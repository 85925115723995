import {
  Card,
  CardMedia
} from "@mui/material";
import React, { useEffect, useState } from "react";
import backAxios from "../axios/back.axios";
import { BASE_URL } from "../data/constants";
import Media from "../data/model/media";


interface ProfileMediaCardProps {
  profileMediaId: string;
  profileId: string
}

export const ProfileMediaCard = React.memo(
  ({ profileMediaId, profileId }: ProfileMediaCardProps) => {
    const [profileMedia, setProfileMedia] = useState<Media | null>(null);
    
    useEffect(() => {
      void fetchProfileMedia();
    }, []);

    const fetchProfileMedia = async () => {
      try {
        if (profileMediaId) {
          const response = await backAxios.get<Media>(`${BASE_URL}/${profileId}/profile-media/${profileMediaId}`);
          console.log(response)
          const profileMedia = response.data;
          setProfileMedia(profileMedia);
          console.log(profileMedia);
          return profileMedia;
        }
      } catch (error: unknown) {
        console.log("Something went wrong while fetching profile");
        console.log(error);
      }
    };

    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)"
        }}
      >
        {profileMedia ? (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "100%" },
              minWidth: { md: "100%" },
            }}
            //height="260"
            component="img"
            src={profileMedia.presignedUrl}
          />
        ) : (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "40%" },
              minWidth: { md: "35%" },
              objectFit: "contain",
            }}
            height="260"
            component="img"
            src={"images/no-image-icon.png"}
          />
        )}
      </Card>
    );
  }
);
