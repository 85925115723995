import AdminType from "./admin-type";

export default class Admin {
  constructor(
    public id: string | undefined = undefined,
    public email: string = "",
    public created_date: string = "",
    public type: AdminType | null = null,
    public password: string = "",
    public enabled2FA: boolean = false,
    public secret: string | null = null
  ) {}
}
