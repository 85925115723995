import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useState } from "react";
import DefaultAppBar from "../components/DefaultAppBar";
import GroupIcon from "@mui/icons-material/Group";
import HandymanIcon from "@mui/icons-material/Handyman";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ChatIcon from "@mui/icons-material/Chat";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import ScienceIcon from "@mui/icons-material/Science";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { AdminRole } from "../data/model/enumerations";

type Links = "" | "Configuration" | "AdminUsers" | "Hobbies" | "CustomHobbies" | "TwoFactorAuthentication" | "IcebreakerConfiguration" | "IcebreakerTemplates" | "IcebreakerTestingPanel";

const Administration = () => {
  const [currentLink, setCurrentLink] = useState<Links>("");
  const [toggleHobbies, setToggleHobbies] = useState(true);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleIcebreaker, setToggleIcebreaker] = useState(false);

  const userRole = localStorage.getItem("role") as AdminRole;

  return (
    <>
      <DefaultAppBar />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "100%", maxWidth: "250px", marginTop: "4px" }}>
          <List
            sx={{
              bgcolor: "background.paper",
              borderRight: "1px solid rgb(144, 144, 144, 0.5)",
              overflowY: "auto",
              height: "100%",
              minHeight: "100vh",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {userRole == AdminRole.DEV_ADMIN ? (
              <ListItemButton
                component={RouterLink}
                to={"/administration/configuration"}
                selected={currentLink === "Configuration"}
                onClick={() => setCurrentLink("Configuration")}
              >
                <ListItemIcon>
                  <HandymanIcon />
                </ListItemIcon>
                <ListItemText primary="Configuration" />
              </ListItemButton>
            ) : (
              <></>
            )}
            {userRole == AdminRole.SUPER_ADMIN ? (
              <ListItemButton
                component={RouterLink}
                to={"/administration/admin-users"}
                selected={currentLink === "AdminUsers"}
                onClick={() => setCurrentLink("AdminUsers")}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Admin Users" />
              </ListItemButton>
            ) : (
              <></>
            )}
            <ListItemButton onClick={() => setToggleHobbies(!toggleHobbies)}>
              <ListItemIcon>
                <SportsHandballIcon />
              </ListItemIcon>
              <ListItemText primary="Hobbies" />
              {toggleHobbies ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={toggleHobbies} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/hobbies"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("Hobbies")}
                  selected={currentLink === "Hobbies"}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Regular" />
                </ListItemButton>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/custom-hobbies"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("CustomHobbies")}
                  selected={currentLink === "CustomHobbies"}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Custom" />
                </ListItemButton>
              </List>
            </Collapse>
            
            <ListItemButton onClick={() => setToggleProfile(!toggleProfile)}>
              <ListItemIcon>
                <AccountCircleIcon/>
              </ListItemIcon>
              <ListItemText primary="My profile" />
              {toggleProfile ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>  
            <Collapse in={toggleProfile} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/two-factor-authentication"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("TwoFactorAuthentication")}
                  selected={currentLink === "TwoFactorAuthentication"}
                >
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Two-Factor Authentication" />
                </ListItemButton>
              </List>
            </Collapse>
            
            <ListItemButton onClick={() => setToggleIcebreaker(!toggleIcebreaker)}>
              <ListItemIcon>
                <ChatIcon/>
              </ListItemIcon>
              <ListItemText primary="Icebreaker" />
              {toggleIcebreaker ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>  
            <Collapse in={toggleIcebreaker} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/icebreaker-configuration"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("IcebreakerConfiguration")}
                  selected={currentLink === "IcebreakerConfiguration"}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configuration" />
                </ListItemButton>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/icebreaker-templates"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("IcebreakerTemplates")}
                  selected={currentLink === "IcebreakerTemplates"}
                >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Templates" />
                </ListItemButton>
                <ListItemButton
                  component={RouterLink}
                  to={"/administration/icebreaker-testing-panel"}
                  sx={{ paddingLeft: 6 }}
                  onClick={() => setCurrentLink("IcebreakerTestingPanel")}
                  selected={currentLink === "IcebreakerTestingPanel"}
                >
                  <ListItemIcon>
                    <ScienceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Testing panel" />
                </ListItemButton>
              </List>
            </Collapse>

          </List>
        </Box>

        <Box sx={{ margin: "2rem", width: "100%" }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default Administration;
