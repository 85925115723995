import UserMessageTemplateDTO from "./user-message-template.dto";

export default class SystemMessageTemplateDTO {
  constructor(
    public id: string = "",
    public name: string = "",
    public template: string = "",
    public userMessageTemplates: UserMessageTemplateDTO[] = []
  ) {}
}
