/* eslint-disable @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument*/
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  Tooltip
} from "@mui/material";
import { Container } from "@mui/system";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDebounce, useEffectOnce, useIsFirstRender } from "usehooks-ts";
import backAxios from "../axios/back.axios";
import { BASE_URL } from "../data/constants";
import SimpleProfile from "../data/model/simple-profile";
import SubscriptionTypeDropdown from "../data/model/subscription-type-dropdown";
import { ProfileCard } from "./ProfileCard";
import { DashboardState, Order, SortBy, profileStatusType } from "./dashboard.model";

export const Dashboard = () => {
  const isFirstRender = useIsFirstRender();

  const [dashboardState, setDashboardState] =
    useOutletContext<
      [
        DashboardState | undefined,
        React.Dispatch<React.SetStateAction<DashboardState | undefined>>
      ]
    >();
  const [profiles, setProfiles] = useState<SimpleProfile[]>([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState<SubscriptionTypeDropdown[]>([]);

  const [subscriptionTypeId, setSubscriptionTypeId] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortBy>("");
  const [order, setOrder] = useState<Order>("ASC");
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 500);

  const [isLoading, setIsLoading] = useState(true);
  const pageNumber = useRef<number>(0);
  const [hasMore, setHasMore] = useState(false);

  const clearAndPopulateProfiles = () => {
    pageNumber.current = 0;
    setProfiles([]);
    setTimeout(() => void fetchProfiles(), 200);
  };

  const [profileStatus, setProfileStatus] = useState<profileStatusType>("");
  const handleProfileStatusChange = (event: SelectChangeEvent) => {
    setProfileStatus(event.target.value as profileStatusType);
  };

  const isComponentMounting = useRef<boolean>(false);

  useEffectOnce(() => {
    const previousState = dashboardState;
    if (previousState) {
      isComponentMounting.current = true;
      setDashboardState(undefined);
      setProfiles(previousState.profiles);
      setSubscriptionTypes(previousState.subscriptionTypes);
      setProfileStatus(previousState.profileStatus);
      setSubscriptionTypeId(previousState.subscriptionTypeId);
      setSortBy(previousState.sortBy);
      setOrder(previousState.order);
      setSearch(previousState.search);
      setIsLoading(false);
      setHasMore(true);
      pageNumber.current = previousState.pageNumber;
      setTimeout(() => {
        window.scrollTo(0, previousState.scrollY);
      }, 200);
    } else {
      clearAndPopulateProfiles();
    }
  });

  useEffect(() => {
    setSubscriptionTypes([]);
    void fetchSubscriptionTypes();
    if (isFirstRender) {
      return;
    }
    if (isComponentMounting.current) {
      isComponentMounting.current = false;
      return;
    }
    pageNumber.current = 0;
    setProfiles([]);
    setTimeout(() => void fetchProfiles(), 200);
  }, [profileStatus, subscriptionTypeId, sortBy, order, debouncedSearch]);

  const saveCurrentState = () => {
    const state = {
      profiles: profiles,
      profileStatus: profileStatus,
      subscriptionTypeId: subscriptionTypeId,
      sortBy: sortBy,
      order: order,
      search: search,
      pageNumber: pageNumber.current,
      scrollY: window.scrollY,
    } as DashboardState;
    setDashboardState(state);
  };

  const fetchProfiles = async () => {
    setIsLoading(true);
    try {
      const response = await backAxios.get(BASE_URL, {
        params: {
          sortBy: sortBy,
          order: order,
          search: search,
          page: pageNumber.current,
          onlyActive: profileStatus === "public",
          onlyComplete: profileStatus === "public",
          onlyEmpty: profileStatus === "empty",
          onlyPartial: profileStatus === "partial",
          onlySuspended: profileStatus === "suspended",
          onlySubscribed: profileStatus === "subscribed",
          onlyPaidUser: profileStatus === "paidUser",
          subscriptionTypeId: subscriptionTypeId,
        },
      });
      const data = response.data as SimpleProfile[];
      setProfiles((prevState) => prevState.concat(data));
      setHasMore(data.length > 0);
      setIsLoading(false);
    } catch (error: any) {
      console.log("Something went wrong while fetching");
      console.log(error);
    }
  };

  const fetchSubscriptionTypes = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/subscription-types/dropdown`;
      const response = await backAxios.get(url);
      const data = response.data as SubscriptionTypeDropdown[];
      // setSubscriptionTypes((prevState) => prevState.concat(data));
      setSubscriptionTypes(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching");
      console.log(error);
    }
  };

  const observer = useRef<IntersectionObserver | null>(null);
  const lastProfileElementRef = useCallback(
    (node: HTMLDivElement) => {
      // prevent triggering infinite scrolling if data is being fetched
      if (isLoading) return;

      // disconnect observer from the previous element
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        // if the element that we are observing is displayed trigger profile loading
        if (entries[0].isIntersecting && hasMore) {
          pageNumber.current = pageNumber.current + 1;
          void fetchProfiles();
        }
      });

      // set last element to be observed
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore]
  );

  const handleSort = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value as SortBy;
    setSortBy(selectedOption);
  };

  const handleSubt = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value;
    setSubscriptionTypeId(parseInt(selectedOption));
  };

  const toggleOrder = () => {
    setOrder(order === "ASC" ? "DESC" : "ASC");
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            my: 2,
            gap: 1,
            justifyContent: "space-between",
            alignItems: { xs: "start", sm: "center" },
          }}
        >

          <FormControl 
            sx={{ width: { xs: "100%", sm: "auto" }, minWidth: "200px" }}
          >
            <InputLabel size="small" id="profile-select-label">Profile status</InputLabel>
            <Select size="small"
              labelId="profile-select-label"
              id="profile-select"
              label="Profile status"
              value={profileStatus}
              onChange={handleProfileStatusChange}
            > 
              <MenuItem value={"subscribed"}>Subscribed</MenuItem>
              <MenuItem value={"empty"}>Empty</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"public"}>Public</MenuItem>
              <MenuItem value={"suspended"}>Suspended</MenuItem>
              <MenuItem value={"paidUser"}>Paid User</MenuItem>
              <MenuItem value={"any"}><em>Any</em></MenuItem>
            </Select>
          </FormControl>
          {
            profileStatus == "subscribed" ?
            <FormControl
            sx={{ width: { xs: "100%", sm: "auto" }, minWidth: "200px", }}
          >
            <InputLabel id="subt-select-label" size="small">
              Subscription type
            </InputLabel>
            <Select
              labelId="subt-select-label"
              id="subt-select"
              value={subscriptionTypeId.toString()}
              size="small"
              label="Subscription type"
              onChange={handleSubt}
            >
              <MenuItem value="0" sx={{ padding: 1 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  ALL
                </div>
              </MenuItem>
              {subscriptionTypes.map((st, index) =>
                <MenuItem key={index} value={st.id} sx={{ padding: 1 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {st.name}
                </div>
              </MenuItem>
              )}
            </Select>
          </FormControl>
            :
            <></>
          }
          
          
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Tooltip title="Search by username, firstname, lastname, email or category">
              <TextField
                sx={{ width: { xs: "70%", sm: "auto" }, minWidth:150 }}
                id="outlined-start-adornment"
                placeholder="Search..."
                value={search}
                size="small"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            <FormControl
              sx={{ width: { xs: "30%", sm: "auto" }, minWidth: "120px" }}
            >
              <InputLabel id="sort-select-label" size="small">
                Sort by
              </InputLabel>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={sortBy}
                size="small"
                label="Sort by"
                onChange={handleSort}
              >
                <MenuItem value="Firstname" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Firstname
                  </div>
                </MenuItem>
                <MenuItem value="Lastname" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Lastname
                  </div>
                </MenuItem>
                <MenuItem value="Username" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Username
                  </div>
                </MenuItem>
                <MenuItem value="Email" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Email
                  </div>
                </MenuItem>
                <MenuItem value="Category" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Category
                  </div>
                </MenuItem>
                <MenuItem value="Priority" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Priority
                  </div>
                </MenuItem>
                <MenuItem value="Creation_date" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Creation
                  </div>
                </MenuItem>
                <MenuItem value="Last_active" sx={{ padding: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    Last active
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={order === "ASC" ? "Ascending" : "Descending"}>
              <ToggleButton value="toggle" size="small" onClick={toggleOrder}>
                {order === "ASC" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </ToggleButton>
            </Tooltip>
          </Box>
        </Box>

        <Divider />

        <Grid container spacing={3} mt={3}>
          {profiles.map((profile, index) => {
            if (profiles.length === index + 1) {
              return (
                <Grid
                  item
                  ref={lastProfileElementRef}
                  key={profile.id}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                >
                  <ProfileCard profile={profile} saveState={saveCurrentState} />
                </Grid>
              );
            } else {
              return (
                <Grid item key={profile.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <ProfileCard profile={profile} saveState={saveCurrentState} />
                </Grid>
              );
            }
          })}
        </Grid>
      </Container>
    </>
  );
};
