import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import backAxios from "../axios/back.axios";
import AlertDialog from "../components/AlertDialog";
import Admin from "../data/model/admin";
import AdminType from "../data/model/admin-type";

const AdminUsers = () => {
  const [admin, setAdmin] = useState<Admin>(new Admin());
  const [errorMessage, setErrorMessage] = useState("");

  const [admins, setAdmins] = useState<Admin[]>([]);
  const [adminTypes, setAdminTypes] = useState<AdminType[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [minimumPasswordLength, setMinimumPasswordLength] = useState(0);

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  useEffect(() => {
    void fetchAdmins();
    void fetchAdminTypes();
    void fetchAdminMinimumPasswordLength();
  }, []);

  const fetchAdmins = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins`;
      const response = await backAxios.get(url);
      const data = response.data as Admin[];
      setAdmins(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching admins");
      console.log(error);
    }
  };

  const fetchAdminTypes = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins/types`;
      const response = await backAxios.get(url);
      const data = response.data as AdminType[];
      setAdminTypes(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching admin types");
      console.log(error);
    }
  };

  const fetchAdminMinimumPasswordLength = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins/default-credentials-configuration`;
      const response = await backAxios.get(url);
      const data = response.data as { minimumPasswordLength: number };
      setMinimumPasswordLength(data.minimumPasswordLength);
    } catch (error: any) {
      console.log(
        "Something went wrong while fetching minimum password length!"
      );
      console.log(error);
    }
  };

  const handleEditClick = (admin: Admin) => {
    setAdmin(admin);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    async function createNewAdmin(url: string, data: any) {
      await backAxios.post(url, data);
    }

    let url;
    if (admin.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins/${admin.id}`;

      backAxios
        .patch(url, admin)
        .then(() => {
          setAdmin(new Admin());
          void fetchAdmins();
        })
        .catch((err: any) => {
          console.log("Something went wrong while updating admin");
          //eslint-disable-next-line
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    } else {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins`;

      backAxios
        .post(url, admin)
        .then(() => {
          setAdmin(new Admin());
          void fetchAdmins();
        })
        .catch((err: any) => {
          console.log("Something went wrong while creating admin");
          //eslint-disable-next-line
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };

  const handleDeleteAdmin = (admin: Admin) => {
    setSelectedAdmin(admin);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = () => {
    async function deleteAdmin(url: string) {
      await backAxios.delete(url);
    }

    if (selectedAdmin && selectedAdmin.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/admins/${selectedAdmin.id}`;

      deleteAdmin(url)
        .then(() => {
          setSelectedAdmin(null);
          setIsDeleteDialogOpen(false);
          setTimeout(() => void fetchAdmins(), 200);
        })
        .catch(() => {
          console.log("Something went wrong while deleting admin");
        });
    }
  };

  const handleDeleteCancelation = () => {
    setSelectedAdmin(null);
    setIsDeleteDialogOpen(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdmin({
      ...admin,
      email: event.target.value,
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdmin({
      ...admin,
      password: event.target.value,
    });
  };

  const handleAdminTypeChange = (value: AdminType | null) => {
    setAdmin({
      ...admin,
      type: value,
    });
  };

  const resetForm = () => setAdmin(new Admin());

  const isSubmitDisabled = (): boolean | undefined => {
    if (admin.id) {
      return false;
    } else {
      if (
        !admin.email ||
        !admin.password ||
        !admin.type ||
        admin.password.length < minimumPasswordLength
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <Box>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 650,
                overflowY: "auto",
                maxHeight: "100vh",
                marginBottom: "20px",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>
                      <b>Type</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map((admin, index) => (
                    <TableRow
                      key={admin.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>
                        {admin.type ? admin.type.name : "N/A"}
                      </TableCell>
                      <TableCell align="right" sx={{ minWidth: "20%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="info"
                            onClick={() => handleEditClick(admin)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => handleDeleteAdmin(admin)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Box
            component={Paper}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "350px",
              padding: "20px",
              borderRadius: "8px",
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <Box sx={{ marginBottom: "5px" }}>
              <Typography variant="h6">
                <b>{admin.id ? `Update admin` : `Create new admin`}</b>
              </Typography>
              <Typography variant="body1">
                {admin.id
                  ? `Fill this form to update admin data`
                  : `Fill this form to create new admin`}
              </Typography>
            </Box>
            <form onSubmit={onSubmit} autoComplete="off">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {errorMessage && (
                  <Box
                    sx={{
                      backgroundColor: "#d32f2f",
                      color: "white",
                      borderRadius: "4px",
                      lineHeight: "1.4375em",
                      padding: "12px 14px",
                    }}
                  >
                    {errorMessage}
                  </Box>
                )}
                <Box>
                  <TextField
                    variant="standard"
                    label="Email"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    name="t2"
                    type="email"
                    required
                    value={admin.email}
                    onChange={handleEmailChange}
                  />
                </Box>
                <Box>
                  <TextField
                    variant="standard"
                    label="Password"
                    sx={{ width: "100%" }}
                    autoComplete="new-password"
                    name="t2"
                    type="password"
                    required={admin.id ? false : true}
                    value={admin.password}
                    onChange={handlePasswordChange}
                    onBlur={() => {
                      setIsPasswordValid(
                        admin.password.length >= minimumPasswordLength
                      );
                    }}
                    onFocus={() => {
                      setIsPasswordValid(true);
                    }}
                    inputProps={{ minLength: minimumPasswordLength }}
                    error={!isPasswordValid}
                  />
                </Box>
                <Box>
                  <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
                    <Autocomplete
                      sx={{ flexGrow: 2 }}
                      disablePortal
                      id="combo-box-demo"
                      options={adminTypes}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          label="Admin type"
                          {...params}
                          placeholder="Admin type"
                          required
                        />
                      )}
                      getOptionLabel={(at) => at.name}
                      onChange={(e, value) => handleAdminTypeChange(value)}
                      value={admin.type}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 2,
                    gap: 2,
                  }}
                >
                  <Button
                    type="submit"
                    disabled={isSubmitDisabled()}
                    variant="contained"
                    disableElevation
                    sx={{ flexGrow: 5 }}
                  >
                    {admin.id ? "Edit" : "Create"}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    sx={{ flexGrow: 1 }}
                    onClick={resetForm}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      <AlertDialog
        title="Are you sure?"
        text={"Deletion of admin is permanent and irreversible"}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteDialogOpen}
        handleCancel={handleDeleteCancelation}
        handleConfirm={handleDeleteConfirmation}
      />
    </Box>
  );
};

export default AdminUsers;
