// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import { Box } from "@mui/system";
import { useEffect, useState, forwardRef, ChangeEvent } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import backAxios from "../axios/back.axios";
import CompleteProfile from "../data/model/complete-profile";
import {
  AdminRole,
  SuspensionDuration
} from "../data/model/enumerations";

interface SuspensionReasons {
  id: number;
  name: string;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SuspensionDialog = (props: {
  isSuspendDialogOpen: boolean;
  onCancel: () => void;
  onSuspend: () => Promise<void>;
  userRole: AdminRole;
  profile: CompleteProfile;
}) => {
  const [suspensionDuration, setSuspensionDuration] =
    useState<SuspensionDuration>(SuspensionDuration.ONE_DAY);
  const [successfullySuspended, setSuccessfullySuspended] = useState(false);
  const [suspensionUnsuccessful, setSuspensionUnsuccessful] = useState(false);
  const [reason, setReason] = useState<SuspensionReasons[]>(
    1
  );
  const [suspensionNote, setSuspensionNote] = useState("");

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason((event.target as HTMLInputElement).value);
    console.log(reason);
  };

  const handleSuspensionNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSuspensionNote((event.target as HTMLInputElement).value);
    console.log(suspensionNote);
  };

  const handleSuspendConfirm = () => {
    async function suspendProfile(url: string) {
      const patchObject = {
        suspensionDuration: suspensionDuration,
        isSuspended: true,
        suspensionReason: reason,
        suspensionNote: suspensionNote,
      };

      await backAxios.patch(url, patchObject);
    }

    if (props.profile?.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${props.profile?.id}`;

      suspendProfile(url)
        .then(() => {
          void props.onSuspend();
          setSuccessfullySuspended(true);
        })
        .catch(() => {
          setSuspensionUnsuccessful(true);
        });
    }
  };

  const [suspensionReasons, setSuspensionReasons] = useState<SuspensionReasons[]>(
    []
  );

  useEffect(() => {
    void fetchSuspensionReasons();
  }, []);

  const fetchSuspensionReasons = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/suspension-reason`;
      const response = await backAxios.get(url);
      const suspensionReasons = response.data as SuspensionReasons[];
      setSuspensionReasons(suspensionReasons);
    } catch (error: any) {
      console.log("Something went wrong while fetching suspension reasons");
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={props.isSuspendDialogOpen}
        onClose={() => {
          props.onCancel();
        }}
      >
        <DialogTitle>Suspend User</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Suspension reason
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                name="radio-buttons-group"
                value={reason}
                onChange={handleReasonChange}
              >
                {suspensionReasons.filter((i, idx) => idx < Math.ceil(suspensionReasons.length / 2)).map((sr) => (
                  <FormControlLabel
                  value={sr.id}
                  control={<Radio />}
                  label={sr.name}
                />
                ))}
              </RadioGroup>
              <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                name="radio-buttons-group"
                value={reason}
                onChange={handleReasonChange}
              >
                {suspensionReasons.filter((i, idx) => idx > Math.floor(suspensionReasons.length / 2)).map((sr) => (
                  <FormControlLabel
                  value={sr.id}
                  control={<Radio />}
                  label={sr.name}
                />
                ))}
              </RadioGroup>
            </RadioGroup>
          </FormControl>
          <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
            <TextField
              id="outlined-multiline-static"
              label="Note"
              multiline
              rows={3}
              placeholder="Additional information for suspension"
              sx={{ width: "100%" }}
              value={suspensionNote}
              onChange={handleSuspensionNoteChange}
            />
          </Box>
          <DialogContentText sx={{ marginBottom: "5px" }}>
            For how long should the user be suspended (starting from today)?
          </DialogContentText>
          <FormControl fullWidth>
            {/* <InputLabel id="suspend-select-label" size="small">
              Days
            </InputLabel> */}
            <Select
              // labelId="suspend-select-label"
              id="suspend-select"
              value={suspensionDuration}
              // label="Days"
              size="small"
              onChange={(event) => setSuspensionDuration(event.target.value)}
            >
              <MenuItem value={SuspensionDuration.ONE_DAY}>24h</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_DAYS}>48h</MenuItem>
              <MenuItem value={SuspensionDuration.WEEK}>One Week</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_WEEKS}>
                Two Weeks
              </MenuItem>
              <MenuItem value={SuspensionDuration.MONTH}>A Month</MenuItem>
              <MenuItem value={SuspensionDuration.PERMANENT}>
                Permanently
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button color="warning" onClick={handleSuspendConfirm}>
            Suspend
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successfullySuspended}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullySuspended(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullySuspended(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          User successfully suspended!
        </Alert>
      </Snackbar>

      <Snackbar
        open={suspensionUnsuccessful}
        autoHideDuration={5000}
        onClose={() => {
          setSuspensionUnsuccessful(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuspensionUnsuccessful(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Did not manage to suspend the user!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SuspensionDialog;
