import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography
} from "@mui/material";
import * as OTPAuth from "otpauth";
import qrcode from "qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backAxios from "../axios/back.axios";
import { AuthenticationDto } from "../data/dto/authentication.dto";
import Admin from "../data/model/admin";
import { parseJwt } from "../helpers/jwt-helper";

const Enforced2FASetup = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");
  const parsedToken = parseJwt(token!); // The existance is checked in App.tsx 

  const [admin, setAdmin] = useState<Admin>(new Admin());
  const [secret, setSecret] = useState("");
  const [QRImage, setQRImage] = useState<string>("");
  const [successfullySetUp2FA, setSuccessfullySetUp2FA] = useState(false);

  useEffect(() => {
    void fetchLoggedAdmin();
  }, []);

  const fetchLoggedAdmin = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      try {
        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/admins/${id}`;
        const response = await backAxios.get(url);
        const data = response.data as Admin;
        setAdmin(data);
      } catch (error: any) {
        console.log("Something went wrong while fetching logged admin info!");
        console.log(error);
      }
    } else {
      throw new Error("Unauthorized access!");
    }
  };

  const updateAdminSecretAndNavigateHome = async () => {
    if(!secret){
      return;
    }

    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      admin.enabled2FA = true;
      admin.secret = secret;
      try {
        const patchUrl = `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/admins/${id}`;

        await backAxios.patch(patchUrl, admin);

        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/auth/two-factor-setup-finalization`;
        const response = await backAxios.post(url);
        const accessToken = (response.data as AuthenticationDto).access_token;
        const payload = parseJwt(accessToken);
        
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("role", payload.role);

        setSuccessfullySetUp2FA(true);
        const intendedRoute = localStorage.getItem('intendedRoute');

        if(intendedRoute) {
          navigate(intendedRoute)
        }
        else {
          navigate("/");
        }
      } catch (error: any) {
        console.log("Something went wrong while updating secret!");
        console.log(error);
      }
    }
  };

  const showQR = () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const email = payload.email;
      const secret = new OTPAuth.Secret();
      const totp = new OTPAuth.TOTP({
        issuer: `Con.tact - ${process.env.REACT_APP_ENV as string}`,
        label: email,
        algorithm: "SHA1",
        digits: 6,
        period: 30,
        secret: secret.base32,
      });

      const otpauth = totp.toString();
      setSecret(otpauth);

      qrcode.toDataURL(otpauth, (err, imageUrl) => {
        if (err) {
          console.log("Error with QR");
          return;
        }
        setQRImage(imageUrl);
      });
    }
  };

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Box
          component={Paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "450px",
            padding: "20px",
            borderRadius: "8px",
            margin: "0 auto",
          }}
        >
          <Box sx={{ marginBottom: "5px" }}>
            <Typography variant="h6">
              <b>Two-Factor Authentication Setup</b>
            </Typography>
            <Typography variant="body1">
              Two Factor Authention is mandatory for this application.

              To set it up, you need to do the following:
              <ol>
                <li>Download the Google Authenticator application</li>
                <li>Click the 'Generate' button to generate QR code</li>
                <li>Scan the generated QR code with Google Authenticator application</li>
                <li>Confirm that you have set up 2FA by clicking the 'Confirm' button </li>
              </ol> 

              Once you finish this setup, you will need to use the code provided by
              Google Authenticator each time you log in.
            </Typography>
          </Box>
          <Box>
            {
              QRImage ? 
                <></> : 
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    mt: 1, // margin top
                    marginLeft: "auto",
                    marginRight: "5px"
                  }}
                  onClick={showQR}
                >
                  Generate QR
                </Button>
            }
          
            {
              QRImage ?
                <Box>
                  <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <img src={QRImage} />
                  </Box>
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{
                      mt: 1, // margin top
                      marginLeft: "auto",
                      marginRight: "5px"
                    }}
                    onClick={() => void updateAdminSecretAndNavigateHome()}
                  >
                  Confirm
                  </Button>
                </Box> 
                 : 
                <></>
            }
          
            
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={successfullySetUp2FA}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullySetUp2FA(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullySetUp2FA(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          2FA setup successful!
        </Alert>
      </Snackbar>
    </>
  );
};
export default Enforced2FASetup;
