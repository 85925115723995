import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import backAxios from "../axios/back.axios";
import { BASE_URL } from "../data/constants";
import CompleteProfile from "../data/model/complete-profile";


interface ProfileCardProps {
  profileId: string;
}

export const ProfileCard = React.memo(
  ({ profileId }: ProfileCardProps) => {
    const [profile, setProfile] = useState<CompleteProfile | null>(null);
    
    useEffect(() => {
      void fetchProfile();
    }, []);

    const fetchProfile = async () => {
      try {
        if (profileId) {
          const response = await backAxios.get(`${BASE_URL}/${profileId}`);
          const profile = response.data as CompleteProfile;
          setProfile(profile);
          return profile;
        }
      } catch (error: any) {
        console.log("Something went wrong while fetching profile");
        console.log(error);
      }
    };

    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)"
        }}
      >
        {profile?.profileMedia ? (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "40%" },
              minWidth: { md: "35%" },
            }}
            height="260"
            component="img"
            src={profile?.profileMedia[0].presignedUrlThumbnail}
          />
        ) : (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "40%" },
              minWidth: { md: "35%" },
              objectFit: "contain",
            }}
            height="260"
            component="img"
            src={"images/no-image-icon.png"}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 1,
            height: "100%",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 1,
              }}
            >
              <Tooltip
                title={`${profile?.firstName ? profile.firstName : ""} ${
                  profile?.lastName ? profile.lastName : ""
                }`}
              >
                <Typography
                  variant="h5"
                  style={{
                    width: 250,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginLeft: "2px",
                    fontWeight: 400,
                  }}
                >
                  {profile?.firstName} {profile?.lastName}
                </Typography>
              </Tooltip>
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Username:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile?.username || "N/A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                display: "flex",
                fontWeight: 600,
                lineHeight: 1.4,
              }}
            >
              Quote:{" "}
              <Tooltip title={profile?.quote}>
                <div
                  style={{
                    width: 120,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginLeft: "2px",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  {profile?.quote || "N/A"}
                </div>
              </Tooltip>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Last active:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile?.lastActive
                  ? format(new Date(profile.lastActive), "yyyy-MM-dd")
                  : "N/A"}
              </span>
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              paddingLeft: { xs: "16px", sm: "16px" },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              component={RouterLink}
              to={profile?.id ? `/profile/${profile?.id}` : ""}
              target="_blank"
              endIcon={<NavigateNextIcon />}
            >
              MORE
            </Button>
          </CardActions>
        </Box>
      </Card>
    );
  }
);
