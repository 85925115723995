import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog(props: {
  open: boolean;
  title: string;
  text: string;
  confirmText: string;
  confirmColor: "success" | "warning" | "error";
  cancelText: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}) {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}>
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel}>{props.cancelText}</Button>
          <Button onClick={props.handleConfirm} color={props.confirmColor}>
            {props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
