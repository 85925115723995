import L from "leaflet";
import iconUrl from "./images/marker-purple.png";

const PurpleMarker = new L.Icon({
  iconUrl: iconUrl,
  iconRetinaUrl: iconUrl,
  iconAnchor: [24, 48],
  popupAnchor: [0, -44],
  iconSize: new L.Point(48, 48),
});

const markerColor = "#CC5DE8";

export { PurpleMarker, markerColor as purpleMarkerColor };
