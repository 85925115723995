import HobbyGroup from "./hobby-group";
import HobbyName from "./hobby-name";

export default class Hobby {
  constructor(
    public id: number | undefined = undefined,
    public englishTranslation: string = "",
    public germanTranslation: string = "",
    public spanishTranslation: string = "",
    public hobbyGroup: HobbyGroup | null = null,
    public isActive: boolean = true,
    public profileHobbiesCount: number = 0
  ) {}
}
