import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { useEffect, useState, ChangeEvent } from "react";
import backAxios from "../axios/back.axios";
import AlertDialog from "../components/AlertDialog";
import Report from "../data/model/report";
import DefaultAppBar from "../components/DefaultAppBar";
import { format } from "date-fns";
import BlogPostCard from "./BlogPostCard";
import CommentCard from "./CommentCard";
import React from "react";
import { ProfileMediaCard } from "./ProfileMediaCard";
import { BlogPostMediaCard } from "./BlogPostMediaCard";
import { ProfileCard } from "./ProfileCard";
import { SuspensionDuration } from "../data/model/enumerations";


const getDateAndTimeAsString = (stringDate: string | undefined) => {
  if (stringDate) {
    const date = new Date(stringDate);
    return format(date, "yyyy-MM-dd HH:mm");
  }

  return "";
};

interface SuspensionReason {
  id: number;
  name: string;
}

const getDialogContent = (report: Report | null) => {
  if (!report) {
    return null;
  }

  switch (report.reportType) {
    case 'BLOG_POST':
      return <BlogPostCard blogPostId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'COMMENT':
    case 'SUBCOMMENT':
      return <CommentCard commentId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'PROFILE':
      return <ProfileCard profileId={report.reportedProfieId} />
    case 'PROFILE_MEDIA':
      return <ProfileMediaCard profileMediaId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'BLOG_POST_MEDIA':
      console.log('test');
      return <BlogPostMediaCard blogPostMediaId={report.reportedRecordId} profileId={report.reportedProfieId} />
    default:
      console.error('Unknown report type', report.reportType);
      return null;
  }
}



const Reports = () => {
  const [report, setReport] = useState<Report | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [reports, setReports] = useState<Report[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openAlertAccept, setOpenAlertAccept] = React.useState(false);
  const [openAlertDismiss, setOpenAlertDismiss] = React.useState(false);
  const [suspensionDuration, setSuspensionDuration] =
    useState<SuspensionDuration>(SuspensionDuration.ONE_DAY);
  const [suspensionReasonId, setSuspensionReasonId] = useState<number>(1);

  const [suspensionReasons, setSuspensionReasons] = useState<SuspensionReason[]>(
    []
  );

  const handleDialogClose = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    void fetchReports();
    void fetchSuspensionReasons();
  }, []);

  const fetchReports = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/reports`;
      const response = await backAxios.get(url);
      const data = response.data as Report[];
      setReports(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching reports");
      console.log(error);
    }
  };

  const fetchSuspensionReasons = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/suspension-reason`;
      const response = await backAxios.get(url);
      const suspensionReasons = response.data as SuspensionReason[];
      setSuspensionReasons(suspensionReasons);
    } catch (error: any) {
      console.log("Something went wrong while fetching suspension reasons");
      console.log(error);
    }
  };

  const [reportNote, setReportNote] = useState("");
  const handleReportNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReportNote((event.target as HTMLInputElement).value);
  };

  const handleEditClick = (report: Report) => {
    setReport(report);
    setOpen(true);
  };

  const handleAcceptClick = (report: Report | null) => {
    setReportNote("");
    setReport(report);
    setOpenAlertAccept(true);
  };

  const handleDismissClick = (report: Report | null) => {
    setReportNote("");
    setReport(report);
    setOpenAlertDismiss(true);
  };

  const handleCancelAccept = () => {
    setOpenAlertAccept(false);
  };

  const handleConfirmAccept = (report: Report | null) => {
    acceptReport(report);
    setOpenAlertAccept(false);
    setOpen(false);
  };

  const handleCancelDismiss = () => {
    setOpenAlertDismiss(false);
  };

  const handleConfirmDismiss = (report: Report | null) => {
    dismissReport(report);
    setOpenAlertDismiss(false);
    setOpen(false);
  };

  const acceptReport = (report: Report | null) => {
    let url;
    if (report && report.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/reports/${report.id}/accept`;

      backAxios
        .post(url, { note: reportNote, suspensionDuration: suspensionDuration, suspensionReasonId: suspensionReasonId})
        .then(() => {
          void fetchReports();
        })
        .catch((err: any) => {
          console.log("Something went wrong while accepting the report");
          //eslint-disable-next-line
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };

  const dismissReport = (report: Report | null) => {
    let url;
    if (report && report.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/reports/${report.id}/dismiss`;

      backAxios
        .post(url, { note: reportNote})
        .then(() => {
          void fetchReports();
        })
        .catch((err: any) => {
          console.log("Something went wrong while dismissing the report");
          //eslint-disable-next-line
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };

  return (
    <>
    <DefaultAppBar />
    <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          // height: "calc(100vh - 20px)",
          p: "10px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                overflowY: "auto",
                maxHeight: "100vh",
                marginBottom: "20px",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Reported</b>
                    </TableCell>
                    <TableCell>
                      <b>Reported by</b>
                    </TableCell>
                    <TableCell>
                      <b>Reason</b>
                    </TableCell>
                    <TableCell>
                      <b>Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report, index) => (
                    <TableRow
                      key={report.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <TableCell>{report.reportType}</TableCell>
                      <TableCell>{report.reportedProfileUsername}</TableCell>
                      <TableCell>{report.reportedByUsername}</TableCell>
                      <TableCell>{report.reportedReason}</TableCell>
                      <TableCell>{getDateAndTimeAsString(report.reportedDate)}</TableCell>
                      <TableCell>{report.description}</TableCell>
                      <TableCell align="right" sx={{ minWidth: "20%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="info"
                            onClick={() => handleEditClick(report)}
                          >
                            View
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Dialog
        sx={{ minHeight: "400px", minWidth: '400px' }}
        open={open}
        onClose={handleDialogClose}
      >
        
        <DialogTitle>Description: {report?.description}</DialogTitle>
        <DialogContent sx={{ minHeight: "80px" }}>
          {getDialogContent(report)}        
        </DialogContent>
        <DialogActions>
          <Button size="small" color="primary" variant="contained" onClick={() => handleAcceptClick(report)}>
            Accept
          </Button>
          <Button size="small" color="error" variant="contained" onClick={() => handleDismissClick(report)}>
            Dismiss
          </Button>
          <Button size="small" color="info" variant="contained" onClick={handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlertAccept} onClose={handleCancelAccept}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to accept this report?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleReportNoteChange}
            value={reportNote}
          />
           {report?.reportType != 'PROFILE' ? (
                          <></>
                        ) : (<>
          <DialogContentText sx={{ marginBottom: "5px" }}>
            For how long should the user be suspended (starting from today)?
          </DialogContentText>
          <FormControl fullWidth>
            {/* <InputLabel id="suspend-select-label" size="small">
              Days
            </InputLabel> */}
            <Select
              // labelId="suspend-select-label"
              id="suspend-select"
              value={suspensionDuration}
              // label="Days"
              size="small"
              onChange={(event) => setSuspensionDuration(event.target.value as SuspensionDuration)}
            >
              <MenuItem value={SuspensionDuration.ONE_DAY}>24h</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_DAYS}>48h</MenuItem>
              <MenuItem value={SuspensionDuration.WEEK}>One Week</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_WEEKS}>
                Two Weeks
              </MenuItem>
              <MenuItem value={SuspensionDuration.MONTH}>A Month</MenuItem>
              <MenuItem value={SuspensionDuration.PERMANENT}>
                Permanently
              </MenuItem>
            </Select>
          </FormControl>
          <DialogContentText sx={{ marginBottom: "5px" }}>
            Reason user is being suspended for:
          </DialogContentText>
          <FormControl fullWidth>
            {/* <InputLabel id="suspend-select-label" size="small">
              Days
            </InputLabel> */}
            <Select
              // labelId="suspend-select-label"
              id="suspend-reason-select"
              value={suspensionReasonId}
              // label="Days"
              size="small"
              onChange={(event) => setSuspensionReasonId(event.target.value as unknown as number)}
            >
              {suspensionReasons.map((sr) => (
                  <MenuItem
                  value={sr.id}>{sr.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          </>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAccept}>Cancel</Button>
          <Button onClick={() => handleConfirmAccept(report)}>Accept</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlertDismiss} onClose={handleCancelDismiss}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to dismiss this report?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleReportNoteChange}
            value={reportNote}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDismiss}>Cancel</Button>
          <Button onClick={() => handleConfirmDismiss(report)}>Dismiss</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reports;
