import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import TextField from "@mui/joy/TextField";
import Typography from "@mui/joy/Typography";
import { CssVarsProvider } from "@mui/joy/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backAxios from "../axios/back.axios";
import { AuthenticationDto } from "../data/dto/authentication.dto";
import { parseJwt } from "../helpers/jwt-helper";

const Verify2FA = () => {
  const [pin, setPin] = useState<string>("");

  const navigateTo = useNavigate();

  const onSubmit2FA = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void doVerification2FA();
  };

  const doVerification2FA = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      try {
        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/auth/${id}/two-factor-auth/${pin}`;
        const response = await backAxios.post(url);
        const accessToken = (response.data as AuthenticationDto).access_token;
        const payload = parseJwt(accessToken);
        if (payload.fullyAuthenticated) {
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("role", payload.role);
          const intendedRoute = localStorage.getItem('intendedRoute');

          if(intendedRoute) {
            navigateTo(intendedRoute)
          }
          else {
            navigateTo("/");
          }
        }
      } catch (error: any) {
        alert("Could not verify entered PIN!");
        console.log(
          "Something went wrong while checking validity of the entered PIN!"
        );
        console.log(error);
      }
    }
  };

  const onChangePIN = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPin(event.target.value);
  };

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          maxWidth: 400,
          mx: "auto", // margin left & right
          my: 7, // margin top & botom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <div>
          <Typography level="h4" component="h1">
            <b>Welcome!</b>
          </Typography>
          <Typography level="body2">Enter generated PIN</Typography>
        </div>
        <form onSubmit={onSubmit2FA}>
          <TextField
            name="pin"
            type="text"
            placeholder="000000"
            label="PIN"
            onChange={onChangePIN}
          />
          <div style={{ display: "flex" }}>
            <Button
              type="submit"
              sx={{
                mt: 1, // margin top
              }}
            >
              Confirm PIN
            </Button>
            <Button
              type="button"
              color="info"
              sx={{
                mt: 1, // margin top,
                marginLeft: "auto",
              }}
              onClick={() => {
                localStorage.clear();
                location.reload();
              }}
            >
              Return
            </Button>
          </div>
        </form>
      </Sheet>
    </CssVarsProvider>
  );
};
export default Verify2FA;
