import { format } from "date-fns";


export const getDateOnlyAsString = (stringDate: string | undefined) => {
    if (stringDate) {
        const date = new Date(stringDate);
        return format(date, "yyyy-MM-dd");
    }

    return "";
};

export const getDateAndTimeAsString = (stringDate: string | undefined) => {
    if (stringDate) {
        const date = new Date(stringDate);
        return format(date, "yyyy-MM-dd HH:mm");
    }

    return "";
};