/* eslint-disable @typescript-eslint/no-unused-vars */
import { CssVarsProvider } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import TextField from "@mui/joy/TextField";
import backAxios from "../axios/back.axios";
import Button from "@mui/joy/Button";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { app } from '../firebase';
import { Auth, getAuth, isSignInWithEmailLink, signInWithEmailLink, signInWithPopup, GoogleAuthProvider, OAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import User from "../data/model/user";
import SvgIcon from "@mui/material/SvgIcon";
import { Box, Card, CircularProgress, Modal, ModalClose } from "@mui/joy";
import { CardMedia } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteAccountHeader from "./deleteAccountHeader";
const init = (): JSX.Element => {
  const googleAuthProvider = new GoogleAuthProvider();
  const appleAuthProvider = new OAuthProvider('apple.com');

  const [email, setEmail] = useState('');
  const [isApiCall, setIsApiCall] = useState(false);
  const [isAccountDelete, setIsAccountDelete] = useState(false);
  const [isSignInWithLink, setIsSignInWithLink] = useState(true);
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [searchParams] = useSearchParams();
  const [phone, setPhone] = useState("+");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState('');
  const [isEmail, setIsEmail] = useState(false)
  const [isSetEmail, setIsSetEmail] = useState(true)
  const [isEmailLoading, setIsEmailLoading] = useState(false)
  const [isVerifyOtpLoading, setIsVerifyOtpLoading] = useState(false)
  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(true)
  // single-time read
  const userEmail: any = isSetEmail ? searchParams.get('userEmail') : '';
  useEffect(() => {
    document.title = "Con.tact: Network Connect App"
  }, [])
  const getProfileAndDelete = async () => {
    const auth: Auth = getAuth(app)
    const result = isSignInWithEmailLink(auth, window.location.href)
    if (result) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const response = await signInWithEmailLink(auth, userEmail, window.location.href)
        const idToken = await response.user.getIdToken()
        if (idToken) {
          localStorage.setItem("access_token", idToken);
          const profile: any = await deleteUserProfile(response.user.uid)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (profile.response?.status === 404) {
            setIsSignInWithLink(false)
          } else {
            setIsSetEmail(false)
            setIsAccountDelete(true)
          }
        }

      } catch (error) {
        setIsSignInWithLink(false)
        console.error('Error occurred:', error);
      }
    }
  }

  if (userEmail) {
    // Check if the email exists in Firebase
    void (async () => {
      await getProfileAndDelete();
    })()
  }

  const doLogin = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL as string}/api/moderation/profiles/deletion/verify/${email}`;
      const response = await backAxios.get(url);
      return response;
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return error;
    }
  };
  const deleteUserProfile = async (uid: string) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL as string}/api/moderation/profiles/deletion/delete-profile/${uid}`;
      const response = await backAxios.delete(url);
      const data = response.data as User;
      return data;
    } catch (error) {
      return error
    }
  };

  const onSubmitLogin = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsEmailLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result: any = await doLogin();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (result && result.response?.status === 404) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      toast.error('User not found!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        icon: false
      });
      setIsEmailLoading(false)
    } else {
      setIsApiCall(true)
      setIsEmail(false)
      setIsEmailLoading(false)
    }
    setEmail('')
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (phone: string) => {
    if (phone.length > 10) {
      setPhone("+" + phone)
      setIsSendOtpDisabled(false)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const socialLogin = (provider: any) => {
    if (provider === "phoneNumber") {
      setIsPhoneNumber(true)
    } else {
      const auth: Auth = getAuth(app)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const idToken = await result.user.getIdToken()
          localStorage.setItem("access_token", idToken);
          const profile: any = await deleteUserProfile(result.user.uid)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (profile && profile.response?.status === 404) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            toast.error('User not found!', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              icon: false
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            toast.success('User deleted successfully!', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              icon: false
            });
          }

        })
        .catch(error => {
          console.error('Error occurred:', error);
        });
    }
  }

  const sendOtp = async () => {
    try {
      const auth: Auth = getAuth(app)
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {})
      if (recaptcha) {
        setIsSendOtpDisabled(true)
      }
      const confirmation: any = await signInWithPhoneNumber(auth, phone, recaptcha)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setUser(confirmation.verificationId)
    } catch (err) {
      console.log(err)
    }
  }

  const verifyOtp = async () => {
    try {
      setIsVerifyOtpLoading(true)
      if (user !== null) {
        const auth: Auth = getAuth(app)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const credential = PhoneAuthProvider.credential(user, otp);
        const data = await signInWithCredential(auth, credential);
        const idToken = await data.user.getIdToken()
        localStorage.setItem("access_token", idToken);
        const profile: any = await deleteUserProfile(data.user.uid)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (profile && profile.response?.status === 404) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          toast.error('User not found!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            icon: false
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          toast.success('User deleted successfully!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            icon: false
          });
        }
        setIsSendOtpDisabled(false)
        setPhone("+")
        setOtp('')
        setUser(null)
      }
      setIsVerifyOtpLoading(false)
    } catch (err) {
      console.log(err)
      setIsVerifyOtpLoading(false)
    }
  }

  const back = (provider: string) => {
    if (provider == 'email') {
      setIsEmail(false)
    } else if (provider == 'phone') {
      setIsPhoneNumber(false)
    }
  }
  return (

    <div>
      
      {!userEmail && !isApiCall && !isPhoneNumber && !isEmail && isSetEmail ?
        //isApiCall false and userEmail false (Initial email page)
        (<CssVarsProvider>
          <Sheet
            sx={{
              maxWidth: 400,
              mx: "auto",
              my: 7,
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
            }}
          >
            <div>
              <DeleteAccountHeader />
              <Typography level="body3">
                We’re sorry to see you go! If you are sure you want to leave con.tact, please choose the Login method associated with your account:
              </Typography>
            </div>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, minmax(0, 1fr))',
                sm: 'repeat(3, minmax(0, 1fr))',
              },
              gap: 2,
              justifyItems: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
              <Card size="sm" variant="outlined" onClick={() => setIsEmail(true)}>
                <CardMedia
                  sx={{
                    width: { xs: "10%", md: "40%" },
                    minWidth: { md: "10%" },
                    objectFit: "contain",
                    alignItems: 'center', textAlign: 'center'
                  }}
                  style={{ marginLeft: 30 }}
                  component="img"
                  src={"images/email.png"}

                />
                <Typography level="h6" fontSize="sm" component="h6">
                  Email
                </Typography>
              </Card>
              <Card size="sm" variant="outlined" onClick={() => socialLogin(googleAuthProvider)}>
                <CardMedia
                  sx={{
                    width: { xs: "10%", md: "40%" },
                    minWidth: { md: "10%" },
                    objectFit: "contain",
                    alignItems: 'center', textAlign: 'center'
                  }}
                  style={{ marginLeft: 30 }}
                  component="img"
                  src={"images/search.png"}
                  className="login-form-button google-gradient"
                />
                <Typography level="h6" fontSize="sm" component="h6">
                  Google
                </Typography>
              </Card>
              <Card size="sm" variant="outlined" onClick={() => socialLogin(appleAuthProvider)}>
                <CardMedia
                  sx={{
                    width: { xs: "10%", md: "40%" },
                    minWidth: { md: "5%" },
                    objectFit: "contain",
                    alignItems: 'center', textAlign: 'center'
                  }}
                  style={{ marginLeft: 30 }}
                  component="img"
                  src={"images/apple.png"}
                  className="login-form-button google-gradient"
                />
                <Typography level="h6" fontSize="sm" component="h6">
                  Apple
                </Typography>
              </Card>
            </Box>
            <Typography level="h6" fontSize="sm" component="h6" sx={{ textAlign: 'center' }}>Or</Typography>
            <Card
              size="sm"
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
              onClick={() => socialLogin('phoneNumber')}
            >
              <CardMedia
                sx={{
                  width: { xs: "10%", md: "10%" },
                  minWidth: { md: "5%" },
                  objectFit: "contain",
                }}
                component="img"
                src={"images/phone.png"}
                alt="Phone Icon"
                className="login-form-button google-gradient"

              />
              <Typography level="h6" fontSize="sm" component="h6" sx={{ marginLeft: 2 }}>
                Use mobile number
              </Typography>
            </Card>
          </Sheet>
          <ToastContainer />
        </CssVarsProvider>) :
        isEmail ?
          //when user select the email option then open new card
          (<CssVarsProvider>
            <Sheet
              sx={{
                maxWidth: 400,
                mx: "auto",
                my: 7,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
              }}
            >
              <div>
                <DeleteAccountHeader />
                <Typography level="body3">
                  If you click on “Delete account” all your data will be deleted irrevocably. Are you sure you want to leave con.tact? We are sorry to see you go!
                </Typography>
              </div>
              <form>
                <TextField
                  autoComplete="username"
                  name="email"
                  type="email"
                  placeholder="your@email.com"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <Button
                  type="button"
                  color="danger"
                  sx={{
                    mt: 1,
                  }}
                  style={{
                    marginRight: 10,
                    // background: 'linear-gradient(135deg, #007BFF, #00FF7F)'
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={onSubmitLogin}
                >
                  {isEmailLoading ? 'Deleting...' : 'Delete Account'}
                </Button>
                <Button
                  type="button"
                  color="neutral"
                  sx={{
                    mt: 1,
                  }}
                  style={{ marginRight: 10 }}
                  onClick={() => back('email')}
                >
                  Back
                </Button>

              </form>
            </Sheet>
            <ToastContainer />
          </CssVarsProvider>) :
          isApiCall ?
            //isAPiCall true(after email deletion mail send show modal)
            (<Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={isApiCall}
              onClose={() => setIsApiCall(false)}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Sheet
                variant="outlined"
                sx={{
                  maxWidth: 500,
                  borderRadius: 'md',
                  p: 3,
                  boxShadow: 'lg',
                }}
              >
                <ModalClose variant="plain" sx={{ m: 1 }} />
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                >
                  Account Deletion Request
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                  An email verification link has been sent to your email address. Please check your inbox to proceed with account deletion.
                </Typography>
              </Sheet>
            </Modal>) :
            isPhoneNumber ?
              //when user select phone number then open new card
              //isPhoneNumber true(when user click on mobileNumber)
              (<CssVarsProvider>
                <Sheet
                  sx={{
                    maxWidth: 400,
                    mx: "auto",
                    my: 7,
                    py: 3,
                    px: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    borderRadius: "sm",
                    boxShadow: "md",
                  }}
                >
                  <div>
                    <DeleteAccountHeader />

                    <Typography level="body3">
                      If you click on “Verify Code” all your data will be deleted irrevocably. Are you sure you want to leave con.tact? We are sorry to see you go!
                    </Typography>
                  </div>
                  <form>
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      onChange={(phone) => handlePhoneChange(phone)}
                    />
                    <Button
                      type="button"
                      sx={{
                        mt: 1,
                        mb: 1
                      }}
                      style={{
                        marginRight: 10,
                        background: isSendOtpDisabled
                          ? 'linear-gradient(135deg, #ccc, #ddd)' // Change the colors when disabled
                          : 'linear-gradient(135deg, #007BFF, #00FF7F)',
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => sendOtp()}
                      disabled={isSendOtpDisabled}

                    >
                      Send Verification Code
                    </Button>
                    <Button
                      color="neutral"
                      type="button"
                      sx={{
                        mt: 1,
                      }}
                      style={{ marginRight: 10 }}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => back('phone')}
                    >
                      Back
                    </Button>
                    {user ? <></> : <div id="recaptcha"></div>}
                    {user ? (
                      <>
                        <TextField
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                          onChange={(e) => setOtp(e.target.value)}
                          sx={{
                            marginTop: "10px",
                            width: "300px"
                          }}
                          variant="outlined"
                          label="Enter Code">
                        </TextField>
                        <Button
                          type="button"
                          sx={{
                            mt: 1,
                          }}
                          style={{ marginRight: 10, background: 'linear-gradient(135deg, #007BFF, #00FF7F)' }}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onClick={verifyOtp}
                        >
                          {isVerifyOtpLoading ? 'Verifing...' : 'Verify code'}
                        </Button>
                      </>
                    ) : <></>}
                  </form>
                </Sheet>
                <ToastContainer />
              </CssVarsProvider>) :
              !isSignInWithLink ?
                // isSignInWithLink false when error comes from identitytoolkit from google api()
                (<CssVarsProvider>
                  <Sheet
                    sx={{
                      maxWidth: 300,
                      mx: "auto",
                      my: 7,
                      py: 5,
                      px: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      borderRadius: "sm",
                      boxShadow: "md",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: { xs: "20%", md: "40%" },
                        minWidth: { md: "10%" },
                        objectFit: "contain",
                        alignItems: 'center', textAlign: 'center'
                      }}
                      height="70"
                      component="img"
                      src={"images/cross.png"}
                    />
                    <Typography level="h4" component="h1">
                      <Typography level="h4" fontSize="sm" style={{ width: '100%', maxWidth: '200px', borderRadius: '50%' }}>There is some issue in delete your account.</Typography>
                    </Typography>
                  </Sheet>
                </CssVarsProvider>) :
                isAccountDelete ?
                  //when delete account api call 
                  (<CssVarsProvider>
                    <Sheet
                      sx={{
                        maxWidth: 300,
                        mx: "auto",
                        my: 7,
                        py: 3,
                        px: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        borderRadius: "sm",
                        boxShadow: "md",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <CardMedia
                        sx={{
                          width: { xs: "20%", md: "40%" },
                          minWidth: { md: "10%" },
                          objectFit: "contain",
                          alignItems: 'center', textAlign: 'center'
                        }}
                        height="70"
                        component="img"
                        src={"images/check.png"}
                      />
                      <Typography level="h4" component="h1" style={{ textAlign: 'center' }}>
                        <b>Account Deleted Successfully</b>
                        <br></br>
                        <Typography level="h4" fontSize="sm">Your account has been successfully deleted.<br></br>  We hope to see you again!</Typography>
                      </Typography>
                    </Sheet>
                  </CssVarsProvider>) :
                  //when account deleted successfully 
                  (<CssVarsProvider>
                    <Sheet
                      sx={{
                        maxWidth: 300,
                        mx: "auto",
                        my: 7,
                        py: 3,
                        px: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        borderRadius: "sm",
                        boxShadow: "md",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Typography level="h4" component="h1" style={{ textAlign: 'center' }}>
                        <b>Account Deletion in process</b>
                        <br></br>
                        <Typography level="h4" fontSize="sm">Your account deletion is in progress.<br></br> Please wait...</Typography>
                      </Typography>
                      <CircularProgress size="lg" />
                    </Sheet>
                  </CssVarsProvider>)}
                  <Box sx={{
        maxWidth: 600,
        mx: "auto",
        my: 7,
        flexDirection: "column"
      }} >
        <Typography level="body1"><b>Steps to follow :</b> In order to delete your account with all the data you need to choose the login option you were previously using to use the account.</Typography>
        <Typography level="body1">Log in to the account and confirm the deletion.</Typography>
        <Typography level="body1"><b>Data that will be deleted :</b> Your all kind of data will be deleted permanently from the servers after you choose delete account option.</Typography>
        <Typography level="body1">The data that we collect includes</Typography>
        <Typography level="body1"><b>Personal Info :</b> Name, Email, Phone, and other info</Typography>
        <Typography startDecorator={
          <Box
            component="span"
            sx={{
              bgcolor: 'black',
              width: '0.5em',
              height: '0.5em',
              borderRadius: '50%',
            }}
          />
        }>Location : Data related to your location.</Typography>
        <Typography startDecorator={
          <Box
            component="span"
            sx={{
              bgcolor: 'black',
              width: '0.5em',
              height: '0.5em',
              borderRadius: '50%',
            }}
          />
        }>Media : Photos, Videos, Documents you've sent or received through the app.</Typography>
        <Typography startDecorator={
          <Box
            component="span"
            sx={{
              bgcolor: 'black',
              width: '0.5em',
              height: '0.5em',
              borderRadius: '50%',
            }}
          />
        }>Your activity</Typography>
        <Typography startDecorator={
          <Box
            component="span"
            sx={{
              bgcolor: 'black',
              width: '0.5em',
              height: '0.5em',
              borderRadius: '50%',
            }}
          />
        }>Device ID and Info</Typography>
      </Box>
    </div>
  );
};

export default init;