import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import SimpleProfile from "../data/model/simple-profile";

const ProfileBox = (props: {
  profile?: SimpleProfile;
  inputLabel: string;
  onChangeUsername: (e: React.ChangeEvent<HTMLInputElement>) => void;
  retrieveProfile: () => Promise<void>;
  clearHistory?: (id: string) => Promise<void>;
}) => {
  return (
    <Box
      sx={{
        height: { xs: undefined, sm: "50%" },
        alignItems: "end",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void props.retrieveProfile();
        }}
      >
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <TextField
            label={props.inputLabel}
            variant="standard"
            onChange={props.onChangeUsername}
          />
          <Button
            size="small"
            sx={{
              paddingLeft: 0,
              minWidth: 0,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => {
              void props.retrieveProfile();
            }}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      </form>
      <div>
        {props.profile ? (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Username:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.username || "-"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Name:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.firstName + (props.profile.lastName || " ")}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Device Token:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.reDeviceToken ||
                  props.profile.deviceToken ||
                  "-"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600 }}
            >
              Longitude:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.longitude || props.profile.longitude === 0
                  ? props.profile.longitude
                  : "-"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600 }}
            >
              Latitude:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.latitude || props.profile.latitude === 0
                  ? props.profile.latitude
                  : "-"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Modified:{" "}
              <span style={{ fontWeight: 400 }}>
                {props.profile.lastModified || "-"}
              </span>
            </Typography>
            <Box display="flex" justifyContent="end">
              {props.clearHistory ? (
                <Button
                  variant="contained"
                  size="small"
                  color="info"
                  onClick={() => {
                    if (props.clearHistory) {
                      void props.clearHistory(props.profile?.id || "");
                    }
                  }}
                >
                  Clear History
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};

export default ProfileBox;
