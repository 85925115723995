import { Dialog, DialogTitle, DialogContent, Grid, Box, Typography, TextField, DialogActions, Button } from "@mui/material";

interface Coordinates {
    lng: number; lat: number;
}

interface REInvocationDialogProps {
    invocationDialogOpen: boolean;
    setInvocationDialogOpen(param: boolean) : void;
    profile1Coordinates?: Coordinates
    setProfile1Coordinates(coordinates: Coordinates): void;
    profile2Coordinates?: Coordinates
    setProfile2Coordinates(coordinates: Coordinates): void;
    invokeRealEncounter(): Promise<void>;
}

const REInvocationDialog = (props: REInvocationDialogProps) => {


    return (
        <Dialog
          open={props.invocationDialogOpen}
          onClose={() => {
            props.setInvocationDialogOpen(false);
          }}
        >
          <DialogTitle>What are the coordinates?</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle1">First Profile</Typography>
                  <div style={{ width: "100%" }}>
                    <TextField
                      label="Longitude"
                      variant="standard"
                      size="small"
                      style={{ width: "100%" }}
                      type="number"
                      value={props.profile1Coordinates?.lng}
                      onChange={(e) =>
                        props.setProfile1Coordinates({
                          lat: props.profile1Coordinates?.lat || 0,
                          lng: Number.parseFloat(e.target.value) || 0,
                        })
                      }
                    />
                    <TextField
                      label="Latitude"
                      variant="standard"
                      size="small"
                      style={{ width: "100%" }}
                      type="number"
                      value={props.profile1Coordinates?.lat}
                      onChange={(e) =>
                        props.setProfile2Coordinates({
                          lng: props.profile1Coordinates?.lng || 0,
                          lat: Number.parseFloat(e.target.value) || 0,
                        })
                      }
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle1">Second Profile</Typography>
                  <div style={{ width: "100%" }}>
                    <TextField
                      label="Longitude"
                      variant="standard"
                      size="small"
                      style={{ width: "100%" }}
                      type="number"
                      value={props.profile2Coordinates?.lng}
                      onChange={(e) =>
                        props.setProfile2Coordinates({
                          lat: props.profile2Coordinates?.lat || 0,
                          lng: Number.parseFloat(e.target.value) || 0,
                        })
                      }
                    />
                    <TextField
                      label="Latitude"
                      variant="standard"
                      size="small"
                      style={{ width: "100%" }}
                      type="number"
                      value={props.profile2Coordinates?.lat}
                      onChange={(e) =>
                        props.setProfile2Coordinates({
                          lng: props.profile2Coordinates?.lng || 0,
                          lat: Number.parseFloat(e.target.value) || 0,
                        })
                      }
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setInvocationDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                void props.invokeRealEncounter();
              }}
            >
              Invoke
            </Button>
          </DialogActions>
        </Dialog>
    );
}

export default REInvocationDialog;