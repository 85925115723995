import L from "leaflet";
import iconUrl from "./images/marker-gray.png";

const GrayMarker = new L.Icon({
  iconUrl: iconUrl,
  iconRetinaUrl: iconUrl,
  iconAnchor: [24, 48],
  popupAnchor: [0, -44],
  iconSize: new L.Point(48, 48),
});

const markerColor = "#B4B4B4";
export { GrayMarker, markerColor as grayMarkerColor };
