import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import SystemMessageTemplateDTO from "../data/dto/system-message-template.dto";
import backAxios from "../axios/back.axios";
import AlertDialog from "../components/AlertDialog";
import UserMessageTemplateDTO from "../data/dto/user-message-template.dto";
import React from "react";

const IcebreakerTemplates = () => {
  const profileFieldsTooltip = `{
    "firstName": "string",
    "lastName": "string",
    "username": "string",
    "quote": "string",
    "birthday": Date,
    "placeOfResidence": "string",
    "hometown": "string",
    "genderType": { "name": "string" },
    "childrenStatus": { "name": "string" },
    "relationshipStatus": { "name": "string" },
    "profileHobbies": [{ "hobbyName": "string" }],
    "languages": [{ "name": "string" }],
    "questionGroups": [
      {
        "questions": [
          {
            "questionNames": [{ "text": "string" }],
            "answers": [{ "answer": "string" }]
          }
        ]
      }
    ],
    "profileWorks": [{ "company": "string" }]
  }`;

  const [systemMessageTemplates, setSystemMessageTemplates] = useState<
    SystemMessageTemplateDTO[]
  >([]);
  const [selectedSystemMessageTemplate, setSelectedSystemMessageTemplate] =
    useState<SystemMessageTemplateDTO>(new SystemMessageTemplateDTO());
  const [
    selectedForDeletionSystemMessageTemplate,
    setSelectedForDeletionSystemMessageTemplate,
  ] = useState<SystemMessageTemplateDTO | null>(null);

  const [
    systemMessageTemplateDeleteDialogOpen,
    setSystemMessageTemplateDeleteDialogOpen,
  ] = useState(false);

  const [userMessageTemplatesVisible, setUserMessageTemplatesVisible] =
    useState("hidden");
  const [userMessageTemplates, setUserMessageTemplates] = useState<
    UserMessageTemplateDTO[]
  >([]);

  const [selectedUserMessageTemplate, setSelectedUserMessageTemplate] =
    useState<UserMessageTemplateDTO>(new UserMessageTemplateDTO());
  const [
    selectedForDeletionUserMessageTemplate,
    setSelectedForDeletionUserMessageTemplate,
  ] = useState<UserMessageTemplateDTO | null>(null);
  const [
    userMessageTemplateDeleteDialogOpen,
    setUserMessageTemplateDeleteDialogOpen,
  ] = useState(false);

  const [successfullyDoneCRUD, setSuccessfullyDoneCRUD] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    void fetchSystemMessageTemplates();
  }, []);

  const fetchSystemMessageTemplates = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/system-message-templates`;
      const response = await backAxios.get(url);
      const data = response.data as SystemMessageTemplateDTO[];
      setSystemMessageTemplates(data);

      if (selectedSystemMessageTemplate.id != "") {
        const alreadySelectedSMT = data.find(
          (smt) => smt.id === selectedSystemMessageTemplate.id
        );
        if (alreadySelectedSMT) {
          setUserMessageTemplates(alreadySelectedSMT.userMessageTemplates);
        }
      }
    } catch (error: any) {
      console.log(
        "Something went wrong while fetching system message templates"
      );
      console.log(error);
    }
  };

  //System message template Select
  const handleSelectSystemTemplateSelect = (
    systemMessageTemplate: SystemMessageTemplateDTO
  ) => {
    if (selectedSystemMessageTemplate.id !== systemMessageTemplate.id) {
      setUserMessageTemplatesVisible("visible");
      setSelectedSystemMessageTemplate(systemMessageTemplate);
      setUserMessageTemplates(systemMessageTemplate.userMessageTemplates);
      setSelectedUserMessageTemplate(new UserMessageTemplateDTO());
    } else {
      setUserMessageTemplatesVisible("hidden");
      setSelectedSystemMessageTemplate(new SystemMessageTemplateDTO());
      setUserMessageTemplates([]);
    }
  };

  //System message template DELETE
  const handleSystemMessageTemplateDeleteClick = (
    systemMessageTemplate: SystemMessageTemplateDTO
  ) => {
    setSelectedForDeletionSystemMessageTemplate(systemMessageTemplate);
    setSystemMessageTemplateDeleteDialogOpen(true);
  };

  const handleSystemMessageTemplateDeleteCancelation = () => {
    setSystemMessageTemplateDeleteDialogOpen(false);
    setSelectedForDeletionSystemMessageTemplate(null);
  };

  const handleSystemMessageTemplateDeleteConfirmation = () => {
    async function deleteSystemMessageTemplate(url: string) {
      await backAxios.delete(url);
    }

    if (!selectedForDeletionSystemMessageTemplate) {
      return;
    }

    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/system-message-templates/${
      selectedForDeletionSystemMessageTemplate.id
    }`;

    deleteSystemMessageTemplate(url)
      .then(() => {
        setSelectedForDeletionSystemMessageTemplate(null);
        setSystemMessageTemplateDeleteDialogOpen(false);
        setAlertMessage("System message template successfully deleted!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while deleting system message template"
        );
      });
  };

  //System message template Form
  const handleSystemTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedSystemMessageTemplate({
      ...selectedSystemMessageTemplate,
      name: event.target.value,
    });
  };

  const handleSystemTemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedSystemMessageTemplate({
      ...selectedSystemMessageTemplate,
      template: event.target.value,
    });
  };

  const handleSystemMessageTemplateAdd = () => {
    async function addSystemMessageTemplate(
      url: string,
      body: SystemMessageTemplateDTO
    ) {
      await backAxios.post(url, body);
    }
    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/system-message-templates`;

    addSystemMessageTemplate(url, selectedSystemMessageTemplate)
      .then(() => {
        setSelectedSystemMessageTemplate(new SystemMessageTemplateDTO());
        setAlertMessage("System message template successfully added!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while adding a new system message template"
        );
      });
  };

  const handleSystemMessageTemplateEdit = () => {
    async function editSystemMessageTemplate(
      url: string,
      body: SystemMessageTemplateDTO
    ) {
      await backAxios.patch(url, body);
    }
    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/system-message-templates`;
    editSystemMessageTemplate(url, selectedSystemMessageTemplate)
      .then(() => {
        // setSystemMessageTemplateEditDialogOpen(false);
        setAlertMessage("System message template successfully updated!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while editing system message template."
        );
      });
  };

  const resetSystemTemplateForm = () =>
    setSelectedSystemMessageTemplate(new SystemMessageTemplateDTO());

  //User message template SELECT
  const handleUserTemplateSelect = (
    userMessageTemplate: UserMessageTemplateDTO
  ) => {
    if (userMessageTemplate.id === selectedUserMessageTemplate.id) {
      setSelectedUserMessageTemplate(new UserMessageTemplateDTO());
    } else {
      setSelectedUserMessageTemplate(userMessageTemplate);
    }
  };

  //User message template DELETE
  const handleUserMessageTemplateDeleteClick = (
    userMessageTemplate: UserMessageTemplateDTO
  ) => {
    setSelectedForDeletionUserMessageTemplate(userMessageTemplate);
    setUserMessageTemplateDeleteDialogOpen(true);
  };

  const handleUserMessageTemplateDeleteCancelation = () => {
    setSelectedForDeletionUserMessageTemplate(null);
    setUserMessageTemplateDeleteDialogOpen(false);
  };

  const handleUserMessageTemplateDeleteConfirmation = () => {
    async function deleteUserMessageTemplate(url: string) {
      await backAxios.delete(url);
    }

    if (!selectedForDeletionUserMessageTemplate) {
      return;
    }

    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/user-message-templates/${
      selectedForDeletionUserMessageTemplate.id
    }`;

    deleteUserMessageTemplate(url)
      .then(() => {
        setSelectedUserMessageTemplate(new UserMessageTemplateDTO());
        setUserMessageTemplateDeleteDialogOpen(false);
        setAlertMessage("User message template successfully deleted!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while deleting user message template"
        );
      });
  };

  //User message template Form
  const handleUserTemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedUserMessageTemplate({
      ...selectedUserMessageTemplate,
      template: event.target.value,
    });
  };

  const handleUserTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedUserMessageTemplate({
      ...selectedUserMessageTemplate,
      name: event.target.value,
    });
  };

  const handleUserTemplateIsFallbackChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedUserMessageTemplate({
      ...selectedUserMessageTemplate,
      isFallback: !selectedUserMessageTemplate.isFallback,
    });
  };

  const handleUserTemplateActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedUserMessageTemplate({
      ...selectedUserMessageTemplate,
      isActive: !selectedUserMessageTemplate.isActive,
    });
  };

  const handleUserMessageTemplateAdd = () => {
    async function addUserMessageTemplate(
      url: string,
      body: UserMessageTemplateDTO
    ) {
      await backAxios.post(url, body);
    }
    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/system-message-templates/${
      selectedSystemMessageTemplate.id
    }/user-message-templates`;
    addUserMessageTemplate(url, selectedUserMessageTemplate)
      .then(() => {
        setSelectedUserMessageTemplate(new UserMessageTemplateDTO());
        setAlertMessage("User message template successfully added!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while adding a new user message template"
        );
      });
  };

  const handleUserMessageTemplateEdit = () => {
    async function editUserMessageTemplate(
      url: string,
      body: UserMessageTemplateDTO
    ) {
      await backAxios.patch(url, body);
    }
    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/icebreaker/user-message-templates/${
      selectedUserMessageTemplate.id
    }`;
    editUserMessageTemplate(url, selectedUserMessageTemplate)
      .then(() => {
        setAlertMessage("User message template successfully updated!");
        setSuccessfullyDoneCRUD(true);
        setTimeout(() => void fetchSystemMessageTemplates(), 200);
      })
      .catch(() => {
        console.log(
          "Something went wrong while editing user message template."
        );
      });
  };

  const resetUserTemplateForm = () =>
    setSelectedUserMessageTemplate(new UserMessageTemplateDTO());

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Grid container>
          {/* System templates */}
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: 650,
                  overflowY: "auto",
                  maxHeight: "100vh",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    pl: { sm: 2 },
                    pt: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  <b>System Templates</b>
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Template</b>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {systemMessageTemplates.map(
                      (systemMessageTemplate, index) => (
                        <TableRow
                          hover
                          selected={
                            selectedSystemMessageTemplate.id ==
                            systemMessageTemplate.id
                          }
                          key={systemMessageTemplate.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleSelectSystemTemplateSelect(
                              systemMessageTemplate
                            )
                          }
                        >
                          <TableCell>{systemMessageTemplate.name}</TableCell>
                          <TableCell>
                            {systemMessageTemplate.template}
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: "35%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                disabled={
                                  systemMessageTemplate.userMessageTemplates
                                    .length > 0
                                }
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={() =>
                                  handleSystemMessageTemplateDeleteClick(
                                    systemMessageTemplate
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          {/* System template form */}
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              component={Paper}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "350px",
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto",
                marginBottom: "20px",
              }}
            >
              <Box sx={{ marginBottom: "5px" }}>
                <Typography variant="h6">
                  <b>
                    {selectedSystemMessageTemplate.id
                      ? `Update system template`
                      : `Create new system template`}
                  </b>
                </Typography>
                <Typography variant="body1">
                  Templates are powered by{" "}
                  <a href="https://github.com/mde/ejs">EJS</a>. Language and
                  style variables are available.
                </Typography>
              </Box>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (selectedSystemMessageTemplate.id) {
                    handleSystemMessageTemplateEdit();
                  } else {
                    handleSystemMessageTemplateAdd();
                  }
                }}
                autoComplete="off"
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      name="t2"
                      type="text"
                      required
                      value={selectedSystemMessageTemplate.name}
                      onChange={handleSystemTemplateNameChange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="Template"
                      multiline={true}
                      rows={5}
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      name="t2"
                      type="text"
                      required
                      value={selectedSystemMessageTemplate.template}
                      onChange={handleSystemTemplateChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 2,
                      gap: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={false}
                      variant="contained"
                      disableElevation
                      sx={{ flexGrow: 5 }}
                    >
                      {selectedSystemMessageTemplate.id ? "Edit" : "Create"}
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      color="secondary"
                      sx={{ flexGrow: 1 }}
                      onClick={resetSystemTemplateForm}
                    >
                      Clear
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ visibility: userMessageTemplatesVisible }}>
          {/* User templates */}
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: 650,
                  overflowY: "auto",
                  maxHeight: "100vh",
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    pl: { sm: 2 },
                    pt: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  <b>User Templates</b>
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Template</b>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <b>Status</b>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userMessageTemplates.map((userMessageTemplate, index) => (
                      <TableRow
                        key={userMessageTemplate.id}
                        onClick={() =>
                          handleUserTemplateSelect(userMessageTemplate)
                        }
                        selected={
                          selectedUserMessageTemplate.id ===
                          userMessageTemplate.id
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        <TableCell>{userMessageTemplate.name}</TableCell>
                        <TableCell>{userMessageTemplate.template}</TableCell>
                        <TableCell>
                          {userMessageTemplate.isActive
                            ? "Active"
                            : "Not active"}
                        </TableCell>
                        <TableCell>
                          {userMessageTemplate.isFallback
                            ? "Fallback"
                            : "Regular"}
                        </TableCell>
                        <TableCell align="right" sx={{ minWidth: "35%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "end",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              color="error"
                              onClick={() =>
                                handleUserMessageTemplateDeleteClick(
                                  userMessageTemplate
                                )
                              }
                            >
                              Delete
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          {/* User template form */}
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              component={Paper}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "350px",
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <Box sx={{ marginBottom: "5px" }}>
                <Typography variant="h6">
                  <b>
                    {selectedUserMessageTemplate.id
                      ? `Update user template`
                      : `Create new user template`}
                  </b>
                </Typography>
                <Typography variant="body1">
                  Templates are powered by{" "}
                  <a href="https://github.com/mde/ejs">EJS</a>. Two profile
                  variables available are{" "}
                  <span>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Available Fields
                          </Typography>
                          <p style={{ whiteSpace: "pre", cursor: "help" }}>
                            {profileFieldsTooltip}
                          </p>
                        </React.Fragment>
                      }
                    >
                      <u style={{ cursor: "help" }}>sender</u>
                    </Tooltip>{" "}
                    and{" "}
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Available Fields
                          </Typography>
                          <p style={{ whiteSpace: "pre" }}>
                            {profileFieldsTooltip}
                          </p>
                        </React.Fragment>
                      }
                    >
                      <u style={{ cursor: "help" }}>receiver</u>
                    </Tooltip>
                  </span>
                  . Language and style variables are also available.
                </Typography>
              </Box>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (selectedUserMessageTemplate.id) {
                    handleUserMessageTemplateEdit();
                  } else {
                    handleUserMessageTemplateAdd();
                  }
                }}
                autoComplete="off"
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      name="t2"
                      type="text"
                      required
                      value={selectedUserMessageTemplate.name}
                      onChange={handleUserTemplateNameChange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="Template"
                      multiline={true}
                      rows={5}
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      name="t2"
                      type="text"
                      required
                      value={selectedUserMessageTemplate.template}
                      onChange={handleUserTemplateChange}
                    />
                  </Box>
                  <FormControlLabel
                    sx={{ ml: "0" }}
                    value="start"
                    control={
                      <Checkbox
                        checked={selectedUserMessageTemplate.isFallback}
                        onChange={handleUserTemplateIsFallbackChange}
                      />
                    }
                    label="Is Fallback: "
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    sx={{ ml: "0" }}
                    value="start"
                    control={
                      <Checkbox
                        disabled={selectedUserMessageTemplate.id == ""}
                        checked={selectedUserMessageTemplate.isActive}
                        onChange={handleUserTemplateActiveChange}
                      />
                    }
                    label="Active: "
                    labelPlacement="start"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 2,
                      gap: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={false}
                      variant="contained"
                      disableElevation
                      sx={{ flexGrow: 5 }}
                    >
                      {selectedUserMessageTemplate.id ? "Edit" : "Create"}
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      color="secondary"
                      sx={{ flexGrow: 1 }}
                      onClick={resetUserTemplateForm}
                    >
                      Clear
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <AlertDialog
        title="Are you sure?"
        text={
          "Deletion of system template message is permanent and irreversible"
        }
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={systemMessageTemplateDeleteDialogOpen}
        handleCancel={handleSystemMessageTemplateDeleteCancelation}
        handleConfirm={handleSystemMessageTemplateDeleteConfirmation}
      />

      <AlertDialog
        title="Are you sure?"
        text={"Deletion of user template message is permanent and irreversible"}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={userMessageTemplateDeleteDialogOpen}
        handleCancel={handleUserMessageTemplateDeleteCancelation}
        handleConfirm={handleUserMessageTemplateDeleteConfirmation}
      />

      <Snackbar
        open={successfullyDoneCRUD}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyDoneCRUD(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyDoneCRUD(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default IcebreakerTemplates;
