import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ReportIcon from "@mui/icons-material/Report";
import { display } from "@mui/system";
import { AdminRole } from "../data/model/enumerations";

export default function DefaultAppBar() {
  const userRole = localStorage.getItem("role") as AdminRole;

  const onLogoutClicked = () => {
    localStorage.clear();
    location.reload();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="transparent" position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
            }}
          >
            <Button color="inherit" component={RouterLink} to="/">
              <SvgIcon>
                <svg id="Gruppe_580" viewBox="0 0 22.237 25.943">
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="-0.092"
                      x2="1"
                      y2="0.916"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#0358a7" />
                      <stop offset="0.256" stopColor="#0358a7" />
                      <stop offset="0.719" stopColor="#32ffce" />
                      <stop offset="1" stopColor="#32ffce" />
                    </linearGradient>
                    <clipPath id="clip-path">
                      <rect
                        id="Rechteck_475"
                        data-name="Rechteck 475"
                        width="22.237"
                        height="25.943"
                        fill="url(#linear-gradient)"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Gruppe_8948"
                    data-name="Gruppe 8948"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Pfad_8577"
                      data-name="Pfad 8577"
                      d="M142.917,301.7c1.934,0,3.867-.022,5.8,0a13.269,13.269,0,0,1,8.372,3.156c.494.4.711.715.1,1.276-1.23,1.118-2.384,2.313-3.556,3.5-.337.343-.561.324-.925.018a6.664,6.664,0,0,0-4.573-1.535q-2.76.038-5.52,0c-.619-.011-.8.193-.793.784.032,1.972-.043,3.949.026,5.919a6.235,6.235,0,0,0,3.931,5.834,6.323,6.323,0,0,0,6.838-.884c.47-.365.724-.365,1.136.06q1.685,1.761,3.472,3.44c.474.447.423.705-.041,1.136-5.849,5.394-16.144,3.8-20.022-3.1a13.953,13.953,0,0,1-1.838-7.062q.036-5.833-.011-11.654c0-.729.226-.911.951-.9C138.481,301.731,140.7,301.7,142.917,301.7Z"
                      transform="translate(-135.318 -301.687)"
                      fill="url(#linear-gradient)"
                    />
                  </g>
                </svg>
              </SvgIcon>
            </Button>
            <Button color="inherit" component={RouterLink} to="/administration">
              <SettingsIcon />
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                &nbsp;Administration
              </Box>
            </Button>
            <Button color="inherit" component={RouterLink} to="/reports">
              <ReportIcon />
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                &nbsp;Reports
              </Box>
            </Button>
            {userRole === AdminRole.SUPER_ADMIN ||
            userRole === AdminRole.DEV_ADMIN ? (
              <Button
                color="inherit"
                component={RouterLink}
                to="/real-encounters"
              >
                <PinDropIcon />
                <Box
                  component="span"
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  &nbsp;Encounters
                </Box>
              </Button>
            ) : (
              <></>
            )}
          </Typography>

          <Button color="inherit" onClick={onLogoutClicked}>
            <LogoutOutlinedIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
