import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = (props: {
  open: boolean;
  text: string;
  severity: "success" | "error";
  duration: number;
  onClose: () => void;
}) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.duration}
      onClose={props.onClose}
      className="not-closing"
    >
      <Alert
        onClose={props.onClose}
        severity={props.severity}
        sx={{ width: "100%" }}
        className="not-closing"
      >
        {props.text}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
