import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import SimpleProfile from "../data/model/simple-profile";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouterLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";

interface ProfileCardProps {
  profile: SimpleProfile;
  saveState: () => void;
}

export const ProfileCard = React.memo(
  ({ profile, saveState }: ProfileCardProps) => {
    const doNotShowDelete = true;

    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          border: profile.isForReview ? 2 : 0,
          borderColor: profile.isForReview ? "error.main" : "none",
        }}
      >
        {profile.profileMedia ? (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "40%" },
              minWidth: { md: "35%" },
            }}
            height={profile.isForReview ? "256" : "260"}
            component="img"
            src={profile.profileMedia}
          />
        ) : (
          <CardMedia
            sx={{
              width: { xs: "100%", md: "40%" },
              minWidth: { md: "35%" },
              objectFit: "contain",
            }}
            height={profile.isForReview ? "256" : "260"}
            component="img"
            src={"images/no-image-icon.png"}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 1,
            height: "100%",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 1,
              }}
            >
              <Tooltip
                title={`${profile.firstName ? profile.firstName : ""} ${
                  profile.lastName ? profile.lastName : ""
                }`}
              >
                <Typography
                  variant="h5"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginLeft: "2px",
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                  }}
                >
                  {profile.firstName} {profile.lastName}
                </Typography>
              </Tooltip>
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Username:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.username || "N/A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                display: "flex",
                fontWeight: 600,
                lineHeight: 1.4,
              }}
            >
              Quote:{" "}
              <Tooltip title={profile.quote}>
                <div
                  style={{
                    width: 120,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginLeft: "2px",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  {profile.quote || "N/A"}
                </div>
              </Tooltip>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Created at:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.creationDate
                  ? format(new Date(profile.creationDate), "yyyy-MM-dd")
                  : "N/A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Last active:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.lastActive
                  ? format(new Date(profile.lastActive), "yyyy-MM-dd")
                  : "N/A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Is prioritized:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.swipesPriority > 0 ? "True" : "False"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Category:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.profileCategoryName || "N/A"}
              </span>
            </Typography>
            {!profile.isSubscribed ?
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Is subscribed:{" "}
              <span style={{ fontWeight: 400 }}>
              {profile.isSubscribed ? "True" : "False"}
              </span>
            </Typography>
            : ""}
            {profile.isExpired ?
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Is expired:{" "}
              <span style={{ fontWeight: 400 }}>
              {profile.isExpired ? "True" : "False"}
              </span>
            </Typography>
            : ""}
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Subscription Interval:{" "}
              <span style={{ fontWeight: 400 }}>
              {profile.subscriptionTypeInterval}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Subscription Type:{" "}
              <span style={{ fontWeight: 400 }}>
              {profile.subscriptionTypeSubscriptionId}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Subscribed until:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.subscriptionEndDate
                  ? format(new Date(profile.subscriptionEndDate), "yyyy-MM-dd")
                  : "N/A"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 600, lineHeight: 1.4 }}
            >
              Country:{" "}
              <span style={{ fontWeight: 400 }}>
                {profile.countryName}
              </span>
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              paddingLeft: { xs: "16px", sm: "16px" },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={saveState}
              component={RouterLink}
              to={`/profile/${profile.id}`}
              endIcon={<NavigateNextIcon />}
            >
              MORE
            </Button>
            {doNotShowDelete ? (
              <></>
            ) : (
              <IconButton
                aria-label="delete"
                size="small"
                color="error"
                sx={{
                  border: "1px solid red",
                  borderRadius: "4px",
                  padding: "4px",
                  marginRight: { xs: "24px", md: "0px" },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </CardActions>
        </Box>
      </Card>
    );
  }
);
