import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import backAxios from "../axios/back.axios";
import IcebreakerConfigurationDTO from "../data/dto/icebreaker-configuration.dto";

const IcebreakerConfiguration = () => {
  const [temperature, setTemperature] = useState<number>(0);
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(0);
  const [presencePenalty, setPresencePenalty] = useState<number>(0);
  const [maxTokens, setMaxTokens] = useState<number>(100);
  const [numberOfSuggestions, setNumberOfSuggestions] = useState<number>(3);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    successfullyUpdatedConfiguration,
    setSuccessfullyUpdatedConfiguration,
  ] = useState<boolean>(false);
  const [modifiedDate, setModifiedDate] = useState("");
  const [modifiedConfiguration, setModifiedConfiguration] =
    useState<IcebreakerConfigurationDTO>(new IcebreakerConfigurationDTO());

  useEffect(() => {
    void fetchIcebreakerConfiguration();
  }, []);

  const fetchIcebreakerConfiguration = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker-configuration`;
      const response = await backAxios.get(url);
      const data = response.data as IcebreakerConfigurationDTO;
      setTemperature(data.temperature);
      setFrequencyPenalty(data.frequencyPenalty);
      setPresencePenalty(data.presencePenalty);
      setMaxTokens(data.maxTokens);
      setNumberOfSuggestions(data.numberOfSuggestions);
      if (data.modifiedDate) {
        const prettyModifedDate =
          data.modifiedDate.toString().split("T")[0] +
          " " +
          data.modifiedDate.toString().split("T")[1].slice(0, 5);
        setModifiedDate(prettyModifedDate);
      } else {
        setModifiedDate("No modifications made yet.");
      }
    } catch (error: any) {
      console.log(
        "Something went wrong while fetching icebreaker configuration"
      );
      console.log(error);
    }
  };

  const handleClick = () => {
    async function updateConfiguration() {
      const icebreakerConfigurationDTO = new IcebreakerConfigurationDTO(
        temperature,
        frequencyPenalty,
        presencePenalty,
        maxTokens,
        numberOfSuggestions,
        new Date()
      );
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker-configuration`;
      await backAxios.patch(url, icebreakerConfigurationDTO);
    }
    updateConfiguration()
      .then(() => {
        setSuccessfullyUpdatedConfiguration(true);
        setTimeout(() => void fetchIcebreakerConfiguration(), 200);
      })
      .catch((err: any) => {
        console.log(
          "Something went wrong while updating icebreaker configuration"
        );
        //eslint-disable-next-line
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: 800,
              overflowY: "auto",
              maxHeight: "100vh",
              marginBottom: "20px",
            }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Parameter name</b>
                  </TableCell>
                  <TableCell>
                    <b>Parameter description</b>
                  </TableCell>
                  <TableCell>
                    <b>Current parameter value</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <TableCell>
                    <b>Temperature</b>
                  </TableCell>
                  <TableCell>
                    Value between 0 and 2. Higher values like 0.8 will make the
                    output more random, while lower values like 0.2 will make it
                    more focused and deterministic.
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="temperature"
                      label="Value"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={temperature}
                      InputProps={{
                        inputProps: { min: 0, max: 2, step: "0.1" },
                      }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);

                        if (value > 2) value = 2;
                        if (value < 0) value = 0;

                        setTemperature(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <TableCell>
                    <b>Frequency penalty</b>
                  </TableCell>
                  <TableCell>
                    Value between -2 and 2. Positive values penalize new tokens
                    based on their existing frequency in the text so far,
                    decreasing the model's likelihood to repeat the same line
                    verbatim.
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="frequencyPenalty"
                      label="Value"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={frequencyPenalty}
                      InputProps={{
                        inputProps: { min: -2, max: 2, step: "0.1" },
                      }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);

                        if (value > 2) value = 2;
                        if (value < -2) value = -2;

                        setFrequencyPenalty(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <TableCell>
                    <b>Presence penalty</b>
                  </TableCell>
                  <TableCell>
                    Value between -2 and 2. Positive values penalize new tokens
                    based on their existing frequency in the text so far,
                    decreasing the model's likelihood to repeat the same line
                    verbatim.
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="presencePenalty"
                      label="Value"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={presencePenalty}
                      InputProps={{
                        inputProps: { min: -2, max: 2, step: "0.1" },
                      }}
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);

                        if (value > 2) value = 2;
                        if (value < -2) value = -2;

                        setPresencePenalty(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <TableCell>
                    <b>Max tokens</b>
                  </TableCell>
                  <TableCell>
                    Integer value higher than 0. The maximum number of tokens to
                    generate in the chat completion.
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="maxTokens"
                      label="Value"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={maxTokens}
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={(e) => {
                        let value = parseInt(e.target.value);

                        if (value <= 0) value = 1;

                        setMaxTokens(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <TableCell>
                    <b>Number of suggestions</b>
                  </TableCell>
                  <TableCell>
                    Integer value higher than 0. How many chat completion
                    choices to generate for each input message.
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="numberOfSuggestions"
                      label="Value"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={numberOfSuggestions}
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={(e) => {
                        let value = parseInt(e.target.value);

                        if (value <= 0) value = 1;

                        setNumberOfSuggestions(value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: 800,
            }}
          >
            <Box
              border={1}
              borderColor={"grey.300"}
              padding={"5px 15px"}
              borderRadius={"5px"}
            >
              <Typography fontFamily={"Roboto,Helvetica,Arial,sans-serif;"}>
                <b>Last modification date:</b> {modifiedDate}
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              color="info"
              onClick={() => handleClick()}
            >
              Change icebreaker configuration
            </Button>
          </Box>
        </Box>
      </Grid>
      <Snackbar
        open={successfullyUpdatedConfiguration}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyUpdatedConfiguration(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyUpdatedConfiguration(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Icebreaker configuration successfully updated!
        </Alert>
      </Snackbar>
    </>
  );
};
export default IcebreakerConfiguration;
