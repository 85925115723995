import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState, createRef } from "react";
import backAxios from "../axios/back.axios";
import AlertDialog from "../components/AlertDialog";
import Hobby from "../data/model/hobby";
import HobbyDto from "../data/dto/hobby.dto";
import HobbyGroup from "../data/model/hobby-group";
import PaginationResponse from "../data/paginated-response";
import TablePaginationActions from "../components/TablePaginationActions";
import SearchIcon from "@mui/icons-material/Search";
import { AdminRole } from "../data/model/enumerations";

const Hobbies = () => {
  const userRole = localStorage.getItem("role") as AdminRole;

  /**
   * Data
   */
  const [hobbies, setHobbies] = useState<Hobby[]>([]);
  const [totalHobbiesCount, setTotalHobbiesCount] = useState(0);
  const [hobbiesPage, setHobbiesPage] = useState(0);
  const [hobbiesPageSize, setHobbiesPageSize] = useState(10);
  const [hobbiesNameSearch, setHobbiesNameSearch] = useState("");
  const [hobbyGroups, setHobbyGroups] = useState<HobbyGroup[]>([]);
  const [selectedHobby, setSelectedHobby] = useState<Hobby | null>();
  const [hobby, setHobby] = useState<Hobby>(new Hobby());
  const [newGroupName, setNewGroupName] = useState("");

  const textInput = createRef<HTMLInputElement>();
  /**
   * Dialogs
   */
  const [isDisableHobbyDialogOpen, setIsDisableHobbyDialogOpen] =
    useState(false);
  const [isEnableHobbyDialogOpen, setIsEnableHobbyDialogOpen] = useState(false);
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false);

  /**
   * Snackbars
   */
  const [successfulOperation, setSuccessfulOperation] = useState(false);
  const [successfulOperationText, setSuccessfulOperationText] = useState("");
  const [unsuccessfulOperation, setUnsuccessfulOperation] = useState(false);
  const [unsuccessfulOperationText, setUnsuccessfulOperationText] = useState("");


  /**
   * Fetching data functions
   * - fetchHobbyGroups
   * - fetchHobbies
   */
  const fetchHobbyGroups = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/groups`;
      const response = await backAxios.get(url);
      const data = response.data as HobbyGroup[];
      setHobbyGroups(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching hobby groups");
    }
  };

  const fetchHobbies = async () => {
    try {
      const params = {
        pageSize: hobbiesPageSize,
        page: hobbiesPage,
        hobbyName: hobbiesNameSearch,
      };
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies`;
      const response = await backAxios.get(url, { params });
      const paginatedResponse = response.data as PaginationResponse<Hobby>;
      setHobbies(paginatedResponse.data);
      setTotalHobbiesCount(paginatedResponse.total);
    } catch (error: any) {
      console.log("Something went wrong while fetching custom hobbies");
    }
  };

  useEffect(() => {
    void fetchHobbyGroups();
    void fetchHobbies();
  }, []);

  useEffect(() => {
    void fetchHobbies();
  }, [hobbiesPageSize, hobbiesPage, hobbiesNameSearch]);

  /**
   * Handle functions used for changing view appearance and background data
   *  - handleEnglishTranslationChange
   *  - handleGermanTranslationChange
   *  - handleHobbyGroupChange
   *  - handleEditCustomHobby
   *  - handleDisableHobby
   *  - handleEnableHobby
   *  - handleChangeHobbyStatusCancelation
   *  - handleCreateGroupCancelation
   *  - handleNewGroupNameChange
   */
  const handleEnglishTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHobby({
      ...hobby,
      englishTranslation: event.target.value,
    });
  };

  const handleGermanTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHobby({
      ...hobby,
      germanTranslation: event.target.value,
    });
  };

  const handleSpanishTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHobby({
      ...hobby,
      spanishTranslation: event.target.value,
    });
  };

  const handleHobbyGroupChange = (value: HobbyGroup | null) => {
    setHobby({
      ...hobby,
      hobbyGroup: value,
    });
  };

  const handleEditCustomHobby = (hobby: Hobby) => {
    setHobby(hobby);
  };

  const handleDisableHobby = (hobby: Hobby) => {
    setSelectedHobby(hobby);
    setIsDisableHobbyDialogOpen(true);
  };

  const handleEnableHobby = (hobby: Hobby) => {
    setSelectedHobby(hobby);
    setIsEnableHobbyDialogOpen(true);
  };

  const handleChangeHobbyStatusCancelation = () => {
    setSelectedHobby(new Hobby());
    setIsDisableHobbyDialogOpen(false);
    setIsEnableHobbyDialogOpen(false);
  };

  const handleCreateGroupCancelation = () => {
    setIsCreationDialogOpen(false);
    setNewGroupName("");
  };

  const handleNewGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewGroupName(event.target.value);
  };

  /**
   * Functions which communicate with backend
   *  - createOrEditHobby (createHobby & editHobby)
   *  - handleChangeHobbyStatusConfirmation
   *  - handleCreateGroupConfirmation
   */

  const createOrEditHobby = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (hobby.id) {
      editHobby();
    } else {
      createHobby();
    }
  };

  function editHobby() {
    async function editExistingHobby(url: string, data: HobbyDto) {
      await backAxios.put(url, data);
    }
    const hobbyDto = new HobbyDto(
      hobby.englishTranslation,
      hobby.germanTranslation,
      hobby.spanishTranslation,
      hobby.hobbyGroup?.id,
      ""
    );

    let url = "";
    if (hobby.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/${hobby.id}`;
    }

    editExistingHobby(url, hobbyDto)
      .then(() => {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Selected hobby successfully edited!");
        setHobby(new Hobby());
        void fetchHobbies();
      })
      .catch((err: any) => {
        console.log("Something went wrong while updating hobby");
        //eslint-disable-next-line
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Something went wrong while editing selected hobby!");
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  }

  function createHobby() {
    async function createNewHobby(url: string, data: HobbyDto) {
      await backAxios.post(url, data);
    }
    const hobbyDto = new HobbyDto(
      hobby.englishTranslation,
      hobby.germanTranslation,
      hobby.spanishTranslation,
      hobby.hobbyGroup?.id || 0,
      ""
    );
    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/hobbies`;

    createNewHobby(url, hobbyDto)
      .then(() => {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("New hobby successfully created!");
        setHobby(new Hobby());
        void fetchHobbies();
      })
      .catch((err: any) => {
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Something went wrong while creating a new hobby!");
        
        //eslint-disable-next-line
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  }

  const handleCreateGroupConfirmation = () => {
    async function createHobbyGroup(url: string, data: any) {
      await backAxios.post(url, data);
    }

    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/hobbies/groups`;

    createHobbyGroup(url, { name: newGroupName })
      .then(() => {
        setNewGroupName("");
        setIsCreationDialogOpen(false);
        setSuccessfulOperation(true);
        setSuccessfulOperationText("New hobby group successfully created!");
        setTimeout(() => void fetchHobbyGroups(), 200);
      })
      .catch(() => {
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Something went wrong while creating new hobby group!");
      });
  };

  const handleToggleHobbyStatusConfirmation = () => {
    async function toggleHobbyStatus(url: string) {
      await backAxios.post(url);
    }

    if (selectedHobby && selectedHobby.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/${selectedHobby.id}/status`;

      toggleHobbyStatus(url)
        .then(() => {
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Chosen hobby's status has been successfully updated!");
          setSelectedHobby(new Hobby());
          setIsDisableHobbyDialogOpen(false);
          setIsEnableHobbyDialogOpen(false);
          setTimeout(() => void fetchHobbies(), 200);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Failed to update hobby's status!");
        });
    }
  };

  /**
   * Form appearance functions
   *  - resetForm
   *  - isSubmitDisabled
   */

  const resetForm = () => setHobby(new Hobby());

  const isSubmitDisabled = (): boolean | undefined => {
    if (hobby.id) {
      return false;
    } else {
      if (
        !hobby.englishTranslation ||
        !hobby.germanTranslation ||
        !hobby.hobbyGroup
      ) {
        return true;
      }
      return false;
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 650,
                overflowY: "auto",
                maxHeight: "100vh",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  margin: "16px",
                }}
              >
                <form
                  style={{ display: "flex", flexDirection: "row" }}
                  onSubmit={(event) => {
                    setHobbiesNameSearch(textInput.current?.value as string);
                    setHobbiesPage(0);
                    event.preventDefault();
                  }}
                >
                  <Box sx={{ flex: "1 0 auto" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Hobby name"
                      type="text"
                      inputRef={textInput}
                    ></TextField>
                  </Box>
                  <Box sx={{ flex: "0 0 auto" }}>
                    <IconButton type="submit">
                      <SearchIcon></SearchIcon>
                    </IconButton>
                  </Box>
                </form>
              </Box>

              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>English</b>
                    </TableCell>
                    <TableCell>
                      <b>German</b>
                    </TableCell>
                    <TableCell>
                      <b>Spanish</b>
                    </TableCell>
                    <TableCell>
                      <b>Group</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hobbies.map((hobby, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <TableCell
                        sx={{ color: hobby.isActive ? "black" : "gray" }}
                      >
                        {hobby.englishTranslation}
                      </TableCell>
                      <TableCell
                        sx={{ color: hobby.isActive ? "black" : "gray" }}
                      >
                        {hobby.germanTranslation}
                      </TableCell>
                      <TableCell
                        sx={{ color: hobby.isActive ? "black" : "gray" }}
                      >
                        {hobby.spanishTranslation}
                      </TableCell>
                      <TableCell
                        sx={{ color: hobby.isActive ? "black" : "gray" }}
                      >
                        {hobby.hobbyGroup?.name}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "end",
                          }}
                        >
                          {userRole === AdminRole.VIEWER ? (
                            <></>
                          ) : (
                            <>
                              {" "}
                              <Button
                                variant="contained"
                                size="small"
                                color="info"
                                onClick={() => handleEditCustomHobby(hobby)}
                              >
                                Edit
                              </Button>
                              {hobby.isActive === true ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="warning"
                                  onClick={() => handleDisableHobby(hobby)}
                                >
                                  Disable
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="success"
                                  onClick={() => handleEnableHobby(hobby)}
                                >
                                  Enable
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={4}
                      count={totalHobbiesCount}
                      rowsPerPage={hobbiesPageSize}
                      rowsPerPageOptions={[10, 20, 30]}
                      page={hobbiesPage}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={(event, page) => {
                        setHobbiesPage(page);
                      }}
                      onRowsPerPageChange={(event) => {
                        const pageSize = Number.parseInt(
                          event.currentTarget.value
                        );
                        setHobbiesPageSize(pageSize);
                      }}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          {userRole === AdminRole.VIEWER ? (
            <></>
          ) : (
            <>
              <Box
                component={Paper}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: "350px",
                  padding: "20px",
                  borderRadius: "8px",
                  margin: "0 auto",
                }}
              >
                <Box sx={{ marginBottom: "5px" }}>
                  <Typography variant="h6">
                    <b>
                      {hobby.id ? "Edit selected hobby" : "Create a new hobby"}
                    </b>
                  </Typography>
                  <Typography variant="body1">
                    {hobby.id
                      ? "Fill this form to edit selected hobby"
                      : "Fill this form to create a new hobby"}
                  </Typography>
                </Box>
                <form onSubmit={createOrEditHobby}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {errorMessage && (
                      <Box
                        sx={{
                          backgroundColor: "#d32f2f",
                          color: "white",
                          borderRadius: "4px",
                          lineHeight: "1.4375em",
                          padding: "12px 14px",
                        }}
                      >
                        {errorMessage}
                      </Box>
                    )}
                    <Box>
                      <TextField
                        variant="standard"
                        label="English translation"
                        sx={{ width: "100%" }}
                        autoComplete="english-translation"
                        name="t2"
                        type="text"
                        placeholder="Enter english translation"
                        required
                        value={hobby.englishTranslation}
                        onChange={handleEnglishTranslationChange}
                      />
                    </Box>
                    <Box>
                      <TextField
                        variant="standard"
                        label="German translation"
                        sx={{ width: "100%" }}
                        autoComplete="german-translation"
                        name="t2"
                        type="text"
                        placeholder="Enter german translation"
                        required
                        value={hobby.germanTranslation}
                        onChange={handleGermanTranslationChange}
                      />
                    </Box>
                    <Box>
                      <TextField
                        variant="standard"
                        label="Spanish translation"
                        sx={{ width: "100%" }}
                        autoComplete="spanish-translation"
                        name="t2"
                        type="text"
                        placeholder="Enter spanish translation"
                        required
                        value={hobby.spanishTranslation}
                        onChange={handleSpanishTranslationChange}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
                        <Autocomplete
                          sx={{ flexGrow: 2 }}
                          disablePortal
                          id="combo-box-demo"
                          options={hobbyGroups}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              label="Hobby group"
                              {...params}
                              required
                            />
                          )}
                          getOptionLabel={(hg) => hg.name}
                          onChange={(e, value) => handleHobbyGroupChange(value)}
                          value={hobby.hobbyGroup}
                        />
                        <IconButton
                          color="primary"
                          onClick={() => setIsCreationDialogOpen(true)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 2,
                        gap: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        disabled={isSubmitDisabled()}
                        variant="contained"
                        disableElevation
                        sx={{ flexGrow: 5 }}
                      >
                        {hobby.id ? "Edit" : "Create"}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        sx={{ flexGrow: 1 }}
                        onClick={resetForm}
                      >
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={isCreationDialogOpen}
        onClose={handleCreateGroupCancelation}
      >
        <DialogTitle>Create new hobby group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            Fill out this form to create a new hobby group
          </DialogContentText>
          <TextField
            sx={{ width: "100%" }}
            autoComplete="group-name"
            name="t2"
            type="text"
            placeholder="Enter hobby group name"
            required
            onChange={handleNewGroupNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCreateGroupCancelation}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={handleCreateGroupConfirmation}
            disabled={newGroupName ? false : true}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        title="Are you sure?"
        text={"Disable of the selected hobby can be later undone."}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDisableHobbyDialogOpen}
        handleCancel={handleChangeHobbyStatusCancelation}
        handleConfirm={handleToggleHobbyStatusConfirmation}
      />

      <AlertDialog
        title="Are you sure?"
        text={"Enabling of the selected hobby will be seen by other users."}
        confirmText="Confirm"
        confirmColor="success"
        cancelText="Cancel"
        open={isEnableHobbyDialogOpen}
        handleCancel={handleChangeHobbyStatusCancelation}
        handleConfirm={handleToggleHobbyStatusConfirmation}
      />

      <Snackbar
        open={successfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfulOperation(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {successfulOperationText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={unsuccessfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfulOperation(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {unsuccessfulOperationText}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Hobbies;
