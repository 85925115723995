import {
  Circle,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import { BlueMarker } from "./BlueMarker";
import { GrayMarker } from "./GrayMarker";
import { PurpleMarker } from "./PurpleMarker";

type AvailableColors = "blue" | "purple" | "gray";

function PinColorToMarker(color?: AvailableColors) {
  if (color === "gray") return GrayMarker;
  else if (color === "purple") return PurpleMarker;
  else return BlueMarker; // A default one
}

function CenterSetter(props: { lat: number; lng: number }) {
  const map = useMap();
  map.flyTo(props, 17);
  return null;
}

const Map = (props: {
  pins?: {
    lng: number;
    lat: number;
    radius?: number;
    text: string;
    color?: AvailableColors;
  }[];
}) => {
  // If nothing is provided, move to Berlin
  const center =
    props.pins && props.pins.length > 0
      ? props.pins[0]
      : { lng: 13.404954, lat: 52.520008 };

  return (
    <div>
      <MapContainer
        center={{ lat: center.lat, lng: center.lng }}
        zoom={17}
        scrollWheelZoom={false}
        style={{ width: "100%", height: "75vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.pins ? (
          <>
            {props.pins.map((pin, i) => {
              return (
                <div key={i}>
                  <Marker
                    position={{ lat: pin.lat, lng: pin.lng }}
                    icon={PinColorToMarker(pin.color)}
                  >
                    <Popup>{pin.text}</Popup>
                  </Marker>
                  {pin.radius ? (
                    <>
                      <Circle
                        center={{ lat: pin.lat, lng: pin.lng }}
                        radius={pin.radius}
                        color="#44E7FF99"
                      ></Circle>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
        <CenterSetter lat={center.lat} lng={center.lng} />
      </MapContainer>
    </div>
  );
};

export default Map;
export type { AvailableColors };
