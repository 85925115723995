import { Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import backAxios from "../axios/back.axios";
import { BASE_URL } from "../data/constants";
import Comment from "../data/model/comment";

interface CommentCardProps {
  commentId: string;
  profileId?: string;
}

const CommentCard = ({ commentId, ...props }: CommentCardProps) => {
  const [comment, setComment] = useState<Comment | null>(null);
  const { profileId: paramProfileId } = useParams<{ profileId: string }>();
  
  const profileId = props.profileId ?? paramProfileId;

  const fetchComment = async () => {
    try {
      if (profileId) {
      const response = await backAxios.get(
        `${BASE_URL}/${profileId}/comments/${commentId}`,
      );
      const comm = response.data as Comment;
      setComment(comm);
      return comm;
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching comment");
      console.log(error);
    }
  };

  useEffect(function initialLoad() {
    void fetchComment();
  }, []);

  if (!comment) {
    // TODO: Show loader
    return null;
  }

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "16px !important",
        }}
      >
        <Typography
          sx={{ fontSize: 14, overflowWrap: "anywhere", textAlign: "left" }}
          color="text.secondary"
        >
          {comment?.text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CommentCard;
