import { Button, Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import backAxios from "../axios/back.axios";
import Comment from "../data/model/comment";
import Report from "../data/model/report";
import ReportView from "./ReportView";

interface CommentCardProps {
  comment: Comment;
}

const CommentCard = (props: CommentCardProps) => {
  const [comment, setComment] = useState<Comment>(props.comment);
  const [reports, setReports] = useState<Report[]>([]);
  const [toggleReportView, setToggleReportView] = useState(false);

  const fetchCommentReports = async () => {
    try {
      const response = await backAxios.get(
        `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/comments/${comment.id}/reports`,
        {
          params: {
            isSubcomment: comment.isSubcomment,
          },
        }
      );
      const reps = response.data as Report[];
      setReports(reps);
      return reps;
    } catch (error: any) {
      console.log("Something went wrong while fetching comment reports");
      console.log(error);
    }
  };

  const openReportView = () => {
    fetchCommentReports()
      .then(() => setToggleReportView(true))
      .catch(() => console.log("Couldn't get reports"));
  };
  const closeReportView = () => setToggleReportView(false);
  const onReportsChanged = () => {
    fetchCommentReports()
      .then((updatedReports) => {
        if (updatedReports && updatedReports.length == 0) {
          comment.isForReview = false;
          setComment(comment);
          closeReportView();
        }
      })
      .catch(() => console.log("Report couldn't changed"));
  };

  return (
    <Card
      sx={{
        border: comment.isForReview ? 2 : 0,
        borderColor: comment.isForReview ? "error.main" : "none",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "16px !important",
        }}
      >
        <Typography
          sx={{ fontSize: 14, overflowWrap: "anywhere", textAlign: "left" }}
          color="text.secondary"
        >
          {comment.text}
        </Typography>
        <Typography>
          {comment.isForReview && (
            <>
              <Button
                onClick={openReportView}
                variant="contained"
                size="small"
                color="error"
              >
                Resolve reports
              </Button>
              <ReportView
                toggle={toggleReportView}
                onClose={closeReportView}
                reports={reports}
                onReportsChanged={onReportsChanged}
              />
            </>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CommentCard;
