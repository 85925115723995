import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SystemMessageTemplateDTO from "../data/dto/system-message-template.dto";
import backAxios from "../axios/back.axios";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import UserMessageTemplateDTO from "../data/dto/user-message-template.dto";
import GenerateSuggestionsHelperDTO from "../data/dto/generate-suggestions-helper.dto";
import GeneratedSuggestionsInfoDTO from "../data/dto/generated-suggestions-info.dto";
import GenerateRenderSuggestionsHelperDTO from "../data/dto/generate-render-suggestions-helper.dto";
import RendersDTO from "../data/dto/renders.dto";

const trimTextToLength = (text: string, maxLength: number) => {
  if (text) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  } else {
    return "";
  }
};

const IcebreakerTestingPanel = () => {
  const [systemMessageTemplates, setSystemMessageTemplates] = useState<
    SystemMessageTemplateDTO[]
  >([]);
  const [selectedSystemMessageTemplate, setSelectedSystemMessageTemplate] =
    useState<SystemMessageTemplateDTO>(new SystemMessageTemplateDTO());
  const [selectedUserMessageTemplate, setSelectedUserMessageTemplate] =
    useState<UserMessageTemplateDTO>(new UserMessageTemplateDTO());
  const [toggleMap, setToggleMap] = useState<Map<string, boolean>>(new Map());
  const updateMap = (k: string, v: boolean) => {
    setToggleMap(new Map(toggleMap.set(k, v)));
  };
  const [tabValue, setTabValue] = useState(0);

  const [selectedUserMessageHistory, setSelectedUserMessageHistory] = useState<
    GeneratedSuggestionsInfoDTO[]
  >([]);

  const [senderUsername, setSenderUsername] = useState("");
  const [receiverUsername, setReceiverUsername] = useState("");
  const [language, setLanguage] = useState("");
  const [style, setStyle] = useState("");

  const [renders, setRenders] = useState<RendersDTO>(new RendersDTO());
  const [rendersVisible, setRendersVisible] = useState(false);

  const [suggestionsInfoVisible, setSuggestionInfoVisible] = useState(false);
  const [progressGenerationVisible, setProgressGenerationVisible] =
    useState(false);
  const [progressRenderGenerationVisible, setProgressRenderGenerationVisible] =
    useState(false);

  const [generatedSuggestionsInfo, setGeneratedSuggestionsInfo] =
    useState<GeneratedSuggestionsInfoDTO>(new GeneratedSuggestionsInfoDTO());

  const [
    successfullyGeneratedSuggestions,
    setSuccessfullyGeneratedSuggestions,
  ] = useState(false);
  const [
    unsuccessfullyGeneratedSuggestions,
    setUnsuccessfullyGeneratedSuggestions,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    void fetchSystemMessageTemplates();
  }, []);

  const fetchSystemMessageTemplates = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/system-message-templates`;
      const response = await backAxios.get(url);
      const data = response.data as SystemMessageTemplateDTO[];
      setSystemMessageTemplates(data);

      const map = new Map();

      for (const s of data) {
        map.set(s.id, false);
      }

      setToggleMap(map);
    } catch (error: any) {
      console.log(
        "Something went wrong while fetching system message templates"
      );
      console.log(error);
    }
  };

  const fetchUserMessageHistory = async (
    selectedUserMessageTemplate: UserMessageTemplateDTO
  ) => {
    if (!selectedUserMessageTemplate) {
      return;
    }

    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/user-message-templates/${
        selectedUserMessageTemplate.id
      }/messages-groups`;
      const response = await backAxios.get(url);

      const data = response.data as GeneratedSuggestionsInfoDTO[];
      setSelectedUserMessageHistory(data);
    } catch (error: any) {
      console.log(
        "Something went wrong while fetching user message template's history"
      );
      console.log(error);
    }
  };

  const handleClickedSystemMessageTemplate = (
    systemMessageTemplate: SystemMessageTemplateDTO
  ) => {
    updateMap(
      systemMessageTemplate.id,
      !toggleMap.get(systemMessageTemplate.id)
    );
  };

  const handleUserMessageTemplateClick = (
    userMessageTemplate: UserMessageTemplateDTO
  ) => {
    setSelectedUserMessageTemplate(userMessageTemplate);
    void fetchUserMessageHistory(userMessageTemplate);
    for (let i = 0; i < systemMessageTemplates.length; i++) {
      const systemMessage = systemMessageTemplates[i];
      const foundSameUserTemplate = systemMessage.userMessageTemplates.find(
        (umt) => umt.id == userMessageTemplate.id
      );
      if (foundSameUserTemplate) {
        setSelectedSystemMessageTemplate(systemMessageTemplates[i]);
        return;
      }
    }
  };

  const handleTabValue = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);

    // If showing history
    // Invoke fetch/refresh
    if (newTabValue == 1 && selectedUserMessageTemplate.id) {
      void fetchUserMessageHistory(selectedUserMessageTemplate);
    }
  };

  const handleSenderUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSenderUsername(event.target.value);
  };

  const handleReceiverUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiverUsername(event.target.value);
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
  };

  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStyle(event.target.value);
  };

  const isTestsButtonDisabled = (): boolean => {
    if (
      !senderUsername ||
      !receiverUsername ||
      !selectedUserMessageTemplate.template
    ) {
      return true;
    }
    return false;
  };

  const generateSuggestions = async () => {
    try {
      setProgressGenerationVisible(true);
      setRenders(new RendersDTO());
      setRendersVisible(false);

      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/suggestions`;

      const suggestionsHelper = new GenerateSuggestionsHelperDTO(
        senderUsername,
        receiverUsername,
        selectedUserMessageTemplate.id,
        language,
        style
      );
      const response = await backAxios.post(url, suggestionsHelper);

      setSuggestionInfoVisible(false);
      setProgressGenerationVisible(false);
      const data = response.data as GeneratedSuggestionsInfoDTO;
      setGeneratedSuggestionsInfo(data);
      setSuggestionInfoVisible(true);
      setSuccessfullyGeneratedSuggestions(true);
    } catch (error: any) {
      console.log("Something went wrong while generating suggestions");
      //eslint-disable-next-line
      setErrorMessage(error.response.data.message);
      setProgressGenerationVisible(false);
      setUnsuccessfullyGeneratedSuggestions(true);
    }
  };

  const getRenders = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/renders`;

      const suggestionsHelper = new GenerateSuggestionsHelperDTO(
        senderUsername,
        receiverUsername,
        selectedUserMessageTemplate.id,
        language,
        style
      );
      const response = await backAxios.post(url, suggestionsHelper);
      setSuggestionInfoVisible(false);
      const data = response.data as RendersDTO;
      setRenders(data);
      setRendersVisible(true);
    } catch (error: any) {
      console.log("Something went wrong while generating suggestions");
      //eslint-disable-next-line
      setErrorMessage(error.response.data.message);
      setProgressGenerationVisible(false);
      setUnsuccessfullyGeneratedSuggestions(true);
    }
  };

  const handleSystemMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRenders({
      systemMessage: event.target.value,
      userMessage: renders.userMessage,
    });
  };

  const handleUserMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRenders({
      systemMessage: renders.systemMessage,
      userMessage: event.target.value,
    });
  };

  const generateRenderSuggestions = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/icebreaker/renders/suggestions`;

      setProgressRenderGenerationVisible(true);

      const renderSuggestionsHelper = new GenerateRenderSuggestionsHelperDTO(
        senderUsername,
        receiverUsername,
        renders.systemMessage,
        renders.userMessage,
        selectedUserMessageTemplate.id
      );
      const response = await backAxios.post(url, renderSuggestionsHelper);

      setSuggestionInfoVisible(false);
      setProgressRenderGenerationVisible(false);
      const data = response.data as GeneratedSuggestionsInfoDTO;
      setGeneratedSuggestionsInfo(data);
      setSuggestionInfoVisible(true);
      setSuccessfullyGeneratedSuggestions(true);
    } catch (error: any) {
      console.log("Something went wrong while generating suggestions");
      //eslint-disable-next-line
      setErrorMessage(error.response.data.message);
      setProgressRenderGenerationVisible(false);
      setUnsuccessfullyGeneratedSuggestions(true);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <List
          sx={{
            bgcolor: "background.paper",
            borderRight: "1px solid rgb(144, 144, 144, 0.5)",
            overflowY: "auto",
            height: "100%",
            minHeight: "100vh",
            width: "300px",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListSubheader>System message templates</ListSubheader>
          {systemMessageTemplates.map((systemMessageTemplate, index) => (
            <div key={index}>
              <ListItemButton
                onClick={() => {
                  handleClickedSystemMessageTemplate(systemMessageTemplate);
                }}
                key={index}
              >
                <ListItemText
                  primary={systemMessageTemplate.name}
                  secondary={trimTextToLength(
                    systemMessageTemplate.template,
                    197
                  )}
                />
                {toggleMap.get(systemMessageTemplate.id) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              <Collapse
                in={toggleMap.get(systemMessageTemplate.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListSubheader>User message templates</ListSubheader>
                  {systemMessageTemplate.userMessageTemplates.map(
                    (userMessageTemplate, index) => (
                      <ListItemButton
                        sx={{ paddingLeft: 6 }}
                        key={index}
                        onClick={() => {
                          handleUserMessageTemplateClick(userMessageTemplate);
                        }}
                        selected={
                          selectedUserMessageTemplate.id ===
                          userMessageTemplate.id
                        }
                      >
                        <ListItemText
                          primary={`${userMessageTemplate.name}${
                            !userMessageTemplate.isFallback ? "" : " (fallback)"
                          }${
                            userMessageTemplate.isActive ? "" : " (inactive)"
                          }`}
                          secondary={trimTextToLength(
                            userMessageTemplate.template,
                            197
                          )}
                        />
                      </ListItemButton>
                    )
                  )}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
        <Box marginLeft={"20px"} marginRight={"20px"} maxWidth={"60vw"}>
          {selectedUserMessageTemplate.id != "" ? (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabValue}>
                  <Tab label="Tests" />
                  <Tab label="History" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabValue === 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      border={1}
                      borderColor={"grey.300"}
                      padding={"5px 15px"}
                      borderRadius={"5px"}
                    >
                      <Typography
                        fontFamily={"Roboto,Helvetica,Arial,sans-serif;"}
                      >
                        <b>Selected system message template:</b>{" "}
                        {selectedSystemMessageTemplate.template}
                      </Typography>
                      <Typography
                        fontFamily={"Roboto,Helvetica,Arial,sans-serif;"}
                      >
                        <b>Selected user message template:</b>{" "}
                        {selectedUserMessageTemplate.template}
                      </Typography>
                    </Box>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Box sx={{ display: "flex", marginTop: "10px" }}>
                        <Box sx={{ marginRight: "20px" }}>
                          <TextField
                            variant="standard"
                            label="Sender's username"
                            sx={{ width: "100%" }}
                            autoComplete="off"
                            name="t2"
                            type="text"
                            required
                            value={senderUsername}
                            onChange={handleSenderUsernameChange}
                          />
                        </Box>
                        <Box sx={{ marginRight: "20px" }}>
                          <TextField
                            variant="standard"
                            label="Receiver's username"
                            sx={{ width: "100%" }}
                            name="t2"
                            type="text"
                            required
                            value={receiverUsername}
                            onChange={handleReceiverUsernameChange}
                          />
                        </Box>
                        <Box sx={{ marginRight: "20px" }}>
                          <TextField
                            variant="standard"
                            label="Language"
                            sx={{ width: "100%" }}
                            name="t2"
                            type="text"
                            value={language}
                            onChange={handleLanguageChange}
                          />
                        </Box>
                        <Box>
                          <TextField
                            variant="standard"
                            label="Style"
                            sx={{ width: "100%" }}
                            name="t2"
                            type="text"
                            value={style}
                            onChange={handleStyleChange}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", marginTop: "5px" }}>
                        <Box sx={{ marginTop: "5px", marginLeft: "auto" }}>
                          <Button
                            variant="contained"
                            disabled={isTestsButtonDisabled()}
                            type="button"
                            onClick={() => void generateSuggestions()}
                            sx={{ marginRight: "10px" }}
                          >
                            Get suggestions
                            {progressGenerationVisible ? (
                              <CircularProgress
                                size="1rem"
                                sx={{ marginLeft: "3px", color: "white" }}
                              />
                            ) : (
                              <></>
                            )}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isTestsButtonDisabled()}
                            type="button"
                            onClick={() => void getRenders()}
                          >
                            Get Renders
                          </Button>
                        </Box>
                      </Box>
                    </form>

                    {rendersVisible ? (
                      <Grid container sx={{ marginTop: "50px" }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                            <CardContent
                              sx={{ paddingBottom: "16px !important" }}
                            >
                              <Typography fontSize={"20px"}>
                                System message
                              </Typography>
                              <TextField
                                sx={{ width: "100%" }}
                                onChange={handleSystemMessageChange}
                                value={renders.systemMessage}
                                multiline
                                rows={5}
                              ></TextField>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              paddingBottom: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <CardContent
                              sx={{ paddingBottom: "16px !important" }}
                            >
                              <Typography fontSize={"20px"}>
                                User message
                              </Typography>
                              <TextField
                                sx={{ width: "100%" }}
                                onChange={handleUserMessageChange}
                                value={renders.userMessage}
                                multiline
                                rows={5}
                              ></TextField>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                          >
                            <CardContent
                              sx={{
                                padding: "8px !important",
                              }}
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => void generateRenderSuggestions()}
                              >
                                Get Suggestions
                                {progressRenderGenerationVisible ? (
                                  <CircularProgress
                                    size="1rem"
                                    sx={{ marginLeft: "3px", color: "white" }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {suggestionsInfoVisible ? (
                      <Grid container sx={{ marginTop: "50px" }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography fontSize={"20px"}>
                                Message group info
                              </Typography>
                              <Typography>
                                <b>System message: </b>{" "}
                                {
                                  generatedSuggestionsInfo.messageGroup
                                    .systemMessage
                                }
                              </Typography>
                              <Typography>
                                <b>User message: </b>{" "}
                                {
                                  generatedSuggestionsInfo.messageGroup
                                    .userMessage
                                }
                              </Typography>
                              <Typography>
                                <b>Date of creation: </b>{" "}
                                {generatedSuggestionsInfo.messageGroup.createdDate
                                  .toString()
                                  .split("T")[0] +
                                  " " +
                                  generatedSuggestionsInfo.messageGroup.createdDate
                                    .toString()
                                    .split("T")[1]
                                    .slice(0, 5)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography fontSize={"20px"}>
                                Generated suggestions
                              </Typography>
                              {generatedSuggestionsInfo.suggestions.map(
                                (suggestion, index) => (
                                  <Typography key={index}>
                                    <b>{index + 1}.</b> {suggestion.suggestion}
                                  </Typography>
                                )
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
                {tabValue === 1 && (
                  <Box>
                    <Typography color="gray">
                      The last 10 queries for the selected user message template
                    </Typography>

                    {selectedUserMessageHistory.length > 0 ? (
                      selectedUserMessageHistory.map((info, index) => {
                        return (
                          <Grid
                            key={index}
                            container
                            sx={{ marginTop: "10px" }}
                          >
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Card
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                <CardContent>
                                  <Typography fontSize={"20px"}>
                                    Message group info
                                  </Typography>
                                  <Typography>
                                    <b>System message: </b>{" "}
                                    {info.messageGroup.systemMessage}
                                  </Typography>
                                  <Typography>
                                    <b>User message: </b>{" "}
                                    {info.messageGroup.userMessage}
                                  </Typography>
                                  <Typography>
                                    <b>Date of creation: </b>{" "}
                                    {info.messageGroup.createdDate
                                      .toString()
                                      .split("T")[0] +
                                      " " +
                                      info.messageGroup.createdDate
                                        .toString()
                                        .split("T")[1]
                                        .slice(0, 5)}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Card
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              >
                                <CardContent>
                                  <Typography fontSize={"20px"}>
                                    Generated suggestions
                                  </Typography>
                                  {info.suggestions.map((suggestion, index) => (
                                    <Typography key={index}>
                                      <b>{index + 1}.</b>{" "}
                                      {suggestion.suggestion}
                                    </Typography>
                                  ))}
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Snackbar
        open={successfullyGeneratedSuggestions}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyGeneratedSuggestions(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyGeneratedSuggestions(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Suggestions successfully generated!
        </Alert>
      </Snackbar>
      <Snackbar
        open={unsuccessfullyGeneratedSuggestions}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfullyGeneratedSuggestions(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfullyGeneratedSuggestions(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default IcebreakerTestingPanel;
