export enum AdminRole {
  SUPER_ADMIN = "Super Admin",
  DEV_ADMIN = "Dev Admin",
  ADMIN = "Admin",
  MODERATOR = "Moderator",
  VIEWER = "Viewer",
}

export enum SuspensionDuration {
  ONE_DAY = "1",
  TWO_DAYS = "2",
  WEEK = "7",
  TWO_WEEKS = "14",
  MONTH = "30",
  PERMANENT = "Permanent",
}

