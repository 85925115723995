import { parseJwt } from "../helpers/jwt-helper";
import { Outlet, Navigate } from "react-router-dom";

const TwoFactorAuthRoute = () => {
    const token = localStorage.getItem("access_token");
    const hasToken = Boolean(token);

    if(!hasToken){
        return  <Navigate to="/login" />;
    }

    const parsedToken = parseJwt(token!);

    const shouldPerform2FA = () => {
        if(!parsedToken.fullyAuthenticated){
            return true;
        }
    }
    
    return (
        shouldPerform2FA() ? (
            <Outlet />
        ) : (
            <Navigate to="/"/>
        )
    );

};

export default TwoFactorAuthRoute;