// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import CakeIcon from "@mui/icons-material/Cake";
import CategoryIcon from "@mui/icons-material/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from "@mui/icons-material/Person";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import WorkIcon from "@mui/icons-material/Work";
import OnDeviceTrainingTwoToneIcon from '@mui/icons-material/OnDeviceTrainingTwoTone';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import backAxios from "../axios/back.axios";
import Alert from "../components/Alert";
import AlertDialog from "../components/AlertDialog";
import CarouselModal from "../components/CarouselModal";
import Item from "../components/Item";
import TabPanel from "../components/TabPanel";
import { BASE_URL } from "../data/constants";
import BlogPost from "../data/model/blog-post";
import Comment from "../data/model/comment";
import CompleteProfile from "../data/model/complete-profile";
import CustomHobby from "../data/model/custom-hobby";
import { AdminRole, SuspensionDuration } from "../data/model/enumerations";
import Media from "../data/model/media";
import ProfileCategory from "../data/model/profile-category";
import ProfileLog from "../data/model/profile-log";
import Report from "../data/model/report";
import SimpleProfile from "../data/model/simple-profile";
import Subscription from "../data/model/subscription";
import Suspension from "../data/model/suspension";
import { getDateAndTimeAsString, getDateOnlyAsString } from "../helpers/date-helpers";
import { getNImages } from "../helpers/images-helper";
import BlogPostCardReport from "../reports/BlogPostCard";
import { BlogPostMediaCard } from "../reports/BlogPostMediaCard";
import CommentCardReport from "../reports/CommentCard";
import { ProfileMediaCard } from "../reports/ProfileMediaCard";
import BlogPostCard from "./BlogPostCard";
import CommentCard from "./CommentCard";
import SuspensionDialog from "./SuspensionDialog";
import { DashboardState } from "./dashboard.model";



interface SuspensionReason {
  id: number;
  name: string;
}

const getDialogContent = (report: Report | null) => {
  if (!report) {
    return null;
  }

  switch (report.reportType) {
    case 'BLOG_POST':
      return <BlogPostCardReport blogPostId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'COMMENT':
    case 'SUBCOMMENT':
      return <CommentCardReport commentId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'PROFILE':
      return;
    case 'PROFILE_MEDIA':
      return <ProfileMediaCard profileMediaId={report.reportedRecordId} profileId={report.reportedProfieId} />
    case 'BLOG_POST_MEDIA':
      return <BlogPostMediaCard blogPostMediaId={report.reportedRecordId} profileId={report.reportedProfieId} />
    default:
      console.error('Unknown report type', report.reportType);
      return null;
  }
}

const ProfileView = () => {
  const MAX_PRIORITY = 999;
  const MIN_PRIORITY = 0;
  const UPDATE_DEFFERAL_MILLIS = 200;

  const navigate = useNavigate();
  const theme = useTheme();
  
  // The connection to dashboard state (because of the endless scroll)
  const [dashboardState, setDashboardState] =
    useOutletContext<
      [
        DashboardState | undefined,
        React.Dispatch<React.SetStateAction<DashboardState | undefined>>
      ]
    >();
  const updateDashboardStateProfile = async (profileId: string) => {
    if (dashboardState) {
      try {
        if (profileId) {
          const response = await backAxios.get(
            `${BASE_URL}/${profileId}/simple-version`
          );
          const simpleProfile = response.data as SimpleProfile;
          const updatedProfiles = dashboardState.profiles.map<SimpleProfile[]>(
            (sp) => {
              return sp.id === profileId ? simpleProfile : sp;
            }
          );

          setDashboardState({
            ...dashboardState,
            profiles: updatedProfiles,
          } as DashboardState);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  const removeDashboardStateProfile = (profileId: string) => {
    if (dashboardState) {
      const filteredProfiles = dashboardState.profiles.filter<SimpleProfile[]>(
        (sp) => {
          return sp.id !== profileId;
        }
      );

      setDashboardState({
        ...dashboardState,
        profiles: filteredProfiles,
      } as DashboardState);
    }
  };

  // Profile data
  const userRole = localStorage.getItem("role") as AdminRole;
  const { profileId } = useParams<{ profileId: string }>();
  const [profile, setProfile] = useState<CompleteProfile | null>(null);

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [suspensions, setSuspensions] = useState<Suspension[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);

  // Lists
  const [lastSubscription, setLastSubscription] = useState<Subscription | null>(null);
  const [profileCategories, setProfileCategories] = useState<ProfileCategory[]>([]);
  const [suspensionReasons, setSuspensionReasons] = useState<SuspensionReason[]>([]);

  // Page states
  const [suspensionDuration, setSuspensionDuration] =
  useState<SuspensionDuration>(SuspensionDuration.ONE_DAY);
  const [suspensionReasonId, setSuspensionReasonId] = useState<number>(1);
  const [report, setReport] = useState<Report | null>(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [toggleCarouselModal, setToggleCarouselModal] = useState(false);
  const [carouselModalSelectedItem, setCarouselModalSelectedItem] =
    useState(-1);

  const [customHobby, setCustomHobby] = useState<CustomHobby>(null);
  
  const [isDeleteCustomHobbyDialogOpen, setDeleteCustomHobbyDialogOpen] =
    useState(false);
  const [isDeleteSwipesDialogOpen, setDeleteSwipesDialogOpen] = useState(false);

  const [reportNote, setReportNote] = useState("");

  const [selectedCategory, setSelectedCategory] =
    useState<ProfileCategory | null>(null);
  const [isEditCategoryDialogOpen, setIsEditCategoryDialogOpen] =
    useState(false);

  const [isSuspendDialogOpen, setSuspendDialogOpen] = useState(false);
  const [isUnsuspendDialogOpen, setUnsuspendDialogOpen] = useState(false);
  const [isSubscribeDialogOpen, setSubscribeDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAlertAccept, setOpenAlertAccept] = React.useState(false);
  const [openAlertDismiss, setOpenAlertDismiss] = React.useState(false);

  const [successfulOperation, setSuccessfulOperation] = useState(false);
  const [successfulOperationText, setSuccessfulOperationText] = useState("");
  const [unsuccessfulOperation, setUnsuccessfulOperation] = useState(false);
  const [unsuccessfulOperationText, setUnsuccessfulOperationText] = useState("");  

  useEffect(() => {
    void fetchProfile();
    void fetchProfilesLastSubscription();
    void fetchBlogPosts();
    void fetchComments();
    void fetchProfileCategories();
    void fetchSuspensions();
    void fetchReports();
    void fetchSuspensionReasons();
  }, []);


  // Retrieval of page data
  const fetchProfileCategories = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profile-categories`;
      const response = await backAxios.get(url);
      const categories = response.data as ProfileCategory[];
      setProfileCategories(categories);
    } catch (error: any) {
      console.log("Something went wrong while fetching profile categories");
      console.log(error);
    }
  };

  const fetchSuspensionReasons = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/suspension-reason`;
      const response = await backAxios.get(url);
      const suspensionReasons = response.data as SuspensionReason[];
      setSuspensionReasons(suspensionReasons);
    } catch (error: any) {
      console.log("Something went wrong while fetching suspension reasons");
      console.log(error);
    }
  };

  const fetchProfile = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(`${BASE_URL}/${profileId}`);
        const profile = response.data as CompleteProfile;
        setProfile(profile);
        setSelectedCategory(profile.profileCategory);
        void updateDashboardStateProfile(profile.id);
        return profile;
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching profile");
      console.log(error);
    }
  };

  const fetchProfilesLastSubscription = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(
          `${BASE_URL}/${profileId}/subscriptions/last-subscription`
        );
        const subscription = response.data as Subscription;
        setLastSubscription(subscription);
        return subscription;
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching last subscription");
      console.log(error);
    }
  };

  const fetchSuspensions = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(
          `${BASE_URL}/${profileId}/suspension`
        );
        const suspensions = response.data as Suspension[];
        setSuspensions(suspensions);
        return suspensions;
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching suspensions");
      console.log(error);
    }
  };

  const fetchReports = async () => {
    try {
      if (profileId) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/${profileId}/reports`;
      const reports = await backAxios.get(url);
      const data = reports.data as Report[];
      setReports(data);
      if(data.length > 0) setTabIndex(7);
    }
    } catch (error: any) {
      console.log("Something went wrong while fetching reports");
      console.log(error);
    }
  };

  const fetchBlogPosts = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(
          `${BASE_URL}/${profileId}/blog-posts`
        );
        const posts = response.data as BlogPost[];
        setBlogPosts(posts);
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching blog posts");
      console.log(error);
    }
  };

  const fetchComments = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(
          `${BASE_URL}/${profileId}/comments`
        );
        const data = response.data as Comment[];
        setComments(data);
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching comments");
      console.log(error);
    }
  };


  // Sending data to server
  const handleUnsuspendConfirm = () => {
    async function unsuspendProfile(url: string) {
      const patchObject = { isSuspended: false };

      await backAxios.patch(url, patchObject);
    }

    if (profile?.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profile.id}`;

      unsuspendProfile(url)
        .then(() => {
          setSuccessfulOperation(true);
          setSuccessfulOperationText("User successfully unsuspended!");
          setUnsuspendDialogOpen(false);
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to unsuspend the user!");
        });
    }
  };

  const handleDeleteCustomHobbyConfirm = () => {
    async function deleteCustomHobby(url: string) {
      await backAxios.delete(url);
    }

    if (profile?.id && customHobby && customHobby.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profile.id}/custom-hobbies/${customHobby.id}`;

      deleteCustomHobby(url)
        .then(() => {
          setCustomHobby(null);
          setDeleteCustomHobbyDialogOpen(false);
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Custom hobby successfully deleted!");
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to delete the custom hobby!");
        });
    }
  };

  const handleDeleteSwipesConfirm = () => {
    async function deleteSwipes(url: string) {
      await backAxios.delete(url);
    }

    if (profile?.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profile.id}/swipes`;

      deleteSwipes(url)
        .then(() => {
          setDeleteSwipesDialogOpen(false);
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Swipes successfully deleted!");
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to delete the swipes!");
        });
    }
  };

  const handleDeleteProfileMedia = async (index: number, media: Media) => {
    async function deleteProfileMedia(url: string, index: number) {
      const response = await backAxios.delete(url);
      const profile = await fetchProfile();
      if (
        profile?.profileMedia === undefined ||
        profile?.profileMedia.length === 0
      ) {
        closeCarousel();
      } else {
        setCarouselModalSelectedItem(
          Math.min(index, profile?.profileMedia.length - 1)
        );
      }
    }
    if (profileId && media) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profileId}/profile-media/${media.id}`;

      await deleteProfileMedia(url, index);
    }
  };

  const handleApproveProfileMedia = async (media: Media) => {
    if (profileId && media) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profileId}/profile-media/${media.id}`;
      await backAxios.patch(url);
      await fetchProfile();
    }
  };

  const handleConfirmEditCategory = () => {
    if (profileId) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profileId}`;

      backAxios
        .patch(url, { profileCategory: selectedCategory })
        .then(() => {
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Successfully changed the category of the profile");
          setIsEditCategoryDialogOpen(false);
          setSelectedCategory(null);
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch(() => {
          setIsEditCategoryDialogOpen(false);
          setSelectedCategory(null);
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to set the category of the profile");
        });
    }
  };

  const handleSwipesPriorityChange = (priority: number) => {
    async function updatePriority(priority: number, url: string) {
      const patchObject = { swipesPriority: priority };
      await backAxios.patch(url, patchObject);
    }

    if (profile?.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profile.id}`;

      updatePriority(priority, url)
        .then(() => {
          if (priority === MIN_PRIORITY) {
            setSuccessfulOperation(true);
            setSuccessfulOperationText(" Successfully set to NOT be prioritized in swipes!");
          } else {
            setSuccessfulOperation(true);
            setSuccessfulOperationText("Successfully set to be prioritized in swipes!");
          }
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to set the prioritization in swipes!");
        });
    }
  };

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);

    async function deleteProfile(profileId: string, url: string) {
      try {
        await backAxios.delete(url);
        removeDashboardStateProfile(profileId);
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Profile successfully deleted!");
        setTimeout(() => navigate("/"), 2000);
      } catch (error) {
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Did not manage to delete the profile!");
      }
    }

    if (profileId) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profileId}`;

      deleteProfile(profileId, url).catch(() =>
        console.log("Something went wrong while deleting")
      );
    }
  };

  // Actions visibility setup
  const prioritizeButtonVisibility = () => {
    const allowedRoles = [
      AdminRole.SUPER_ADMIN,
      AdminRole.DEV_ADMIN,
      AdminRole.ADMIN,
      AdminRole.MODERATOR,
    ];
    return allowedRoles.includes(userRole) ? "visible" : "hidden";
  };

  const suspendButtonVisibility = () => {
    const allowedRoles = [
      AdminRole.SUPER_ADMIN,
      AdminRole.DEV_ADMIN,
      AdminRole.ADMIN,
      AdminRole.MODERATOR,
    ];
    return allowedRoles.includes(userRole) && !profile?.user.isSuspended
      ? "visible"
      : "hidden";
  };

  const unsuspendButtonVisibility = () => {
    const allowedRoles = [
      AdminRole.SUPER_ADMIN,
      AdminRole.DEV_ADMIN,
      AdminRole.ADMIN,
      AdminRole.MODERATOR,
    ];
    return allowedRoles.includes(userRole) && profile?.user.isSuspended
      ? "visible"
      : "hidden";
  };

  const deleteButtonVisibility = () => {
    const allowedRoles = [
      AdminRole.SUPER_ADMIN,
      AdminRole.DEV_ADMIN,
      AdminRole.ADMIN,
      AdminRole.MODERATOR,
    ];
    return allowedRoles.includes(userRole) ? "visible" : "hidden";
  };

  const deleteCustomHobbyButtonVisibility = () => {
    const allowedRoles = [
      AdminRole.SUPER_ADMIN,
      AdminRole.DEV_ADMIN,
      AdminRole.ADMIN,
      AdminRole.MODERATOR,
    ];
    return allowedRoles.includes(userRole) ? "visible" : "hidden";
  };

  const deleteSwipesButtonVisibility = () => {
    const allowedRoles = [AdminRole.DEV_ADMIN];
    return allowedRoles.includes(userRole) ? "visible" : "hidden";
  };

  // Handling user interactions
  const handleDelete = () => {
    if (
      userRole?.toLocaleLowerCase() !== AdminRole.VIEWER.toLocaleLowerCase()
    ) {
      setDeleteDialogOpen(true);
    }
  };

  const handleSuspend = () => {
    if (
      userRole?.toLocaleLowerCase() !== AdminRole.VIEWER.toLocaleLowerCase()
    ) {
      setSuspendDialogOpen(true);
    }
  };

  const handleUnsuspend = () => {
    if (
      userRole?.toLocaleLowerCase() !== AdminRole.VIEWER.toLocaleLowerCase()
    ) {
      setUnsuspendDialogOpen(true);
    }
  };

  const handleDeleteCustomHobby = (customHobby: CustomHobby) => {
    if (
      userRole?.toLocaleLowerCase() !== AdminRole.VIEWER.toLocaleLowerCase()
    ) {
      setCustomHobby(customHobby);
      setDeleteCustomHobbyDialogOpen(true);
    }
  };

  const handleDeleteSwipes = () => {
    if (
      userRole?.toLocaleLowerCase() !== AdminRole.VIEWER.toLocaleLowerCase()
    ) {
      setDeleteSwipesDialogOpen(true);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const subscribeProfile = (profileId: string | null) => {
    let url;
    if (profileId) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/${profileId}/subscriptions/subscribe`;

      backAxios
        .post(url)
        .then(() => {
          void fetchProfilesLastSubscription();
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        })
        .catch((err: any) => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to subscribe the profile");
        });
    }
  };

  const handleReportNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setReportNote((event.target as HTMLInputElement).value);
  };

  const handleEditClick = (report: Report) => {
    setReport(report);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleAcceptClick = (report: Report | null) => {
    setReportNote("");
    setReport(report);
    setOpenAlertAccept(true);
  };

  const handleDismissClick = (report: Report | null) => {
    setReportNote("");
    setReport(report);
    setOpenAlertDismiss(true);
  };

  const handleCancelAccept = () => {
    setOpenAlertAccept(false);
  };

  const handleConfirmAccept = (report: Report | null) => {
    acceptReport(report);
    setOpenAlertAccept(false);
    setOpen(false);
  };

  const handleCancelDismiss = () => {
    setOpenAlertDismiss(false);
  };

  const handleConfirmDismiss = (report: Report | null) => {
    dismissReport(report);
    setOpenAlertDismiss(false);
    setOpen(false);
  };

  const acceptReport = (report: Report | null) => {
    let url;
    if (report && report.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/reports/${report.id}/accept`;

      backAxios
        .post(url, { note: reportNote, suspensionDuration: suspensionDuration, suspensionReasonId: suspensionReasonId})
        .then(() => {
          void fetchReports();
        })
        .catch((err: any) => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to accept profile report");
        });
    }
  };

  const dismissReport = (report: Report | null) => {
    let url;
    if (report && report.id) {
      url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/reports/${report.id}/dismiss`;

      backAxios
        .post(url, { note: reportNote})
        .then(() => {
          void fetchReports();
        })
        .catch((err: any) => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Did not manage to dismiss profile report");
        });
    }
  };

  const handleOpenSubscribe = () => {
    setSubscribeDialogOpen(true);
  };

  const handleCancelSubscribe = () => {
    setSubscribeDialogOpen(false);
  };

  const handleConfirmSubscribe = (profileId: string | null) => {
    subscribeProfile(profileId);
    setSubscribeDialogOpen(false);
  };

  const handleTabIndexChange = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ) => {
    setTabIndex(newTabIndex);
  };

  const openCarousel = (selectedItem: number) => {
    if (selectedItem >= 0) {
      setToggleCarouselModal(true);
      setCarouselModalSelectedItem(selectedItem);
    }
  };

  const closeCarousel = () => {
    setToggleCarouselModal(false);
    setCarouselModalSelectedItem(-1);
  };

  const handleCategoryChange = (category: ProfileCategory) => {
    setSelectedCategory(category);
  };

  const handleEditCategoryDialogClose = () => {
    setIsEditCategoryDialogOpen(false);
    setSelectedCategory(null);
  };

  const handleEditCategoryDialogOpen = () => {
    setIsEditCategoryDialogOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          // height: "calc(100vh - 20px)",
          p: "10px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Item sx={{ height: "100%", width: "100%" }} elevation={3}>
              <Box sx={{ padding: "15px" }}>
                {/* Profile header */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "flex-start", sm: "center" },
                      gap: "25px",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: { xs: "20px", md: "40px" } }}
                    >
                      <Avatar
                        src={profile?.profileMedia?.[0]?.presignedUrlThumbnail}
                        sx={{
                          height: { xs: 110, md: 140 },
                          width: { xs: 110, md: 140 },
                          cursor:
                            profile?.profileMedia?.[0] !== undefined
                              ? "pointer"
                              : "default",
                          border:
                            profile?.profileMedia?.[0] &&
                            !profile.profileMedia[0].isApproved
                              ? 2
                              : 0,
                          borderColor:
                            profile?.profileMedia?.[0] &&
                            !profile.profileMedia[0].isApproved
                              ? "error.main"
                              : "none",
                        }}
                        variant="rounded"
                        onClick={() => {
                          openCarousel(profile?.profileMedia?.[0]?.order);
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="left"
                          sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" } }}
                        >
                          {profile?.firstName} {profile?.lastName}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <PersonIcon fontSize="small" color="primary" />
                            <Typography variant="subtitle2">
                              {profile?.username}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      flexItem
                      orientation={
                        useMediaQuery(theme.breakpoints.down("sm"))
                          ? "horizontal"
                          : "vertical"
                      }
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { xs: "10px", sm: "20px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <CategoryIcon fontSize="medium" color="primary" />
                        <Typography
                          variant="subtitle1"
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Category:&nbsp;
                          {profile?.profileCategory?.name}
                        </Typography>
                        {userRole === AdminRole.VIEWER ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <IconButton
                              sx={{ marginLeft: "8px" }}
                              aria-label="edit"
                              size="small"
                              color="success"
                              onClick={handleEditCategoryDialogOpen}
                            >
                              <EditIcon fontSize="medium" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: { xs: "20px", sm: "40px" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <LocationOnIcon fontSize="small" color="primary" />
                            <Typography variant="subtitle2" align="left">
                              {profile?.hometown || "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <CakeIcon fontSize="small" color="primary" />
                            <Typography variant="subtitle2" align="left">
                              {getDateOnlyAsString(profile?.birthday)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <WorkIcon fontSize="small" color="primary" />
                            <Typography
                              variant="subtitle2"
                              align="left"
                              sx={{
                                wordBreak: "break-word",
                                width: { xs: "27vw", sm: "100%" },
                              }}
                            >
                              {profile?.jobTitle || "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <OnDeviceTrainingTwoToneIcon fontSize="small" color="primary" />
                            <Typography
                              variant="subtitle2"
                              align="left"
                              sx={{
                                wordBreak: "break-word",
                                width: { xs: "27vw", sm: "100%" },
                              }}
                            >
                              {profile?.deviceOS || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <MailOutlineIcon fontSize="small" color="primary" />
                            <Tooltip title={profile?.user?.email}>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                sx={{
                                  width: {
                                    xs: "100px",
                                    sm: "80px",
                                    md: "100%",
                                  },
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {profile?.user?.email || "N/A"}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <LocalPhoneIcon fontSize="small" color="primary" />
                            <Typography variant="subtitle2" align="left">
                              {profile?.user.phoneNumber || "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <FormatQuoteIcon fontSize="small" color="primary" />
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontStyle: "italic",
                                wordBreak: "break-word",
                                width: { xs: "27vw", sm: "100%" },
                              }}
                              align="left"
                            >
                              {profile?.quote || "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <AppSettingsAltIcon fontSize="small" color="primary" />
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontStyle: "italic",
                                wordBreak: "break-word",
                                width: { xs: "27vw", sm: "100%" },
                              }}
                              align="left"
                            >
                              {profile?.appVersion || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {profile ? (
                    <Box>
                      {
                        <Tooltip
                          title={
                            profile.swipesPriority === MIN_PRIORITY
                              ? "Prioritize"
                              : "Deprioritize"
                          }
                        >
                          <IconButton
                            variant="contained"
                            color="success"
                            sx={{
                              border: "1px solid #2E7D32",
                              borderRadius: "4px",
                              height: { xs: "34px", sm: "38px" },
                              width: { xs: "34px", sm: "38px" },
                              margin: "4px",
                              marginRight: "16px",
                              visibility: prioritizeButtonVisibility(),
                            }}
                            onClick={() => {
                              const newPriorityValue =
                                profile.swipesPriority === MIN_PRIORITY
                                  ? MAX_PRIORITY
                                  : MIN_PRIORITY;
                              handleSwipesPriorityChange(newPriorityValue);
                            }}
                          >
                            {profile.swipesPriority === MIN_PRIORITY ? (
                              <KeyboardDoubleArrowUpIcon
                                sx={{
                                  fontSize: { xs: "18px", sm: "20px" },
                                }}
                              />
                            ) : (
                              <KeyboardDoubleArrowDownIcon
                                sx={{
                                  fontSize: { xs: "18px", sm: "20px" },
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      }
                      {!profile.user.isSuspended ? (
                        <Tooltip title="Suspend">
                          <IconButton
                            color="warning"
                            sx={{
                              border: "1px solid #ed6c02",
                              borderRadius: "4px",
                              height: { xs: "34px", sm: "38px" },
                              width: { xs: "34px", sm: "38px" },
                              margin: "4px",
                              visibility: suspendButtonVisibility(),
                            }}
                            onClick={handleSuspend}
                          >
                            <TimerOutlinedIcon
                              sx={{
                                fontSize: { xs: "18px", sm: "20px" },
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unsuspend">
                          <IconButton
                            color="success"
                            sx={{
                              border: "1px solid #2e7d32",
                              borderRadius: "4px",
                              height: { xs: "34px", sm: "38px" },
                              width: { xs: "34px", sm: "38px" },
                              margin: "4px",
                              visibility: unsuspendButtonVisibility(),
                            }}
                            onClick={handleUnsuspend}
                          >
                            <TimerOffOutlinedIcon
                              sx={{
                                fontSize: { xs: "18px", sm: "20px" },
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete User">
                        <IconButton
                          color="error"
                          sx={{
                            border: "1px solid red",
                            borderRadius: "4px",
                            height: { xs: "34px", sm: "38px" },
                            width: { xs: "34px", sm: "38px" },
                            visibility: deleteButtonVisibility(),
                          }}
                          onClick={handleDelete}
                        >
                          <DeleteIcon
                            sx={{
                              fontSize: { xs: "18px", sm: "20px" },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Divider sx={{ my: "25px" }} />

                <Grid container spacing={4}>
                  {/* Photos column */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography sx={{ mb: "20px" }} variant="h5">
                        Other Photos
                      </Typography>
                      <ImageList sx={{ width: "100%", margin: 0 }} cols={3}>
                        {getNImages(profile, 9).map((item, index) => (
                          <ImageListItem
                            key={index}
                            sx={{
                              aspectRatio: "1/1",
                              border: !item.isApproved ? 2 : 0,
                              borderColor: !item.isApproved
                                ? "error.main"
                                : "none",
                            }}
                            onClick={() => {
                              openCarousel(
                                profile?.profileMedia?.[index + 1]?.order
                              );
                            }}
                          >
                            <img
                              height={140}
                              width={140}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                                cursor:
                                  profile?.profileMedia?.[index + 1] !==
                                  undefined
                                    ? "pointer"
                                    : "default",
                              }}
                              src={`${item.path}`}
                              alt={`image missing`}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                  </Grid>
                  {/* Account info column */}
                  <Grid item xs={12} sm={6} md={2}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography sx={{ mb: "14px" }} variant="h5">
                        Account info
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Is prioritized:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.swipesPriority > 0 ? "True" : "False"}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Created at:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {getDateOnlyAsString(profile?.user.createdDate)}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Is complete:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.isComplete
                            ? "True"
                            : `False, last page: ${
                                profile?.lastOnBoardingPage || "N/A"
                              }`}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Is active:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.user.isActive ? "True" : "False"}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Is suspended:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.user.isSuspended ? "True" : "False"}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Suspended until:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.user.isPermanentlySuspended
                            ? "Permanently"
                            : getDateOnlyAsString(profile?.user.suspendedUntil)}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Last active:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {profile?.lastActive
                            ? format(new Date(profile.lastActive), "yyyy-MM-dd")
                            : "N/A"}
                        </span>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Number of connections:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.connectionCount || "N/A"}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Number of swipes:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.swipesCount || "N/A"}
                        </span>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteSwipes()}
                          sx={{
                            padding: 0,
                            paddingLeft: "8px",
                            visibility: deleteSwipesButtonVisibility(),
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Country:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.countryName || "N/A"}
                        </span>
                      </Typography>

                      <Typography sx={{ mb: "14px", mt: "14px" }} variant="h5">
                        Subscription info
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Is subscribed:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.isSubscribed ? "True" : "False"}
                        </span>
                        { profile?.isSubscribed ?
                        (<></>) : (
                        <>
                            <IconButton
                              sx={{ marginLeft: "8px" }}
                              aria-label="edit"
                              size="small"
                              color="success"
                              onClick={handleOpenSubscribe}
                            >
                              <MonetizationOnIcon />
                            </IconButton>
                          </>)}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Subscribed until:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {lastSubscription?.endDate
                            ? getDateAndTimeAsString(lastSubscription.endDate)
                            : "N/A"}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Subscription type interval:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {lastSubscription?.subscriptionTypeInterval}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Subscription type:{""}
                        <span style={{ fontWeight: 500 }}>
                          {lastSubscription?.subscriptionTypeName}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Auto renewal:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {lastSubscription
                            ? lastSubscription.isAutoRenew
                              ? "True"
                              : "False"
                            : ""}
                        </span>
                      </Typography>

                      <Typography sx={{ mb: "14px", mt: "14px" }} variant="h5">
                        Real encounter info
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Number of real encounters:{" "}
                        <span style={{ fontWeight: 500 }}>
                          {profile?.numberOfRealEncounters}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Last real encounter:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {profile?.lastRealEncounterDate
                            ? getDateOnlyAsString(profile.lastRealEncounterDate)
                            : "N/A"}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          lineHeight: 1.35,
                        }}
                      >
                        Last location update:{" "}
                        <span style={{ fontWeight: 400 }}>
                          {profile?.lastLocationUpdateDate
                            ? getDateOnlyAsString(
                                profile.lastLocationUpdateDate
                              )
                            : "N/A"}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Additional info column */}
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography sx={{ mb: "2px" }} variant="h5">
                        Additional info
                      </Typography>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                        }}
                      >
                        <Tabs
                          value={tabIndex}
                          onChange={handleTabIndexChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Work" id="simple-tab-1" />
                          <Tab label="Education" id="simple-tab-2" />
                          <Tab label="Custom Hobbies" id="simple-tab-3" />
                          <Tab label="Blog" id="simple-tab-4" />
                          <Tab label="Comments" id="simple-tab-5" />
                          <Tab label="Suspensions" id="simple-tab-6" />
                          <Tab label="Logs" id="simple-tab-7" />
                          <Tab label="Reports" id="simple-tab-8" />
                        </Tabs>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <TabPanel value={tabIndex} index={0}>
                          <Carousel
                            showIndicators={false}
                            autoPlay={true}
                            interval={2500}
                            transitionTime={500}
                            infiniteLoop={true}
                            showThumbs={false}
                          >
                            {profile?.works?.map((work, index) => (
                              <Box
                                key={index}
                                style={{ textAlign: "justify", height: "100%" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  IsMain:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {work.isMain ? "True" : "False"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Company:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {work.company || "N/A"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Position:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {work.position || "N/A"}
                                  </span>
                                </Typography>
                                {work.startYear && work.startMonth ? (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Started:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {`${work.startYear}-${work.startMonth}`}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Started:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {"N/A"}
                                    </span>
                                  </Typography>
                                )}
                                {work.endYear && work.endMonth ? (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Ended:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {`${work.endYear}-${work.endMonth}`}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Ended:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {"N/A"}
                                    </span>
                                  </Typography>
                                )}
                              </Box>
                            ))}
                          </Carousel>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                          <Carousel
                            showIndicators={false}
                            autoPlay={true}
                            interval={2500}
                            transitionTime={500}
                            infiniteLoop={true}
                            showArrows={true}
                            showThumbs={false}
                          >
                            {profile?.educations?.map((education, index) => (
                              <Box
                                key={index}
                                style={{ textAlign: "justify", height: "100%" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  IsCourse:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {education.isCourse ? "True" : "False"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Degree:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {education.degree || "N/A"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  School:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {education.school || "N/A"}
                                  </span>
                                </Typography>
                                {education.startYear && education.startMonth ? (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Started:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {`${education.startYear}-${education.startMonth}`}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Started:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {"-"}
                                    </span>
                                  </Typography>
                                )}
                                {education.endYear && education.endMonth ? (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Ended:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {`${education.endYear}-${education.endMonth}`}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                  >
                                    Ended:{" "}
                                    <span style={{ fontWeight: 500 }}>
                                      {"N/A"}
                                    </span>
                                  </Typography>
                                )}
                              </Box>
                            ))}
                          </Carousel>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                          <Box>
                            {profile?.customHobbies ? (
                              <List dense>
                                {profile?.customHobbies?.map(
                                  (customHobby, index) => (
                                    <ListItem
                                      key={index}
                                      secondaryAction={
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={() =>
                                            handleDeleteCustomHobby(customHobby)
                                          }
                                          sx={{
                                            visibility:
                                              deleteCustomHobbyButtonVisibility(),
                                          }}
                                        >
                                          <RemoveCircleOutlineIcon />
                                        </IconButton>
                                      }
                                    >
                                      <ListItemText>
                                        {customHobby.name}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0.5,
                            }}
                          >
                            {blogPosts?.map((post) => (
                              <Box key={post.id}>
                                <BlogPostCard blogPost={post} />
                              </Box>
                            ))}
                          </Box>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0.5,
                            }}
                          >
                            {comments?.map((comment) => (
                              <Box key={comment.id}>
                                <CommentCard comment={comment} />
                              </Box>
                            ))}
                          </Box>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={5}>
                          <Carousel
                            showIndicators={false}
                            autoPlay={true}
                            interval={2500}
                            transitionTime={500}
                            infiniteLoop={true}
                            showArrows={true}
                            showThumbs={false}
                          >
                            {suspensions?.map((suspension, index) => (
                              <Box
                                key={index}
                                style={{ textAlign: "justify", height: "100%" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Suspension reason:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {suspension.suspensionReason || "N/A"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Start date:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {getDateOnlyAsString(suspension.startDate) || "N/A"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  End date:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {getDateOnlyAsString(suspension.endDate) || "N/A"}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Note:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {suspension.note || " "}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "0.9rem", fontWeight: 600 }}
                                >
                                  Is permanently suspended:{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {suspension.isPermanentlySuspended ? "True" : "False"}
                                  </span>
                                </Typography>
                              </Box>
                            ))}
                          </Carousel>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={6}>
                          <div style={{display: "flex"}}>
                            <ul style={{listStyle: "none"}}
                            >
                              {profile == null ? 
                                <></> : 
                                <>{profile?.profileLogs?.sort((a, b) => Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)).map((log: ProfileLog) => (
                                  <li><a href={log.presignedUrl} download={true}>{log.uploadedDate}</a></li>
                                ))}
                                </> 
                              }
                            </ul>
                          </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={7}>
                          <TableContainer
                            component={Paper}
                            // sx={{
                            //   overflowY: "auto",
                            //   maxHeight: "100vh",
                            //   marginBottom: "20px",
                            // }}
                          >
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <b>Type</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Reported by</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Reason</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Date</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Description</b>
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {reports.map((report, index) => (
                                  <TableRow
                                    key={report.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": { border: 0 },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <TableCell>{report.reportType}</TableCell>
                                    <TableCell>{report.reportedByUsername}</TableCell>
                                    <TableCell>{report.reportedReason}</TableCell>
                                    <TableCell>{getDateAndTimeAsString(report.reportedDate)}</TableCell>
                                    <TableCell>{report.description}</TableCell>
                                    <TableCell align="right" sx={{ minWidth: "20%" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          color="info"
                                          onClick={() => handleEditClick(report)}
                                        >
                                          View
                                        </Button>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        sx={{ minHeight: "400px" }}
        open={isEditCategoryDialogOpen}
        onClose={handleEditCategoryDialogClose}
      >
        <DialogTitle>Edit profile category</DialogTitle>
        <DialogContent sx={{ height: "160px" }}>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            Choose profile category from the list below
          </DialogContentText>
          <Autocomplete
            sx={{ flexGrow: 2 }}
            disablePortal
            id="category-chooser"
            options={profileCategories}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} placeholder="Category" required />
            )}
            getOptionLabel={(c) => c.name}
            onChange={(e, value) => handleCategoryChange(value)}
            value={selectedCategory}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditCategoryDialogClose}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={handleConfirmEditCategory}
            disabled={selectedCategory ? false : true}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        title="Are you sure?"
        text="Are you sure that you want to unsuspend the user?"
        confirmText="Confirm"
        confirmColor="success"
        cancelText="Cancel"
        open={isUnsuspendDialogOpen}
        handleCancel={() => setUnsuspendDialogOpen(false)}
        handleConfirm={handleUnsuspendConfirm}
      />

      <AlertDialog
        title="Are you sure?"
        text="Deletion of profiles is irreversible. Are you sure that this profile should be deleted?"
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteDialogOpen}
        handleCancel={handleDeleteCancel}
        handleConfirm={handleDeleteConfirm}
      />

      <AlertDialog
        title="Are you sure?"
        text={`Deletion of custom hobbies is irreversible. Are you sure that custom hobby ${
          customHobby ? '"' + customHobby.name + '"' : ""
        } should be deleted?`}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteCustomHobbyDialogOpen}
        handleCancel={() => setDeleteCustomHobbyDialogOpen(false)}
        handleConfirm={handleDeleteCustomHobbyConfirm}
      />

      <AlertDialog
        title="Are you sure?"
        text={`Deletion of swipes is irreversible. Are you sure you want to delete them?`}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteSwipesDialogOpen}
        handleCancel={() => setDeleteSwipesDialogOpen(false)}
        handleConfirm={handleDeleteSwipesConfirm}
      />

      <Snackbar
        open={successfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfulOperation(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {successfulOperationText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={unsuccessfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfulOperation(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {unsuccessfulOperationText}
        </Alert>
      </Snackbar>

      <SuspensionDialog
        isSuspendDialogOpen={isSuspendDialogOpen}
        onCancel={() => {
          setSuspendDialogOpen(false);
        }}
        onSuspend={() => {
          setSuspendDialogOpen(false);
          setTimeout(() => void fetchProfile(), UPDATE_DEFFERAL_MILLIS);
        }}
        profile={profile}
        userRole={userRole}
      ></SuspensionDialog>

      <CarouselModal
        toggle={toggleCarouselModal}
        onClose={closeCarousel}
        media={profile?.profileMedia}
        initialSelection={carouselModalSelectedItem}
        onDelete={
          userRole === AdminRole.VIEWER ? undefined : handleDeleteProfileMedia
        }
        onApprove={
          userRole === AdminRole.VIEWER ? undefined : handleApproveProfileMedia
        }
      ></CarouselModal>

      <Dialog
        sx={{ minHeight: "100px", minWidth: '400px' }}
        open={open}
        onClose={handleDialogClose}
      >
        
        <DialogTitle>Description: {report?.description}</DialogTitle>
        <DialogContent sx={{ minHeight: "80px" }}>
          {getDialogContent(report)}        
        </DialogContent>
        <DialogActions>
          <Button size="small" color="primary" variant="contained" onClick={() => handleAcceptClick(report)}>
            Accept
          </Button>
          <Button size="small" color="error" variant="contained" onClick={() => handleDismissClick(report)}>
            Dismiss
          </Button>
          <Button size="small" color="info" variant="contained" onClick={handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlertAccept} onClose={handleCancelAccept}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to accept this report?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleReportNoteChange}
            value={reportNote}
          />
          {report?.reportType != 'PROFILE' ? (
                          <></>
                        ) : (<>
          <DialogContentText sx={{ marginBottom: "5px" }}>
            For how long should the user be suspended (starting from today)?
          </DialogContentText>
          <FormControl fullWidth>
            {/* <InputLabel id="suspend-select-label" size="small">
              Days
            </InputLabel> */}
            <Select
              // labelId="suspend-select-label"
              id="suspend-select"
              value={suspensionDuration}
              // label="Days"
              size="small"
              onChange={(event) => setSuspensionDuration(event.target.value as SuspensionDuration)}
            >
              <MenuItem value={SuspensionDuration.ONE_DAY}>24h</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_DAYS}>48h</MenuItem>
              <MenuItem value={SuspensionDuration.WEEK}>One Week</MenuItem>
              <MenuItem value={SuspensionDuration.TWO_WEEKS}>
                Two Weeks
              </MenuItem>
              <MenuItem value={SuspensionDuration.MONTH}>A Month</MenuItem>
              <MenuItem value={SuspensionDuration.PERMANENT}>
                Permanently
              </MenuItem>
            </Select>
          </FormControl>
          <DialogContentText sx={{ marginBottom: "5px" }}>
            Reason user is being suspended for:
          </DialogContentText>
          <FormControl fullWidth>
            {/* <InputLabel id="suspend-select-label" size="small">
              Days
            </InputLabel> */}
            <Select
              // labelId="suspend-select-label"
              id="suspend-reason-select"
              value={suspensionReasonId}
              // label="Days"
              size="small"
              onChange={(event) => setSuspensionReasonId(event.target.value as unknown as number)}
            >
              {suspensionReasons.map((sr) => (
                  <MenuItem
                  value={sr.id}>{sr.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          </>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAccept}>Cancel</Button>
          <Button onClick={() => handleConfirmAccept(report)}>Accept</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlertDismiss} onClose={handleCancelDismiss}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to dismiss this report?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleReportNoteChange}
            value={reportNote}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDismiss}>Cancel</Button>
          <Button onClick={() => handleConfirmDismiss(report)}>Dismiss</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSubscribeDialogOpen} onClose={handleCancelSubscribe}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to indefinitely subscribe this profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSubscribe}>Cancel</Button>
          <Button onClick={() => handleConfirmSubscribe(profile.id)}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileView;
