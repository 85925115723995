import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import qrcode from "qrcode";
import backAxios from "../axios/back.axios";
import { parseJwt } from "../helpers/jwt-helper";
import Admin from "../data/model/admin";
import * as OTPAuth from "otpauth";

const TwoFactorAuthentication = () => {

  const token = localStorage.getItem("access_token");
  const parsedToken = parseJwt(token!); // Existance of token is checked in App.tsx 

  const [admin, setAdmin] = useState<Admin>(new Admin());
  const [enabled, setEnabled] = useState<boolean>(false);
  const [secret, setSecret] = useState("");
  const [QRImage, setQRImage] = useState<string>("");
  const [successfullySetUp2FA, setSuccessfullySetUp2FA] = useState(false);

  useEffect(() => {
    void fetchLoggedAdmin();
  }, []);

  const fetchLoggedAdmin = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      try {
        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/admins/${id}`;
        const response = await backAxios.get(url);
        const data = response.data as Admin;
        setAdmin(data);
        setEnabled(data.enabled2FA);
      } catch (error: any) {
        console.log("Something went wrong while fetching logged admin info!");
        console.log(error);
      }
    } else {
      throw new Error("Unauthorized access!");
    }
  };

  const updateAdminSecret = async () => {

    if(!secret){
      return;
    }

    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      admin.enabled2FA = true;
      admin.secret = secret;
      try {
        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/admins/${id}`;

        await backAxios.patch(url, admin);

        setSuccessfullySetUp2FA(true);

      } catch (error: any) {
        console.log("Something went wrong while updating secret!");
        console.log(error);
      }
    }
  };

  const remove2FAAdmin = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const id = payload.sub;
      admin.enabled2FA = false;
      admin.secret = "";
      try {
        const url = `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/admins/${id}`;

        await backAxios.patch(url, admin);
      } catch (error: any) {
        console.log("Something went wrong while removing 2fa from admin!");
        console.log(error);
      }
    }
  };

  const handle2FAChange = (changedEnabled: boolean) => {
    setEnabled(changedEnabled);
    if (changedEnabled === true) {
      void showQR();
    } else {
      void remove2FAAdmin();
    }
  };

  const showQR = () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const payload = parseJwt(access_token);
      const email = payload.email;
      const secret = new OTPAuth.Secret();
      const totp = new OTPAuth.TOTP({
        issuer: `Con.tact - ${process.env.REACT_APP_ENV as string}`,
        label: email,
        algorithm: "SHA1",
        digits: 6,
        period: 30,
        secret: secret.base32,
      });

      const otpauth = totp.toString();
      setSecret(otpauth);

      qrcode.toDataURL(otpauth, (err, imageUrl) => {
        if (err) {
          console.log("Error with QR");
          return;
        }
        setQRImage(imageUrl);
      });
    }
  };

  const showElement = () => {
    //show nothing
    if (enabled === false) {
      return -1;
    }
    //show button
    else if (enabled === true && QRImage === "") {
      return 0;
    }
    //show QR
    else if (enabled === true && QRImage.length > 0) {
      return 1;
    }
    return -1;
  };

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Box
          component={Paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "450px",
            padding: "20px",
            borderRadius: "8px",
            margin: "0 auto",
          }}
        >
          <Box sx={{ marginBottom: "5px" }}>
            <Typography variant="h6">
              <b>Two-Factor Authentication</b>
            </Typography>
            <Typography variant="body1">
            To set up Two Factor Authention, you need to do the following:
            <ol>
              <li>Download the Google Authenticator application</li>
              <li>Click the {parsedToken.is2FAMandatory ? `'Generate' button` : `toggle`} to generate QR code</li>
              <li>Scan the generated QR code with Google Authenticator application</li>
              <li>Confirm that you have set up 2FA by clicking the 'Confirm' button </li>
            </ol> 

            Once you finish this setup, you will need to use the code provided by
            Google Authenticator each time you log in. 
            
            {
              parsedToken.is2FAMandatory ? 
              `` : 
              `You can opt out of
              the two-factor authentication by disabling it.`
            }
            </Typography>
            {
              parsedToken.is2FAMandatory ? 
              <></> : 
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Disabled</Typography>
                <Switch
                  onChange={(event) => handle2FAChange(event.target.checked)}
                  checked={enabled}
                />
                <Typography>Enabled</Typography>
              </Stack>
            }
            
          </Box>
          <Box>
            {(() => {
              switch (showElement()) {
                case 0:
                  return (
                    <Button
                      type="button"
                      variant="outlined"
                      sx={{
                        mt: 1, // margin top
                      }}
                      onClick={showQR}
                    >
                      Generate QR
                    </Button>
                  );
                case 1:
                  return (
                    QRImage ? 
                    <Box>
                      <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <img src={QRImage} />
                      </Box>
                      <Button
                        type="button"
                        variant="outlined"
                        sx={{
                          mt: 1, // margin top
                          marginLeft: "auto",
                          marginRight: "5px"
                        }}
                        onClick={() => void updateAdminSecret()}
                      >
                      Confirm
                      </Button>
                    </Box> :
                    <></>
                  );
                default:
                  return (
                    <div className="msg-empty">
                      When enabled, a QR code will be present.
                    </div>
                  );
              }
            })()}
          </Box>
        </Box>
      </Grid>

      <Snackbar
        open={successfullySetUp2FA}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullySetUp2FA(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullySetUp2FA(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          2FA setup successful!
        </Alert>
      </Snackbar>
    </>
  );
};
export default TwoFactorAuthentication;
