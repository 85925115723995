import {
  Alert,
  Box,
  Button,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import backAxios from "../axios/back.axios";
import Report from "../data/model/report";

interface ReportViewProps {
  toggle: boolean;
  reports: Report[];
  onReportsChanged: () => void;
  onClose: () => void;
}

const ReportView = ({
  toggle,
  onClose,
  reports,
  onReportsChanged,
}: ReportViewProps) => {
  const [successfullyResolved, setSuccessfullyResolved] = useState(false);
  const [unsuccessfullyResolved, setUnsuccessfullyResolved] = useState(false);
  const acceptReport = (reportId: string) => {
    backAxios
      .delete(
        `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/reports/${reportId}`
      )
      .then(() => {
        setSuccessfullyResolved(true);
        onReportsChanged();
      })
      .catch(() => {
        console.log("Something went wrong while resolving report");
        setUnsuccessfullyResolved(true);
      });
  };

  const [successfullyDismissed, setSuccessfullyDismissed] = useState(false);
  const [unsuccessfullyDismissed, setUnsuccessfullyDismissed] = useState(false);
  const dismissReport = (reportId: string) => {
    backAxios
      .delete(
        `${
          process.env.REACT_APP_SERVER_URL as string
        }/api/moderation/reports/${reportId})`
      )
      .then(() => {
        onReportsChanged();
        setSuccessfullyDismissed(true);
      })
      .catch(() => {
        console.log("Something went wrong while resolving report");
        setUnsuccessfullyDismissed(true);
      });
  };

  const contentRef = useRef(null);
  useOnClickOutside(contentRef, () => onClose());

  return (
    <>
      <Modal
        open={toggle}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <TableContainer
            ref={contentRef}
            component={Paper}
            sx={{ width: "80%", maxHeight: "80%" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Report reason</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((report, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{report.reportedReason}</TableCell>
                    <TableCell>{report.description}</TableCell>
                    <TableCell align="right" sx={{ minWidth: "20%" }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => acceptReport(report.id)}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => dismissReport(report.id)}
                      >
                        Dismiss
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Snackbar
        open={successfullyResolved}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyResolved(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyResolved(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Successfully resolved report!
        </Alert>
      </Snackbar>
      <Snackbar
        open={unsuccessfullyResolved}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfullyResolved(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfullyResolved(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Did not manage to resolve report!
        </Alert>
      </Snackbar>
      <Snackbar
        open={successfullyDismissed}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyDismissed(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyDismissed(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Successfully dismissed report!
        </Alert>
      </Snackbar>
      <Snackbar
        open={unsuccessfullyDismissed}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfullyDismissed(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfullyDismissed(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Did not manage to dismiss report!
        </Alert>
      </Snackbar>
    </>
  );
};
export default ReportView;
