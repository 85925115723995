import CompleteProfile from "../data/model/complete-profile";

export const getNImages = (
    profile: CompleteProfile | null,
    totalImages: number
  ): { path: string; isApproved: boolean }[] => {
    const placeholderPath = "https://via.placeholder.com/240x346?text=%20";

    const images: { path: string; isApproved: boolean }[] = [];

    if (profile) {
        for (const pm of profile.profileMedia.slice(1)) {
        images.push({
            path: pm.presignedUrlThumbnail,
            isApproved: pm.isApproved,
        });

        if (images.length === totalImages) break;
        }
    }

    const imagesToAdd = totalImages - images.length;
    for (let i = 0; i < imagesToAdd; i++) {
        images.push({ path: placeholderPath, isApproved: true });
    }

    return images;
};