import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import DefaultAppBar from "../components/DefaultAppBar";
import { DashboardState } from "./dashboard.model";

const ProfilesManagement = () => {
  const [dashboardState, setDashboardState] = React.useState<
    DashboardState | undefined
  >(undefined);

  return (
    <>
      <DefaultAppBar />
      <Box sx={{ width: "100%" }}>
        <Outlet context={[dashboardState, setDashboardState]} />
      </Box>
    </>
  );
};
export default ProfilesManagement;
