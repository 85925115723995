import { Dialog, DialogTitle, DialogContent, Box, TextField, DialogActions, Button } from "@mui/material";

interface Coordinates {
    lng?: string; lat?: string;
}

interface REClearAreaDialogProps {
    clearAreaDialogOpen: boolean;
    setClearAreaDialogOpen(param: boolean): void;
    clearAreaCoords?: Coordinates;
    setClearAreaCoords(coordinates: Coordinates): void;
    clearArea(): Promise<void>;
}

const REClearAreaDialog = (props: REClearAreaDialogProps) => {

    return (
        <Dialog
          open={props.clearAreaDialogOpen}
          onClose={() => {
            props.setClearAreaDialogOpen(false);
          }}
        >
          <DialogTitle>What are the coordinates of the area?</DialogTitle>
          <DialogContent>
            <Box>
              <div style={{ width: "100%" }}>
                <TextField
                  label="Longitude"
                  variant="standard"
                  size="small"
                  style={{ width: "100%" }}
                  type="number"
                  value={props.clearAreaCoords?.lng}
                  required
                  inputProps={{
                    step: "0.1"
                  }}
                  onChange={(e) =>
                    props.setClearAreaCoords({
                      lat: props.clearAreaCoords?.lat,
                      lng: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Latitude"
                  variant="standard"
                  size="small"
                  style={{ width: "100%" }}
                  type="number"
                  value={props.clearAreaCoords?.lat}
                  required
                  inputProps={{
                    step: "0.1"
                  }}
                  onChange={(e) =>
                    props.setClearAreaCoords({
                      lng: props.clearAreaCoords?.lng,
                      lat:  e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setClearAreaDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                void props.clearArea();
              }}
              disabled={
                props.clearAreaCoords?.lat == undefined ||
                new Boolean(Number.parseFloat(props.clearAreaCoords?.lat)).valueOf() == false ||
                props.clearAreaCoords?.lng == undefined ||
                new Boolean(Number.parseFloat(props.clearAreaCoords?.lng)).valueOf() == false }
            >
              Clear Area
            </Button>
          </DialogActions>
        </Dialog>
    );
}

export default REClearAreaDialog;