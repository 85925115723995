import { Outlet, Navigate, useLocation } from "react-router-dom";
import { AdminRole } from "../data/model/enumerations";
import { parseJwt } from "../helpers/jwt-helper";

const PrivateRoute = (props: { allowedRoles?: AdminRole[] }) => {
  const token = localStorage.getItem("access_token");
  const userRole = localStorage.getItem("role") as AdminRole;
  const location = useLocation();
  localStorage.setItem("intendedRoute", location.pathname)

  const hasToken = Boolean(token);

  if(!hasToken){
    return <Navigate to="/login" />;
  }

  const parsedToken = parseJwt(token!);

  const isPartiallyAuthenticated = () => {
    return !parsedToken.fullyAuthenticated;
  };

  const shouldEnforce2FA = () => {
    if (parsedToken.fullyAuthenticated && parsedToken.is2FAMandatory && !parsedToken.authenticatedWith2FA) {
        return true;
    } 
    return false;
}

  const hasAppropriateRole = () => {
    if (!(props.allowedRoles && props.allowedRoles.length > 0)) {
      return true;
    }

    const appropriateRole = props.allowedRoles.find((ar) => ar === userRole);
    if (appropriateRole) {
      return true;
    }

    return false;
  };

  const navigateToRoute = () => {
    if (isPartiallyAuthenticated()) {
      return "two-factor-auth";
    } 
    else if (shouldEnforce2FA()) {
      return "enforce-two-factor-auth";
    }
    else if (hasAppropriateRole()) {
      return "child";
    } 
    else {
      return "dashboard";
    }
  };

  // If user does not have an appropriate role
  // Navigate to dashboard
  return (() => {
    switch (navigateToRoute()) {
      case "child":
        return <Outlet />;
      case "dashboard":
        return <Navigate to="/" />;
      case "two-factor-auth":
        return <Navigate to="/two-factor-auth" />;
      case "enforce-two-factor-auth":
        return <Navigate to="/enforced-2FA-setup" />;
    }
  })();
};

export default PrivateRoute;
