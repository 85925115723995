import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import backAxios from "../axios/back.axios";
import AlertDialog from "../components/AlertDialog";
import { AdminRole } from "../data/model/enumerations";

interface Language {
  id: number;
  code: string;
}

interface CustomHobby {
  id: number;
  name: string;
  language: Language;
}

interface HobbyGroup {
  id: number;
  name: string;
}

interface Hobby {
  englishTranslation: string;
  germanTranslation: string;
  spanishTranslation: string;
  hobbyGroupId: number;
  hobbyGroupName: string;
}

const CustomHobbies = () => {
  const userRole = localStorage.getItem("role") as AdminRole;

  const [englishTranslation, setEnglishTranslation] = useState("");
  const [germanTranslation, setGermanTranslation] = useState("");
  const [spanishTranslation, setSpanishTranslation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedCustomHobby, setSelectedCustomHobby] =
    useState<CustomHobby | null>(null);
  const [customHobbies, setCustomHobbies] = useState<CustomHobby[]>([]);

  const [selectedHobbyGroup, setSelectedHobbyGroup] =
    useState<HobbyGroup | null>(null);
  const [hobbyGroups, setHobbyGroups] = useState<HobbyGroup[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDisregardDialogOpen, setIsDisregardDialogOpen] = useState(false);


  const [successfulOperation, setSuccessfulOperation] = useState(false);
  const [successfulOperationText, setSuccessfulOperationText] = useState("");
  const [unsuccessfulOperation, setUnsuccessfulOperation] = useState(false);
  const [unsuccessfulOperationText, setUnsuccessfulOperationText] = useState("");

  const fetchCustomHobbies = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/custom`;
      const response = await backAxios.get(url);
      const data = response.data as CustomHobby[];
      setCustomHobbies(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching custom hobbies");
    }
  };

  const fetchHobbyGroups = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/groups`;
      const response = await backAxios.get(url);
      const data = response.data as HobbyGroup[];
      setHobbyGroups(data);
    } catch (error: any) {
      console.log("Something went wrong while fetching hobby groups");
    }
  };

  useEffect(() => {
    void fetchCustomHobbies();
    void fetchHobbyGroups();
  }, []);

  const handleUseCustomHobby = (ch: CustomHobby) => {
    if (ch.language.code.toLocaleLowerCase() === "en") {
      setEnglishTranslation(ch.name);
      setGermanTranslation("");
      setSpanishTranslation("");
    }
    if (ch.language.code.toLocaleLowerCase() === "de") {
      setGermanTranslation(ch.name);
      setEnglishTranslation("");
      setSpanishTranslation("");
    }
    if (ch.language.code.toLocaleLowerCase() === "es") {
      setSpanishTranslation(ch.name);
      setGermanTranslation("");
      setEnglishTranslation("");
    }

  };  
  
  const handleDisregardCustomHobby = (ch: CustomHobby) => {
    setSelectedCustomHobby(ch);
    setIsDisregardDialogOpen(true);
  };

  const handleDisregardConfirmation = () => {
    async function disregardCustomHobby(url: string) {
      await backAxios.post(url);
    }

    if (selectedCustomHobby && selectedCustomHobby.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/custom/${selectedCustomHobby.id}/disregard`;

      disregardCustomHobby(url)
        .then(() => {
          setSelectedCustomHobby(null);
          setIsDisregardDialogOpen(false);
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Custom hobby successfully disregarded!");
          setTimeout(() => void fetchCustomHobbies(), 200);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Something went wrong while disregarding the custom hobby!");
        });
    }
  };

  const handleDisregardCancelation = () => {
    setSelectedCustomHobby(null);
    setIsDisregardDialogOpen(false);
  };

  const handleDeleteCustomHobby = (ch: CustomHobby) => {
    setSelectedCustomHobby(ch);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = () => {
    async function deleteCustomHobby(url: string) {
      await backAxios.delete(url);
    }

    if (selectedCustomHobby && selectedCustomHobby.id) {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/hobbies/custom/${selectedCustomHobby.id}`;

      deleteCustomHobby(url)
        .then(() => {
          setSelectedCustomHobby(null);
          setIsDeleteDialogOpen(false);
          setSuccessfulOperation(true);
          setSuccessfulOperationText("Custom hobby successfully deleted!");
          setTimeout(() => void fetchCustomHobbies(), 200);
        })
        .catch(() => {
          setUnsuccessfulOperation(true);
          setUnsuccessfulOperationText("Something went wrong while deleting the custom hobby!");
        });
    }
  };

  const handleDeleteCancelation = () => {
    setSelectedCustomHobby(null);
    setIsDeleteDialogOpen(false);
  };

  const handleEnglishTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnglishTranslation(event.target.value);
  };

  const handleGermanTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGermanTranslation(event.target.value);
  };

  const handleSpanishTranslationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSpanishTranslation(event.target.value);
  };

  const onSubmitCreate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    async function createNewHobby(url: string, data: Hobby) {
      await backAxios.post(url, data);
    }

    const hobby: Hobby = {
      englishTranslation: englishTranslation,
      germanTranslation: germanTranslation,
      spanishTranslation: spanishTranslation,
      hobbyGroupId: selectedHobbyGroup?.id || 0,
      hobbyGroupName: "",
    };

    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/hobbies`;

    createNewHobby(url, hobby)
      .then(() => {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Hobby successfully created!");
        setEnglishTranslation("");
        setGermanTranslation("");
        setSpanishTranslation("");
        setSelectedHobbyGroup(null);
        setTimeout(() => void fetchCustomHobbies(), 200);
      })
      .catch((err: any) => {
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Something went wrong while creating the hobby!");
        //eslint-disable-next-line
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  const [newGroupName, setNewGroupName] = useState("");
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false);

  const handleCreateGroupCancelation = () => {
    setIsCreationDialogOpen(false);
    setNewGroupName("");
  };

  const handleNewGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewGroupName(event.target.value);
  };

  const handleCreateGroupConfirmation = () => {
    async function createHobbyGroup(url: string, data: any) {
      await backAxios.post(url, data);
    }

    const url = `${
      process.env.REACT_APP_SERVER_URL as string
    }/api/moderation/hobbies/groups`;

    createHobbyGroup(url, { name: newGroupName })
      .then(() => {
        setNewGroupName("");
        setIsCreationDialogOpen(false);
        setSuccessfulOperation(true);
        setSuccessfulOperationText("New hobby group successfully created!");
        setTimeout(() => void fetchHobbyGroups(), 200);
      })
      .catch(() => {
        setUnsuccessfulOperation(true);
        setUnsuccessfulOperationText("Something went wrong while creating new hobby group!");
      });
  };

  return (
    <>
      <Grid container mt={12} sx={{ marginTop: "24px" }}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 650,
                overflowY: "auto",
                maxHeight: "100vh",
                marginBottom: "20px",
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Custom hobbies</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customHobbies.map((ch) => (
                    <TableRow
                      key={ch.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {ch.name}
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "end",
                          }}
                        >
                          {userRole === AdminRole.VIEWER ? (
                            <></>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                onClick={() => handleUseCustomHobby(ch)}
                              >
                                Use
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                color="info"
                                onClick={() => handleDisregardCustomHobby(ch)}
                              >
                                Disregard
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={() => handleDeleteCustomHobby(ch)}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          {userRole === AdminRole.VIEWER ? (
            <></>
          ) : (
            <Box
              component={Paper}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "350px",
                padding: "20px",
                borderRadius: "8px",
                margin: "0 auto",
              }}
            >
              <Box sx={{ marginBottom: "5px" }}>
                <Typography variant="h6">
                  <b>Create a new hobby</b>
                </Typography>
                <Typography variant="body1">
                  Fill this form to create a new hobby
                </Typography>
              </Box>
              <form onSubmit={onSubmitCreate}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  {errorMessage && (
                    <Box
                      sx={{
                        backgroundColor: "#d32f2f",
                        color: "white",
                        borderRadius: "4px",
                        lineHeight: "1.4375em",
                        padding: "12px 14px",
                      }}
                    >
                      {errorMessage}
                    </Box>
                  )}
                  <Box>
                    <TextField
                      variant="standard"
                      label="English translation"
                      sx={{ width: "100%" }}
                      autoComplete="english-translation"
                      name="t2"
                      type="text"
                      placeholder="Enter english translation"
                      required
                      value={englishTranslation}
                      onChange={handleEnglishTranslationChange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="German translation"
                      sx={{ width: "100%" }}
                      autoComplete="german-translation"
                      name="t2"
                      type="text"
                      placeholder="Enter german translation"
                      required
                      value={germanTranslation}
                      onChange={handleGermanTranslationChange}
                    />
                  </Box>
                  <Box>
                    <TextField
                      variant="standard"
                      label="Spanish translation"
                      sx={{ width: "100%" }}
                      autoComplete="spanish-translation"
                      name="t2"
                      type="text"
                      placeholder="Enter spanish translation"
                      required
                      value={spanishTranslation}
                      onChange={handleSpanishTranslationChange}
                    />
                  </Box>

                  <Box>
                    <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
                      <Autocomplete
                        sx={{ flexGrow: 2 }}
                        disablePortal
                        id="combo-box-demo"
                        options={hobbyGroups}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            label="Hobby group"
                            {...params}
                            required
                          />
                        )}
                        getOptionLabel={(hg) => hg.name}
                        onChange={(e, value) => setSelectedHobbyGroup(value)}
                        value={selectedHobbyGroup}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => setIsCreationDialogOpen(true)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Button
                    type="submit"
                    disabled={
                      !englishTranslation ||
                      !germanTranslation ||
                      !selectedHobbyGroup
                    }
                    variant="contained"
                    disableElevation
                    sx={{
                      marginTop: 2,
                      width: "100%",
                    }}
                  >
                    Create
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={isCreationDialogOpen}
        onClose={handleCreateGroupCancelation}
      >
        <DialogTitle>Create a new hobby group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            Fill out this form to create a new hobby group
          </DialogContentText>
          <TextField
            sx={{ width: "100%" }}
            autoComplete="group-name"
            name="t2"
            type="text"
            placeholder="Enter hobby group name"
            required
            onChange={handleNewGroupNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCreateGroupCancelation}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={handleCreateGroupConfirmation}
            disabled={newGroupName ? false : true}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        title="Are you sure?"
        text={`Deletion of custom hobbies is irreversible. Are you sure that custom hobby ${
          selectedCustomHobby ? selectedCustomHobby.name : ""
        } should be deleted?`}
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteDialogOpen}
        handleCancel={handleDeleteCancelation}
        handleConfirm={handleDeleteConfirmation}
      />

      <AlertDialog
        title="Are you sure?"
        text={`Are you sure that custom hobby ${
          selectedCustomHobby ? selectedCustomHobby.name : ""
        } should be disregarded? You will not be able to see it in moderation panel anymore.`}
        confirmText="Confirm"
        confirmColor="warning"
        cancelText="Cancel"
        open={isDisregardDialogOpen}
        handleCancel={handleDisregardCancelation}
        handleConfirm={handleDisregardConfirmation}
      />

      <Snackbar
        open={successfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfulOperation(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {successfulOperationText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={unsuccessfulOperation}
        autoHideDuration={5000}
        onClose={() => {
          setUnsuccessfulOperation(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setUnsuccessfulOperation(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          {unsuccessfulOperationText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomHobbies;
