import { FirebaseApp, initializeApp } from "firebase/app";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";

const ENVIRONMENT = process.env.REACT_APP_ENV as string;

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};
const config = ENVIRONMENT === "production" ? {
  apiKey: "AIzaSyCgjLwM06WAwPDrxlRJt4NseyGKIxZxzSM",
  authDomain: "con-tact-prod.firebaseapp.com",
  projectId: "con-tact-prod",
  storageBucket: "con-tact-prod.appspot.com",
  messagingSenderId: "749615276525",
  appId: "1:749615276525:web:f04524834112de781a47e4",
  measurementId: "G-V8JVL0MLH4"
}: {
  apiKey: "AIzaSyBP6IAfdRRHpb7pm1C0Gj99qPAuij_Q0ig",
  authDomain: "cont-tact.firebaseapp.com",
  projectId: "cont-tact",
  storageBucket: "cont-tact.appspot.com",
  messagingSenderId: "613047620286",
  appId: "1:613047620286:web:a5faa7474584d1230204ef",
  measurementId: "G-5BRW921SGP",
}
const firebaseConfig: FirebaseConfig = config;
const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LeojKMpAAAAAIUxwkFrt11BDOnyeZcvgFv_W-CG'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
export const app = firebaseApp
