import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosResponse } from "axios";
import React, { useRef, useState } from "react";
import backAxios from "../axios/back.axios";
import AlertSnackbar from "../components/AlertSnackbar";
import DefaultAppBar from "../components/DefaultAppBar";
import Map, { AvailableColors } from "../components/Map/Map";
import { MAXLAT, MAXLON, MINLAT, MINLON } from "../data/constants";
import RealEncounterHistory from "../data/model/real-encounter-history";
import SimpleProfile from "../data/model/simple-profile";
import ProfileBox from "./ProfileBox";
import REClearAreaDialog from "./REClearAreaDialog";
import REInvocationDialog from "./REInvocationDialog";

const RealEncounters = () => {
  const ENVIRONMENT = process.env.REACT_APP_ENV as string;

  const BASE_URL = `${
    process.env.REACT_APP_SERVER_URL as string
  }/api/moderation/real-encounters`;

  const [username1, setUsername1] = useState("");
  const [username2, setUsername2] = useState("");

  const [profile1, setProfile1] = useState<SimpleProfile>();
  const [profile2, setProfile2] = useState<SimpleProfile>();

  const [invocationDialogOpen, setInvocationDialogOpen] = useState(false);
  const [invocationProfile1Coords, setInvocationProfile1Coords] = useState<{
    lng: number;
    lat: number;
  }>();
  const [invocationProfile2Coords, setInvocationProfile2Coords] = useState<{
    lng: number;
    lat: number;
  }>();

  const [clearAreaDialogOpen, setClearAreaDialogOpen] = useState(false);
  const [clearAreaCoords, setClearAreaCoords] = useState<{
    lng?: string;
    lat?: string;
  }>({lat:"0", lng: "0"});

  const [pins, setPins] = useState<
    {
      lng: number;
      lat: number;
      radius: number;
      text: string;
      color: AvailableColors;
    }[]
  >([]);

  const [rehs, setRehs] = useState<RealEncounterHistory[]>([]);
  const [rehsLastFetched, setRehsLastFetched] = useState<Date>();
  const rehsFetchCount = useRef(0);
  const rehsTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const rehsFetchRefreshPeriod = 3000; // every three seconds
  const rehsFetchStartCount = 4; // for 12 seconds

  const [successfulOperation, setSuccessfulOperation] = useState(false);
  const [successfulOperationText, setSuccessfulOperationText] = useState("");
  const [unsuccessfulOperation, setUnsuccessfulOperation] = useState(false);
  const [unsuccessfulOperationRext, setUnsuccessfulOperationText] = useState("");

  const onChangeUsername1 = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername1(e.target.value.toLowerCase()); // Usernames should be case insensitive, but just in case
  const onChangeUsername2 = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername2(e.target.value.toLowerCase()); // Usernames should be case insensitive, but just in case

  const retrieveFirstProfile = async (showSuccessAlert = true) => {
    const profile = await tryRetrieveProfileByUsername(username1);

    if (profile) {
      setProfile1(profile);
      updatePins(profile, "blue", profile2, "purple");
      if (showSuccessAlert) {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Successfully retrieved user data");
      }
    } else {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to retrieve user data");
    }

    void tryRetrieveRealEncounterHistoryForProfiles(profile, profile2);
  };

  const retrieveSecondProfile = async (showSuccessAlert = true) => {
    const profile = await tryRetrieveProfileByUsername(username2);

    if (profile) {
      setProfile2(profile);
      updatePins(profile, "purple", profile1, "blue");
      if (showSuccessAlert) {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Successfully retrieved user data");
      }
    } else {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to retrieve user data");
    }

    void tryRetrieveRealEncounterHistoryForProfiles(profile1, profile);
  };

  // Cannot just call two separate retrievals!
  // Pins state updates would be at risk of async interleaving
  const retrieveBothProfiles = async (showSuccessAlert = true) => {
    const profile1 = await tryRetrieveProfileByUsername(username1);
    setProfile1(profile1);

    const profile2 = await tryRetrieveProfileByUsername(username2);
    setProfile2(profile2);

    if (profile1 && profile2) {
      updatePins(profile1, "blue", profile2, "purple");
      if (showSuccessAlert) {
        setSuccessfulOperation(true);
        setSuccessfulOperationText("Successfully retrieved user data");
      }
    } else {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to retrieve user data");
    }

    void tryRetrieveRealEncounterHistoryForProfiles(profile1, profile2, true);
  };

  const tryRetrieveProfileByUsername = async (username: string) => {
    let res: AxiosResponse;
    try {
      res = await backAxios.get(`${BASE_URL}/profiles`, {
        params: { username: username },
      });
    } catch (err) {
      return;
    }

    return res.data as SimpleProfile;
  };

  const tryRetrieveRealEncounterHistoryForProfiles = async (
    profile1?: SimpleProfile,
    profile2?: SimpleProfile,
    repeatedly = false
  ) => {
    let res: AxiosResponse;
    try {
      res = await backAxios.get(`${BASE_URL}/history`, {
        params: { firstProfileId: profile1?.id, secondProfileId: profile2?.id },
      });
    } catch (err) {
      return;
    }

    const rehs = res.data as RealEncounterHistory[];
    setRehs(rehs);
    setRehsLastFetched(new Date());

    if (repeatedly && rehsTimerRef.current) {
      clearInterval(rehsTimerRef.current);
      rehsTimerRef.current = null;
    }
    if (repeatedly && !rehsTimerRef.current) {
      rehsFetchCount.current = rehsFetchStartCount;
      rehsTimerRef.current = setInterval(() => {
        if (rehsFetchCount.current <= 0 && rehsTimerRef.current) {
          clearInterval(rehsTimerRef.current);
          rehsTimerRef.current = null;
        } else {
          rehsFetchCount.current = rehsFetchCount.current - 1;
          void tryRetrieveRealEncounterHistoryForProfiles(profile1, profile2);
        }
      }, rehsFetchRefreshPeriod);
    }
  };

  const updatePins = (
    newProfile: SimpleProfile,
    newProfileProfileWantedColor: AvailableColors,
    otherProfile?: SimpleProfile,
    otherProfileProfileWantedColor?: AvailableColors
  ) => {
    if (
      newProfile.longitude != undefined &&
      newProfile.longitude != null &&
      newProfile.latitude != undefined &&
      newProfile.latitude != null
    ) {
      const newPins = [createPin(newProfile, newProfileProfileWantedColor)];

      if (
        otherProfile &&
        otherProfile.longitude != undefined &&
        otherProfile.longitude != null &&
        otherProfile.latitude != undefined &&
        otherProfile.latitude != null &&
        otherProfileProfileWantedColor
      ) {
        newPins.push(createPin(otherProfile, otherProfileProfileWantedColor));
      }
      setPins(newPins);
    }
  };

  const createPin = (
    profile: SimpleProfile,
    profileProfileWantedColor: string
  ) => {
    const pin = {
      lng: profile.reLongitude ?? profile.longitude,
      lat: profile.reLatitude ?? profile.latitude,
      radius: profile.reRadius,
      text: profile.reDeviceToken
        ? profile.username
        : `(old data) ${profile.username}`,
      color: (profile.reDeviceToken
        ? profileProfileWantedColor
        : "gray") as AvailableColors,
    };
    return pin;
  };

  const invokeRealEncounter = async () => {
    try {
      const res1 = await backAxios.get(`${BASE_URL}/profiles`, {
        params: { username: username1 },
      });
      const profile1 = res1.data as SimpleProfile;

      const res2 = await backAxios.get(`${BASE_URL}/profiles`, {
        params: { username: username2 },
      });
      const profile2 = res2.data as SimpleProfile;

      await backAxios.patch(`${BASE_URL}/profiles/${profile1.id}`, {
        longitude: Number.parseFloat(
          invocationProfile1Coords?.lng?.toString() || "0"
        ),
        latitude: Number.parseFloat(
          invocationProfile1Coords?.lat?.toString() || "0"
        ),
        deviceId: profile1.reDeviceToken || profile1.deviceToken,
      });
      await backAxios.patch(`${BASE_URL}/profiles/${profile2.id}`, {
        longitude: Number.parseFloat(
          invocationProfile2Coords?.lng?.toString() || "0"
        ),
        latitude: Number.parseFloat(
          invocationProfile2Coords?.lat?.toString() || "0"
        ),
        deviceId: profile2.reDeviceToken || profile2.deviceToken,
      });

      await retrieveBothProfiles();
      setInvocationDialogOpen(false);
      
      setSuccessfulOperation(true);
      setSuccessfulOperationText("Successfully invoked real encounter");
    } catch (err) {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to invoke real encounter");
    }
  };

  const deleteREDataForProfile = async (profileId: string) => {
    try {
      await backAxios.delete(`${BASE_URL}/profiles/${profileId}`);
      if (profile1?.id === profileId) {
        void retrieveFirstProfile();
      } else {
        void retrieveSecondProfile();
      }

      setSuccessfulOperation(true);
      setSuccessfulOperationText("Successfully cleared real encounter data");
    } catch (err) {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to cleared real encounter data");
    }
  };

  const deleteREDataForBothProfiles = async () => {
    try {
      if (profile1 && profile2) {
        await backAxios.delete(`${BASE_URL}/profiles/${profile1.id}`);
        await backAxios.delete(`${BASE_URL}/profiles/${profile2.id}`);

        await retrieveBothProfiles();

        setSuccessfulOperation(true);
        setSuccessfulOperationText("Successfully cleared real encounter data");
      }
    } catch (err) {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to cleared real encounter data");
    }
  };

  const clearArea = async () => {
    try {
      const longitude = Number.parseFloat(
        clearAreaCoords?.lng?.toString() || ""
      );
      const latitude = Number.parseFloat(
        clearAreaCoords?.lat?.toString() || ""
      );

      if (Number.isNaN(longitude) || Number.isNaN(latitude)) {
        alert("Both longitude and latitude must be numbers");
        return;
      }

      if (longitude < MINLON || longitude > MAXLON) {
        alert(`Longitude should be between ${MINLON} and ${MAXLON}`);
        return;
      }

      if (latitude < MINLAT || latitude > MAXLAT) {
        alert(`Latitude should be between ${MINLAT} and ${MAXLAT}`);
        return;
      }

      await backAxios.post(`${BASE_URL}/area-clearance`, {
        longitude,
        latitude,
      });
      if (profile1 && profile2) {
        void retrieveBothProfiles(false);
      } else if (profile1) {
        void retrieveFirstProfile(false);
      } else if (profile2) {
        void retrieveSecondProfile(false);
      }

      setClearAreaDialogOpen(false);
      setSuccessfulOperation(true);
      setSuccessfulOperationText("Successfully cleared area");
    } catch (err) {
      setUnsuccessfulOperation(true);
      setUnsuccessfulOperationText("Did not manage to clear area");
    }
  };

  return (
    <>
      <DefaultAppBar />
      <Box style={{ minHeight: "100vh", padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <ProfileBox
              profile={profile1}
              inputLabel={"First Username"}
              onChangeUsername={onChangeUsername1}
              retrieveProfile={retrieveFirstProfile}
              clearHistory={
                ENVIRONMENT === "development"
                  ? deleteREDataForProfile
                  : undefined
              }
            ></ProfileBox>
            <ProfileBox
              profile={profile2}
              inputLabel={"Second Username"}
              onChangeUsername={onChangeUsername2}
              retrieveProfile={retrieveSecondProfile}
              clearHistory={
                ENVIRONMENT === "development"
                  ? deleteREDataForProfile
                  : undefined
              }
            ></ProfileBox>
          </Grid>
          <Grid style={{ minHeight: "80vh" }} item xs={12} sm={9}>
            <Map pins={pins} />
            <Box mt="10px" display={"flex"} justifyContent={"end"} gap="10px">
              {ENVIRONMENT === "development" || ENVIRONMENT === "local" ? (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      setClearAreaDialogOpen(true);
                    }}
                  >
                    Clear Area
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    disabled={!(profile1 && profile2)}
                    onClick={() => {
                      void deleteREDataForBothProfiles();
                    }}
                  >
                    Clear History (Both)
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    disabled={!(profile1 && profile2)}
                    onClick={() => {
                      setInvocationProfile1Coords({
                        lng: profile1?.longitude || 0,
                        lat: profile1?.latitude || 0,
                      });
                      setInvocationProfile2Coords({
                        lng: profile2?.longitude || 0,
                        lat: profile2?.latitude || 0,
                      });
                      setInvocationDialogOpen(true);
                    }}
                  >
                    Invoke
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid mt={"10px"} container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant="h6">Real Encounter History</Typography>
            <Typography
              sx={{
                paddingLeft: "5px",
                paddingBottom: "5px",
                color: "rgba(0, 0, 0, 0.6);",
              }}
              variant="subtitle2"
            >
              Last Fetched: {rehsLastFetched?.toLocaleTimeString()}
            </Typography>
            {rehs.length > 0 ? (
              <TableContainer component={Paper}>
                <Table aria-label="real encounters history table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Encounter Time</TableCell>
                      <TableCell>First Profile Username</TableCell>
                      <TableCell>Second Profile Username</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rehs.map((reh, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {new Date(reh.encounterTime).toLocaleString()}
                        </TableCell>
                        <TableCell>{reh.primaryProfile.username}</TableCell>
                        <TableCell>{reh.secondaryProfile.username}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Typography
                  sx={{
                    paddingLeft: "5px",
                    paddingBottom: "5px",
                  }}
                  variant="subtitle2"
                >
                  No History Available
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      (
      <>
        <REInvocationDialog 
          invocationDialogOpen={invocationDialogOpen}
          setInvocationDialogOpen={setInvocationDialogOpen}
          profile1Coordinates={invocationProfile1Coords}
          setProfile1Coordinates={setInvocationProfile1Coords}
          profile2Coordinates={invocationProfile2Coords}
          setProfile2Coordinates={setInvocationProfile2Coords}
          invokeRealEncounter={invokeRealEncounter}
          />
      </>
      ) (
      <>
        <REClearAreaDialog 
          clearAreaDialogOpen={clearAreaDialogOpen}
          setClearAreaDialogOpen={setClearAreaDialogOpen}
          clearAreaCoords={clearAreaCoords}
          setClearAreaCoords={setClearAreaCoords}
          clearArea={clearArea}
          />
      </>
      )
      <>
        <AlertSnackbar
          open={successfulOperation}
          text={successfulOperationText}
          severity="success"
          duration={5000}
          onClose={() => setSuccessfulOperation(true)}
        ></AlertSnackbar>
        <AlertSnackbar
          open={unsuccessfulOperation}
          text={unsuccessfulOperationRext}
          severity="error"
          duration={5000}
          onClose={() => setUnsuccessfulOperation(false)}
        ></AlertSnackbar>
      </>
    </>
  );
};
export default RealEncounters;
