import { parseJwt } from "../helpers/jwt-helper";
import { Outlet, Navigate } from "react-router-dom";

const Enforced2FASetupRoute = () => {
    const token = localStorage.getItem("access_token");
    const hasToken = Boolean(token);

    if(!hasToken){
        return  <Navigate to="/login" />;
    }

    const parsedToken = parseJwt(token!);

    const isPartiallyAuthenticated = () => {
        return !parsedToken.fullyAuthenticated;
    }

    const shouldEnforce2FA = () => {

        if (parsedToken.fullyAuthenticated && parsedToken.is2FAMandatory && !parsedToken.authenticatedWith2FA) {
            return true;
        } 
        return false;
    }


    return  isPartiallyAuthenticated() ? (
            <Navigate to="/two-factor-auth"/>
        ) : (
            shouldEnforce2FA() ? <Outlet /> : <Navigate to="/" />
        );
};

export default Enforced2FASetupRoute;