import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import { CssVarsProvider } from "@mui/joy/styles";
import TextField from "@mui/joy/TextField";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backAxios from "../axios/back.axios";
import { AuthenticationDto } from "../data/dto/authentication.dto";
import { Credentials } from "../data/model/credentials";
import { parseJwt } from "../helpers/jwt-helper";

const Login = () => {
  const [credentials, setCredentials] = useState<Credentials>(
    new Credentials("", "")
  );
  const navigateTo = useNavigate();

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCredentials = Object.assign({}, credentials);
    newCredentials.email = event.target.value;
    setCredentials(newCredentials);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCredentials = Object.assign({}, credentials);
    newCredentials.password = event.target.value;
    setCredentials(newCredentials);
  };

  const onSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void doLogin();
  };

  const doLogin = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL as string}/auth/login`;
    try {
      const retVal = await backAxios.post(url, credentials);
      const accessToken = (retVal.data as AuthenticationDto).access_token;
      const payload = parseJwt(accessToken);

      localStorage.setItem(
        "access_token",
        (retVal.data as AuthenticationDto).access_token
      );
      if (payload.fullyAuthenticated) {
        localStorage.setItem("role", payload.role);
        const intendedRoute = localStorage.getItem('intendedRoute');

        if(payload.is2FAMandatory){
          navigateTo("/enforced-2FA-setup");
        }
        else{
          if(intendedRoute){
            navigateTo(intendedRoute);
          }
          else{
            navigateTo("/");
          }
        }
      } else {
        navigateTo("/two-factor-auth");
      }
    } catch (error) {
      alert("Was not able to log in.");
    }
  };

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          maxWidth: 400,
          mx: "auto", // margin left & right
          my: 7, // margin top & botom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <div>
          <Typography level="h4" component="h1">
            <b>Welcome!</b>
          </Typography>
          <Typography level="body2">Sign in to continue</Typography>
        </div>
        <form onSubmit={onSubmitLogin}>
          <TextField
            autoComplete="username"
            name="email"
            type="email"
            placeholder="your@email.com"
            label="Email"
            onChange={onChangeEmail}
          />
          <TextField
            autoComplete="current-password"
            name="password"
            type="password"
            placeholder="password"
            label="Password"
            onChange={onChangePassword}
          />
          <Button
            type="submit"
            sx={{
              mt: 1, // margin top
            }}
          >
            Log in
          </Button>
        </form>
      </Sheet>
    </CssVarsProvider>
  );
};

export default Login;
