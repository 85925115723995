import { Backdrop, Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import OpenPlayerJS from "openplayerjs";
import { useCallback, useState, forwardRef, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Media from "../data/model/media";
import { useClickAnyWhere } from "usehooks-ts";
import AlertDialog from "./AlertDialog";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CarouselModal = (props: {
  toggle: boolean;
  onClose: () => void;
  onDelete?: (index: number, media: Media) => Promise<void>;
  onApprove?: (media: Media) => Promise<void>;
  media: Media[];
  initialSelection: number;
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mediaForDeletion, setMediaForDeletion] = useState<{
    media: Media;
    index: number;
  } | null>(null);
  const [successfullyDeleted, setSuccessfullyDeleted] = useState(false);
  const [deletionUnsuccessful, setDeletionUnsuccessful] = useState(false);

  const [isApproveDialogOpen, setApproveDialogOpen] = useState(false);
  const [mediaForApproval, setMediaForApproval] = useState<Media | null>(null);
  const [successfullyApproved, setSuccessfullyApproved] = useState(false);
  const [approvalUnsuccessful, setApprovalUnsuccessful] = useState(false);

  const rootNodeRef = useRef<HTMLDivElement | null>(null);
  const saveRootNode = useCallback((node: HTMLDivElement) => {
    if (node) {
      rootNodeRef.current = node;
    } else {
      rootNodeRef.current = null;
    }
  }, []);

  let player: OpenPlayerJS;
  const initPlayer = async (videoId: string) => {
    player = new OpenPlayerJS(videoId);
    await player.init();
  };

  const loadVideo = useCallback((node: HTMLVideoElement) => {
    if (node) {
      initPlayer(node.id).catch((error) => console.log(error));
    } else {
      for (const [key, value] of Object.entries(OpenPlayerJS.instances)) {
        value.destroy();
      }
    }
  }, []);

  useClickAnyWhere((event: MouseEvent) => {
    const eventElement = event.target as HTMLElement;

    if (
      props.toggle === true &&
      rootNodeRef.current &&
      rootNodeRef.current.contains(eventElement)
    ) {
      if (!isDeleteDialogOpen) {
        if (
          eventElement.tagName === "DIV" &&
          !eventElement.className.includes("not-closing") &&
          !eventElement.parentElement?.className.includes("not-closing")
        ) {
          props.onClose();
        }
      }
    }
  });

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);

    if (props.onDelete && mediaForDeletion) {
      props
        .onDelete(mediaForDeletion?.index, mediaForDeletion?.media)
        .then(() => setSuccessfullyDeleted(true))
        .catch(() => setDeletionUnsuccessful(true));
    } else {
      alert("No function for deletion provided");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMediaForDeletion(null);
  };

  const handleApproveConfirm = () => {
    setApproveDialogOpen(false);
    if (props.onApprove && mediaForApproval) {
      props
        .onApprove(mediaForApproval)
        .then(() => setSuccessfullyApproved(true))
        .catch(() => setApprovalUnsuccessful(true));
    } else {
      alert("No function for deletion provided");
    }
  };

  const handleApproveCancel = () => {
    setApproveDialogOpen(false);
    setMediaForApproval(null);
  };

  return (
    <>
      <Modal
        ref={saveRootNode}
        open={props.toggle}
        onClose={props.onClose}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.85)" } }}
      >
        <Box>
          <Carousel showThumbs={false} selectedItem={props.initialSelection}>
            {props.media?.map((media, index) => {
              return media.isVideo ? (
                <Box
                  key={media.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Box className="not-closing">
                    <video
                      id={`id-${media.id}`}
                      className="op-player__media"
                      controls
                      playsInline
                      ref={loadVideo}
                      poster={media.presignedUrlThumbnail}
                      style={{ maxHeight: "100vh" }}
                    >
                      <source
                        src={media.presignedUrl}
                        type="application/x-mpegURL"
                      />
                    </video>
                  </Box>
                </Box>
              ) : (
                <Box
                  key={media.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Box
                    className="not-closing"
                    sx={{
                      maxWidth: "80%",
                      maxHeight: "80%",
                      border: !media.isApproved ? 2 : 0,
                      borderColor: !media.isApproved ? "error.main" : "none",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "scale-down",
                        maxWidth: "90vw",
                        maxHeight: "75vh",
                      }}
                      src={media.presignedUrl}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      paddingRight: "40px",
                      paddingTop: "25px",
                    }}
                  >
                    {!media.isApproved && props.onApprove ? (
                      <Button
                        color="error"
                        variant="outlined"
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 1)",
                          color: "#ffffff",
                          background: "transparent",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "rgba(255, 255, 255, 0.2)",
                            border: "1px solid rgba(255, 255, 255, 1)",
                          },
                          marginRight: "5px",
                        }}
                        onClick={() => {
                          setMediaForApproval(media);
                          setApproveDialogOpen(true);
                        }}
                      >
                        Approve Image&nbsp;
                        <TaskAltIcon
                          sx={{
                            fontSize: { xs: "18px", sm: "20px" },
                          }}
                        />
                      </Button>
                    ) : (
                      <></>
                    )}
                    {props.onDelete ? (
                      <Button
                        color="error"
                        variant="outlined"
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 1)",
                          color: "#ffffff",
                          background: "transparent",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "rgba(255, 255, 255, 0.2)",
                            border: "1px solid rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={() => {
                          setMediaForDeletion({ media, index });
                          setDeleteDialogOpen(true);
                        }}
                      >
                        Delete Image&nbsp;
                        <DeleteIcon
                          sx={{
                            fontSize: { xs: "18px", sm: "20px" },
                          }}
                        />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Carousel>
        </Box>
      </Modal>

      <AlertDialog
        title="Are you sure?"
        text="Deletion of media is irreversible. Are you sure that this media should be deleted"
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isDeleteDialogOpen}
        handleCancel={handleDeleteCancel}
        handleConfirm={handleDeleteConfirm}
      />

      <AlertDialog
        title="Are you sure?"
        text="Are you sure that this media should be marked as 'safe for work'"
        confirmText="Confirm"
        confirmColor="error"
        cancelText="Cancel"
        open={isApproveDialogOpen}
        handleCancel={handleApproveCancel}
        handleConfirm={handleApproveConfirm}
      />

      <Snackbar
        open={successfullyDeleted}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyDeleted(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyDeleted(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Media successfully deleted!
        </Alert>
      </Snackbar>

      <Snackbar
        open={deletionUnsuccessful}
        autoHideDuration={5000}
        onClose={() => {
          setDeletionUnsuccessful(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setDeletionUnsuccessful(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Did not manage to delete the media!
        </Alert>
      </Snackbar>

      <Snackbar
        open={successfullyApproved}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessfullyApproved(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setSuccessfullyApproved(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Media successfully approved!
        </Alert>
      </Snackbar>

      <Snackbar
        open={approvalUnsuccessful}
        autoHideDuration={5000}
        onClose={() => {
          setApprovalUnsuccessful(false);
        }}
        className="not-closing"
      >
        <Alert
          onClose={() => {
            setApprovalUnsuccessful(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="not-closing"
        >
          Media approval unsuccessful!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CarouselModal;
