export const parseJwt = function (access_token: string) {
  const base64Url = access_token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload) as {
    exp: number;
    role: string;
    email: string;
    sub: string;
    fullyAuthenticated: boolean;
    authenticatedWith2FA: boolean;
    is2FAMandatory: boolean;
  };
};
