import axios, { AxiosError } from "axios";

const backAxios = axios.create();

backAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    if (config.headers === undefined) {
      config.headers = {};
    }

    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

backAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      const token = localStorage.getItem("access_token");
      if (token) {
        alert("Your session has expired!");
        localStorage.clear();
        location.reload();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default backAxios;
