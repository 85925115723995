// eslint-disable @typescript-eslint/no-floating-promises
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import backAxios from "../axios/back.axios";
import CarouselModal from "../components/CarouselModal";
import { BASE_URL } from "../data/constants";
import BlogPost from "../data/model/blog-post";
import { AdminRole } from "../data/model/enumerations";
import Media from "../data/model/media";

interface BlogPostCardProps {
  blogPostId: string;
  profileId?: string;
}

const BlogPostCard = ({ blogPostId, ...props }: BlogPostCardProps) => {
  const userRole = localStorage.getItem("role") as AdminRole;

  const { profileId: paramProfileId } = useParams<{ profileId: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);

  const [togglePreview, setTogglePreview] = useState(false);
  const [carouselSelectedItem, setCarouselSelectedItem] = useState(0);
  const openCarousel = () => setTogglePreview(true);
  const closeCarousel = () => setTogglePreview(false);

  const profileId = props.profileId ?? paramProfileId;

  const fetchBlogPost = async () => {
    try {
      if (profileId) {
        const response = await backAxios.get(
          `${BASE_URL}/${profileId}/blog-posts/${blogPostId}`
        );
        const updatedPost = response.data as BlogPost;
        setPost(updatedPost);
        return updatedPost;
      }
    } catch (error: any) {
      console.log("Something went wrong while fetching blog post");
      console.log(error);
    }
  };

  const handleDeleteMedia = async (index: number, media: Media) => {
    async function deleteBlogMedia(index: number) {
      let updatedPost;
      try {
        if (profileId && media && post) {
          await backAxios.delete(
            `${BASE_URL}/${profileId}/blog-posts/${post.id}/media/${media.id}`
          );
          updatedPost = await fetchBlogPost(post.id);
        }
      } catch (error: any) {
        console.log("Something went wrong while updating");
      }
      if (updatedPost?.media === undefined || updatedPost?.media.length === 0) {
        closeCarousel();
      } else {
        setCarouselSelectedItem(Math.min(index, updatedPost?.media.length - 1));
      }
      setPost(updatedPost);
    }
    await deleteBlogMedia(index);
  };

  useEffect(function initialLoad() {
    void fetchBlogPost();
  }, []);

  if (!post) {
    // TODO: Show loader
    return null;
  }

  return (
    <Card>
      <CardHeader title={post.name || "N/A"} subheader={post.city || "N/A"} />
      <Carousel showThumbs={false} onChange={setCarouselSelectedItem}>
        {post.media?.map((media) => {
          return media.isVideo ? (
            <Box
              key={media.id}
              onClick={openCarousel}
              sx={{ cursor: "pointer", maxHeight: 230 }}
            >
              <img src={media.presignedUrlThumbnail} alt="No content" />
            </Box>
          ) : (
            <Box
              key={media.id}
              onClick={openCarousel}
              sx={{ cursor: "pointer", maxHeight: 230 }}
            >
              <img src={media.presignedUrl} alt="No content" />
            </Box>
          );
        })}
      </Carousel>
      <CarouselModal
        toggle={togglePreview}
        media={post.media}
        onClose={closeCarousel}
        initialSelection={carouselSelectedItem}
        onDelete={userRole === AdminRole.VIEWER ? undefined : handleDeleteMedia}
      ></CarouselModal>
      <CardContent>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 600 }}
        >
          Description:{" "}
          <span style={{ fontWeight: 400 }}>{post.description || "N/A"}</span>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 600 }}
        >
          Created at:{" "}
          <span style={{ fontWeight: 400 }}>
            {post.creationDate
              ? format(new Date(post.creationDate), "yyyy-MM-dd")
              : "N/A"}
          </span>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 600 }}
        >
          Number of likes:{" "}
          <span style={{ fontWeight: 400 }}>{post.numberOfLikes || 0}</span>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 600 }}
        >
          Number of comments:{" "}
          <span style={{ fontWeight: 400 }}>{post.numberOfComments || 0}</span>
        </Typography>
      </CardContent>
    </Card>
  );
};
export default BlogPostCard;
